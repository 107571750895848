import { Cms } from "@swo/shared";
import cookie from "js-cookie";
import { useEffect, useState } from "react";
import { type State, stateSchema } from "./State";

export function useToolbarState() {
  const KEY = Cms.CookieNames.state;

  const [state, setState] = useState<State | null>(null);

  function readState(): State | null {
    try {
      const s = cookie.get(KEY);

      if (!s || s === "null") {
        return null;
      }

      return s ? stateSchema.parse(JSON.parse(s)) : null;
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    const cookieState = readState();

    if (!cookieState) {
      return;
    }

    void fetch("/storefront/toolbelt")
      .then(res => res.json())
      .then(data => {
        return setState(cookieState);
      })
      .catch(() => false);
  }, []);

  useEffect(() => {
    if (!state) {
      return;
    }

    cookie.set(KEY, JSON.stringify(state), {
      expires: 365,
    });

    // if (previousState && state && previousState.opened && previousState.errorsStrategy !== state.errorsStrategy) {
    //   window.location.reload();
    // }
  }, [state]);

  return { state, setState };
}
