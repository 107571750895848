import React, { useMemo } from "react";
import { ReactTFC } from "../../../../utils/fp/missing-ts-parts";
import { css } from "@emotion/react";
import { captureProductNamesInText, ProductNameCaptureT } from "./capture-product-name/capture-product-names-in-text";

function renderTextWithProductName(textWithProductName: Array<ProductNameCaptureT>) {
  const styles = css`
    text-transform: none;
    white-space: nowrap;
  `;

  return (
    <React.Fragment>
      {textWithProductName.map(t =>
        !t.dontTransform ? (
          <React.Fragment key={JSON.stringify(t)}>{t.text}</React.Fragment>
        ) : (
          <span key={JSON.stringify(t)} css={styles}>
            {t.text}
          </span>
        ),
      )}
    </React.Fragment>
  );
}

function hasAnyProductName(textWithProductName: Array<ProductNameCaptureT>): boolean {
  return textWithProductName.some(t => t.dontTransform);
}

export const useTextWithProductNameRenderer = (text: string) => {
  return useMemo(() => {
    const textWithProductName = captureProductNamesInText(text);

    return {
      Element: renderTextWithProductName(textWithProductName),
      hasAnyProductName: hasAnyProductName(textWithProductName),
    };
  }, [text]);
};

export const EXPERIMENTAL_TextWithProductNameRenderer: ReactTFC<{ text: string }> = props => {
  const r = useTextWithProductNameRenderer(props.text);

  return r.Element;
};
