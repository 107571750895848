export function filterConsecutiveDuplicates<T>(arr: Array<T>, eqT: (a: T, b: T) => boolean): Array<T> {
  let ret: Array<T> = [];

  const last = (arr: Array<T>): T | undefined => {
    return ret[ret.length - 1];
  };

  arr.forEach(a => {
    const lastElem = last(ret);

    if (!lastElem) {
      return ret.push(a);
    } else if (!eqT(lastElem, a)) {
      return ret.push(a);
    }
  });

  return ret;
}
