export function timed<T>(eff: () => Promise<T>, randomKey: string) {
  window.performance.mark(`start-${randomKey}`);

  const ret = eff();

  return ret.finally(() => {
    window.performance.mark(`end-${randomKey}`);
  });
}

export function timedWithPrint<T>(eff: () => Promise<T>, randomKey: string) {
  return timed(eff, randomKey).finally(() => {
    if (randomKey) {
      console.debug(`%c ${readTimed(randomKey)}`, `background: #222; color: #e5ecea`);
    }
  });
}

export function readTimed(measurementMarkName: string) {
  const measurementName = Math.random().toString();
  window.performance.measure(measurementName, `start-${measurementMarkName}`, `end-${measurementMarkName}`);

  const roundedMsDuration = Math.floor(window.performance.getEntriesByName(measurementName)[0]!.duration);

  return `[${measurementMarkName}] ${roundedMsDuration}ms`;
}

export const debug = (scope: string) => (msg: string) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`[${scope}] ${msg}`);
  }
};
