import { useLayoutEffectWithSSR } from "./use-layout-effect-with-ssr";

export function useGlobalClassname(className: string, isActive: boolean) {
  useLayoutEffectWithSSR(() => {
    if (isActive) {
      document.documentElement.classList.add(className);

      return () => {
        document.documentElement.classList.remove(className);
      };
    }
  }, [className, isActive]);
}
