import { DealerLocatorGroup } from "@swo/storefront";

export const FORM_ELEMENTS: Array<DealerLocatorGroup> = [
  "binoculars",
  "telescopes",
  "riflescopes",
  "premium-gear",
  "thermal-vision",
  "ax-visio-focus",
];
