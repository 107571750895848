import type { OthersSearchResponse } from "@swo/storefront";
import type React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { AssetResult } from "./AssetResult";
import { EventResult } from "./EventResult";
import { PageResult, StoryResult } from "./results/PageResult";
import { gridStyles } from "./ProductsSearchResultsGrid";

export const OthersSearchResultsGrid: React.FC<{
  others: OthersSearchResponse;
  t: SearchT;
}> = ({ others, t }) => {
  return (
    <div css={gridStyles} className="tight u-mt-3">
      {others.results.map(hit => {
        switch (hit._tag) {
          case "page":
            return <PageResult page={hit} t={t} key={hit.id} />;
          case "story":
            return <StoryResult story={hit} t={t} key={hit.id} />;
          case "event":
            return <EventResult event={hit} t={t} key={hit.id} />;
          case "asset":
            return <AssetResult asset={hit} t={t} key={hit.id} />;
          default: {
            const _: never = hit;
            return _;
          }
        }
      })}
    </div>
  );
};
