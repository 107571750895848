import React from "react";
import { HeaderNavigationButton } from "./HeaderNavigationButton";
import { css } from "@emotion/react";
import { MainNavigationElement } from "./props";
import cn from "classnames";
import { CSS_MQ } from "../../../styles/breakpoints";

export const MainNavigationElements: React.FC<{
  mainNavigationElements: Array<MainNavigationElement>;
  onClick: (tag: "content" | "shop") => void;
  activeTag: "shop" | "content" | null;
}> = ({ mainNavigationElements, onClick, activeTag }) => {
  return (
    <div
      css={css`
        display: flex;

        gap: 8px;
        ${CSS_MQ.atLeastLg} {
          gap: 20px;
        }

        ${CSS_MQ.atMostSm} {
          display: none;
        }
      `}
    >
      {mainNavigationElements.map(({ label, ref, className, tag }) => {
        return (
          <HeaderNavigationButton
            className="_navigation"
            key={label.key}
            buttonRef={ref}
            buttonLabel={label}
            buttonClassName={cn(className, tag === activeTag && "active")}
            onClick={() => onClick(tag)}
          />
        );
      })}
    </div>
  );
};
