import { SwarovskiOptikPage } from "@swo/sitemap-service";
import { GuidedSellingEvent } from "./guided-selling-tracking-aspect";

export type ProductCategory = string | "Binoculars" | "Spotting Scopes" | "Rifle Scopes" | "Accessories" | "Gear";

export type GTMEvent =
  | PageViewEvent
  | OrderConfirmationEvent
  | CheckoutStepsEvent
  | RemoveFromCartEvent
  | AddToCartEvent
  | ProductDetailViewEvent
  | ProductClickEvent
  | ProductImpressionsEvent
  | OpenDealerLocator
  | SearchDealerLocator
  | GuidedSellingEvent
  | CheckoutEmailEnteredEvent
  | UserDataLoadedEvent
  | ProductAvailabilitySubscriptionEvent;

export type CheckoutEmailEnteredEvent = {
  event: "checkout-email-entered";
  email: string;
};

export type UserDataLoadedEvent = {
  event: "user-data-loaded";
  email: string;
};

export type ProductAvailabilitySubscriptionEvent = {
  event: "product-availability-subscription";
  localeCode: string;
  language: string;
  country: string;
  segmentKey: string;
  email: string;
  sku: string;
};

export type PageViewEvent = {
  event: "pageview";
  language: string;
  country: string;
  consumerType: string;
  pageType: SwarovskiOptikPage["__typename"];
  pageId: string;
};

export type OrderConfirmationEvent = {
  event: "order-confirmation";
  ecommerce: {
    currencyCode: string;
    purchase: {
      actionField: {
        id: string; // transaction id
        affiliation: "Online Store"; // hardcoded
        revenue: number; // including tax
        tax: number;
        shipping: number;
        coupon?: string;
      };
      products: Array<EcommerceProductVariant>;
    };
  };
};

export type EcommerceProductVariant = {
  name: string;
  id: string; // sku
  price: number;
  // TDB: variant: string; // color
  category: ProductCategory;
  quantity: number;
  dimension10: string;
  dimension7: "in-stock" | "out-of-stock";
};

export type EcommerceProduct = {
  name: string;
  category: ProductCategory;
  dimension10: string;
};

export type CheckoutStepsEvent = {
  event: "checkout-steps";
  ecommerce: {
    currencyCode: string;
    checkout: {
      actionField: { step: number }; // 1 - view cart, 2 - check order
      products: Array<EcommerceProductVariant>;
    };
  };
};

export type RemoveFromCartEvent = {
  event: "remove-from-cart";
  ecommerce: {
    currencyCode: string;
    remove: {
      products: Array<EcommerceProductVariant>;
    };
  };
};

export type AddToCartEvent = {
  event: "add-to-cart";
  ecommerce: {
    currencyCode: string;
    add: {
      products: Array<EcommerceProductVariant>;
    };
  };
};

export type ProductDetailViewEvent = {
  event: "product-detail";
  ecommerce: {
    currencyCode: string;
    detail: {
      products: Array<EcommerceProductVariant>;
    };
  };
};

export type ProductClickEvent = {
  event: "product-click";
  ecommerce: {
    currencyCode: string;
    click: {
      products: Array<EcommerceProduct>;
    };
  };
};

export type ProductImpressionsEvent = {
  event: "product-impressions";
  ecommerce: {
    currencyCode: string;
    impressions: Array<EcommerceProduct & { position: number }>;
  };
};

export type OpenDealerLocator = {
  event: "open-dealer-locator";
  id: string; //sku
  availability: string; //product availability
};

export type SearchDealerLocator = {
  event: "search-dealer-locator";
};

export const GTMClassHooks = {
  // These classes are exposed to external systems.
  // Please do not change their values unless you know what you're doing
  segmentPickerOverlay: "js-segment-picker-overlay-wrapper",
  segmentPickerOverlayValue: (v: string) => `js-segment-picker-overlay-selection ${v}`,
  segmentPickerOverlayClose: `js-segment-picker-overlay-close`,
  segmentPickerFloating: `js-segment-picker-floating-wrapper`,
  segmentPickerFloatingState: (isOpened: boolean) =>
    isOpened ? "js-segment-picker-floating-wrapper-opened" : "js-segment-picker-floating-wrapper-closed",
  segmentPickerFloatingValue: (v: string) => `js-segment-picker-floating ${v}`,
  segmentPickerFloatingClose: `js-segment-picker-floating-close`,
};
