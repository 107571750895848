import React from "react";
import { css } from "@emotion/react";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { fontSize } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { Node } from "./Links";
import { NewLabel2 } from "../../../layouts/product-detail-page-layout/NewLabel2";

export const LinkNode: React.FC<Node> = ({ link, isNew }) => {
  return (
    <SwarovskiLink
      link={link.data}
      isInternal={link.type === "Internal Link"}
      styled="no-decoration"
      className="u-relative"
      attachStyles={[
        fontSize.x25,
        css`
          color: ${CSS_COLORS.Black};

          &:hover {
            text-decoration: underline;
          }
        `,
      ]}
    >
      <NewLabel2 name={link.label} isNew={isNew} />
    </SwarovskiLink>
  );
};
