import { jsonEqNonNullable } from "../utils/fp/json-eq";
import { useEffect, useRef } from "react";
import { filterConsecutiveDuplicates } from "../utils/js/filter-consecutive-duplicates";

function queueElementEq<T extends { taskId: string }>(one: T, another: T) {
  const { taskId: taskIdOne, ...restOne } = one;
  const { taskId: taskIdAnother, ...restAnother } = another;

  return jsonEqNonNullable(restOne, restAnother);
}

type UseQueueParams = { onAppended: () => void };

export function useQueue<T>(callbacks?: UseQueueParams) {
  const queue = useRef<Array<{ t: T; taskId: string }>>([]);

  function append(event: T) {
    const taskId = `task-${Date.now()}-${Math.floor(Math.random() * 10 ** 10)}`;

    const queueElement: { t: T; taskId: string } = { t: event, taskId: taskId };
    const updatedQueue: Array<{ t: T; taskId: string }> = filterConsecutiveDuplicates(
      [...queue.current, queueElement],
      queueElementEq,
    );

    queue.current = updatedQueue;

    if (callbacks?.onAppended) {
      callbacks.onAppended();
    }
  }

  function remove(taskId: string) {
    queue.current = queue.current.filter(t => t.taskId !== taskId);
  }

  function seeNext() {
    const [event, _] = queue.current;

    return event ? { event, dequeue: () => remove(event.taskId) } : undefined;
  }

  return { queue, append, remove, seeNext };
}

export function useQueueWithProcessor<T>(eventHandler: (event: T) => Promise<void>) {
  const { queue, append: internalAppend, remove, seeNext } = useQueue<T>();
  const isHandlingEvents = useRef(false);

  function handleAsync() {
    if (isHandlingEvents.current) {
      return;
    }

    const maybeEvent = seeNext();

    if (!maybeEvent) {
      return;
    }

    const { event, dequeue } = maybeEvent;

    isHandlingEvents.current = true;
    void eventHandler(event.t).finally(() => {
      dequeue();
      isHandlingEvents.current = false;
    });
  }

  const append = (event: T) => {
    internalAppend(event);
    handleAsync();
  };

  return { queue, append, remove };
}
