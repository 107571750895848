import React from "react";
import { internalPaneStyles } from "./InternalPane";
import cn from "classnames";
import { Theme } from "./PaneContentState";

export const MobilePaneContentRawShell: React.FC<
  React.PropsWithChildren<{ theme: Theme; isActive: boolean; className: string }>
> = ({ children, theme, isActive, className }) => {
  return (
    <div
      css={internalPaneStyles}
      className={cn("js-internal-pane u-grow u-relative", className, `theme-${theme}`, { active: isActive })}
    >
      {children}
    </div>
  );
};
