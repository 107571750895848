import React from "react";

import { css } from "@emotion/react";
import cn from "classnames";

const styles = css`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  > .expand {
    flex-grow: 1;
  }

  > .fill-screen {
    min-height: 60vh;
  }
`;

export const AppView: React.FC<React.PropsWithChildren<{ className?: string; withDebugStyles?: boolean }>> = props => {
  return (
    <div
      css={styles}
      className={cn("app-view", props.className, props.withDebugStyles ? "global-debug-styles" : undefined)}
    >
      {props.children}
    </div>
  );
};
