export type SwarovskiOptikSegmentKey = "hunting" | "birding" | "outdoor";

export type SwarovskiOptikSegmentShort = {
  id: string;
  key: SwarovskiOptikSegmentKey;
  slug: string;
};

export function parseSwarovskiOptikSegment(keyStr: string): keyStr is SwarovskiOptikSegmentKey {
  // FIXME: Use validator from storefront
  return ["hunting", "birding", "outdoor"].includes(keyStr);
}
