import { css } from "@emotion/react";

import { atLeastMediaQuery, BREAKPOINTS, CSS_MQ } from "./breakpoints";

export const CSS_COLORS = {
  Green: `var(--so-green)`,
  GreenDark: `var(--so-green-dark)`,
  GreenWhite: `var(--so-green-white)`,

  // We need it to use this instead of CSS's `transparent`
  // which is not well-supported in Safari...
  // ie. https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
  GreenA01: `var(--so-green-almost-transparent)`,
  GreenDarkA01: `var(--so-green-dark-almost-transparent)`,
  GreenWhiteA01: `var(--so-green-white-almost-transparent)`,

  GreenA50: `var(--so-green-half-opacity)`,
  GreenDarkA50: `var(--so-green-dark-half-opacity)`,
  GreenWhiteA50: `var(--so-green-white-half-opacity)`,

  Gray2024: "#E5EBEA",
  GrayLight2024: "#f9f9f9",
  GreenTransparent20: "#d8e4e0",
  GrayDark2024: "#656867",
  OtherGray2024: "#DFE3E6",
  BlackTransparent60: "#00000099",

  CoolMint: "#E6ECEA",
  Unknown: "#2a775f",
  Unknown2: "#f2f2f2",
  Unknown3: "#f3f5f5",

  White: `var(--white)`,
  Black: `var(--black)`,
  Grey00: "#f5f5f5",
  Grey0: "#efefef",
  Grey: "#d8d8d8",
  Grey2: "#979797",
  Grey3: "#505050",
  Grey4: "#DCDCDC",
  Grey5: "#BFBFBF",
  Grey6: "#E5E5E5",
  Grey7: "#292929",

  RedCustom: "#e10b0b",
  RedTransparentCustom: "#FCE7E7",
  RedTransparentCustom2: "#ff6b6b80",
  OrangeCustom: "#ff5900",
  YellowCustom: "#ffd700aa",
  GreenCustom: "#03c714",
  GreenTransparent: "#03c71450",
};

export const spacerSizes = {
  sm: "--spacer-sm-size",
  smd: "--spacer-smd-size",
  md: "--spacer-md-size",
  mdlg: "--spacer-mdlg-size",
  lg: "--spacer-lg-size",
};

export const CSS_SPACER_SIZES = {
  sm: `var(${spacerSizes.sm})`,
  smd: `var(${spacerSizes.smd})`,
  md: `var(${spacerSizes.md})`,
  mdlg: `var(${spacerSizes.mdlg})`,
  lg: `var(${spacerSizes.lg})`,
};

export const spacerCssSizes = css`
  ${spacerSizes.sm}: 2px;
  ${spacerSizes.smd}: 8px;
  ${spacerSizes.md}: 40px;
  ${spacerSizes.mdlg}: 48px;
  ${spacerSizes.lg}: 60px;

  ${CSS_MQ.atLeastMd} {
    ${spacerSizes.sm}: 4px;
    ${spacerSizes.smd}: 20px;
    ${spacerSizes.md}: 50px;
    ${spacerSizes.mdlg}: 64px;
    ${spacerSizes.lg}: 100px;
  }
`;

export const gutterSize = 20;

export const globalCssVariablesDefinition = [
  css`
    --so-green: #1f7057;
    --so-green-half-opacity: #1f705780;
    --so-green-almost-transparent: #1f705701;

    --so-green-dark: #00412d;
    --so-green-dark-half-opacity: #00412d80;
    --so-green-dark-almost-transparent: #00412d01;

    --so-green-white: #e5ecea;
    --so-green-white-half-opacity: #e5ecea80;
    --so-green-white-almost-transparent: #e5ecea01;

    --white: #ffffff;
    --black: #000000;

    --max-container-width: 100%;
    @media only screen and (min-width: 2200px) {
      --max-container-width: 2000px;
    }

    --content-gutter: ${gutterSize}px;
    --max-content-width-new: calc(100% - 2 * var(--content-gutter));
    ${atLeastMediaQuery(BREAKPOINTS.atLeastXl + 2 * gutterSize)} {
      --content-gutter: 0px;
      --max-content-width-new: 1400px;
    }

    --max-narrow-content-width: calc(100% - 40px);
    ${atLeastMediaQuery(1065 + 40)} {
      --max-narrow-content-width: 1065px;
    }

    --ctx-cart-background-color: ${CSS_COLORS.White};
    --ctx-cart-color: ${CSS_COLORS.Green};

    ${CSS_MQ.atLeastMd} {
      --ctx-cart-background-color: ${CSS_COLORS.Green};
      --ctx-cart-color: ${CSS_COLORS.Grey};
    }

    --header-pinned-height: 70px;
    ${CSS_MQ.atLeastMd} {
      --header-pinned-height: 100px;
    }

    --header-unfixed-height: 70px;
    ${CSS_MQ.atLeastMd} {
      --header-unfixed-height: 100px;
    }

    --plp-grid-gap: 8px;
    ${CSS_MQ.atLeastMd} {
      --plp-grid-gap: 20px;
    }
    ${CSS_MQ.atLeastXl} {
      --plp-grid-gap: 34px;
    }
  `,
  spacerCssSizes,
];
