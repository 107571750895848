import React from "react";
import { css } from "@emotion/react";
import { paddingStyles } from "./paddingXStyles";
import { CSS_MQ } from "../../../styles/breakpoints";

export const Wrapper: React.FC<React.PropsWithChildren<{ noPadding?: boolean }>> = ({ noPadding, children }) => {
  return (
    <div
      className="js-wrapper u-flex u-flex-col u-grow"
      css={[
        css`
          width: calc(100% + var(--close-pane-width));

          ${CSS_MQ.atLeastMd} {
            margin-top: var(--margin-top);
          }
        `,
        noPadding
          ? css`
              ${CSS_MQ.atLeastMd} {
                padding: var(--padding-top) 0 0 0;
              }
            `
          : css`
              ${CSS_MQ.atLeastMd} {
                ${paddingStyles}
              }
            `,
      ]}
    >
      {children}
    </div>
  );
};
