import React from "react";
import { ImageWithFocalPointType } from "@swo/shared";
import { ImageWithFocalPoint } from "../../_shared-components/image-related/ImageWithFocalPoint";
import { css } from "@emotion/react";
import { StoryComponent } from "../../MainNavigationComponent/navigation/StoryComponent";
import { SwarovskiButton } from "../../_shared-components/ui-elements/SwarovskiButton";
import { paddingStyles } from "./paddingXStyles";
import { CSS_MQ } from "../../../styles/breakpoints";
import type { TranslatedValue } from "@swo/storefront";

type PageListWithOverviewProps = {
  list: Array<{
    link: string;
    primaryHeadline: string;
    secondaryHeadline: string | undefined;
    additionalText: string | TranslatedValue;
  }>;
  overview: {
    linkData: {
      pathname: string;
    };
    label: string;
  };
  image?: ImageWithFocalPointType;
};

export const PageListWithOverview = React.forwardRef<HTMLDivElement, PageListWithOverviewProps>(
  ({ list, overview, image }, ref) => {
    return (
      <>
        <div
          className="js-page-list-with-overview u-flex u-flex-col u-relative so-scrollbar-y"
          css={[
            css`
              gap: 40px;

              align-items: flex-start;

              ${CSS_MQ.atLeastMd} {
                ${paddingStyles};
                padding-bottom: 40px;

                overflow-y: auto;
              }
            `,
          ]}
        >
          <ul
            ref={ref as any}
            className="u-flex u-flex-col"
            css={css`
              gap: 40px;
            `}
          >
            {list.map(_ => (
              <StoryComponent key={_.link} {..._} />
            ))}
          </ul>

          <SwarovskiButton as="a" variant="green" link={overview.linkData.pathname} isInternal>
            {overview.label}
          </SwarovskiButton>
        </div>

        {image && (
          <ImageWithFocalPoint
            assetId={image.image.sys.id}
            style={{ "--ar": image.image.width / image.image.height } as React.CSSProperties}
            image={image.image}
            focalPoint={image.focalPoint}
            requestedWidth={700}
            attachPictureStyles={css`
              position: relative;

              display: block;
              width: 100%;
              ${CSS_MQ.atMostSm} {
                width: calc(100% + 2 * var(--padding-x) + var(--mlS));
                margin-left: calc(-1 * var(--padding-x) - var(--mlS));
              }

              margin-top: 40px;
              ${CSS_MQ.atMostSm} {
                margin-top: 20px;
              }

              aspect-ratio: var(--ar);
            `}
          />
        )}
      </>
    );
  },
);
