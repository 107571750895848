import { css } from "@emotion/react";
import { NoImageSvg } from "../../assets";
import { CSS_MQ } from "../../styles/breakpoints";
import { FONTS, fontSize } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";

export const FallbackImage = () => {
  return (
    <span css={fallbackImagesStyles}>
      <NoImageSvg strokeWidth={3} />
    </span>
  );
};

export const iconStyles = css`
  color: ${CSS_COLORS.Green};
  display: block;

  width: 42px;
  ${CSS_MQ.atLeastSm} {
    width: 48px;
  }

  svg {
    width: 100%;
    height: 100%;
    color: inherit;
  }
`;

const fallbackImagesStyles = [FONTS.SwarovskiFutura300, fontSize.x2s, iconStyles];
