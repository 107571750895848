import { useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { useEqualState } from "./use-equal-state";

export function useStale<T>(data: T, stalenessAcceptanceMs: number, acceptImmediately: (t: T) => boolean): T {
  const [state, setState] = useState<T>(data);
  const [counter, setCounter] = useEqualState<T | null>(null);
  const counterRef = useRef(counter);

  useEffect(() => {
    if (counter === null) {
      return;
    }
    
    // Otherwise,
    // if new data comes, we lag behind
    // and update state only after @stalenessAcceptanceMs duration has passed
    if (counterRef.current !== counter) {
      counterRef.current = counter;

      const id = window.setTimeout(() => {
        setState(data);
        setCounter(null);
      }, stalenessAcceptanceMs);

      return () => {
        window.clearTimeout(id);
      };
    }
  }, [counter]);

  if (isEqual(state, data)) {
    return state;
  }

  if (acceptImmediately(data) || stalenessAcceptanceMs <= 0) {
    // In certain cases, we know we have transitioned to the correct state and we don't need to show stale result
    // (think we have stale loading state and fresh loaded state)
    setState(data);
  } else {
    setCounter(data);
  }

  return state;
}
