import {
  PlpSparePartFinderProductLevel,
  PlpSparePartFinderShowSpareParts,
  PlpSparePartFinderSubfamilyLevel,
} from "@swo/storefront";
import { fetchApi } from "../../../data-layer/fetch-api";
import { optionsGetJson } from "../../options";

export function sparePartsCategoryFinder(
  context: {
    localeCode: string;
    segmentKey: string;
  },
  data: { categoryKey: string },
): Promise<PlpSparePartFinderSubfamilyLevel> {
  const { localeCode, segmentKey } = context;
  return fetchApi<PlpSparePartFinderSubfamilyLevel>(
    `/storefront/spare-parts-finder/categories/${data.categoryKey}?localeCode=${localeCode}&segmentKey=${segmentKey}`,
    optionsGetJson,
  );
}

export function sparePartsSubfamilyFinder(
  context: {
    localeCode: string;
    segmentKey: string;
  },
  data: { subfamilyKey: string },
): Promise<PlpSparePartFinderProductLevel> {
  const { localeCode, segmentKey } = context;

  return fetchApi<PlpSparePartFinderProductLevel>(
    `/storefront/spare-parts-finder/subfamilies/${data.subfamilyKey}?localeCode=${localeCode}&segmentKey=${segmentKey}`,
    optionsGetJson,
  );
}

export function sparePartsProductFinder(
  context: {
    localeCode: string;
    segmentKey: string;
  },
  data: { productKey: string },
): Promise<PlpSparePartFinderShowSpareParts> {
  const { localeCode, segmentKey } = context;

  return fetchApi<PlpSparePartFinderShowSpareParts>(
    `/storefront/spare-parts-finder/products/${data.productKey}?localeCode=${localeCode}&segmentKey=${segmentKey}`,
    optionsGetJson,
  );
}
