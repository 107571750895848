import { css } from "@emotion/react";
import { CSS_COLORS } from "./styles-css-variables";
import { CSS_MQ } from "./breakpoints";

export const richTextAnchorStyles = css`
  a {
    color: ${CSS_COLORS.Green};
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const richTextBoldStyles = css`
  b {
    font-weight: 500;
  }
`;

export const soListStyles = css`
  list-style-type: circle;
  padding-left: 24px;
  margin: 0;
`;

export const richTextListStyles = css`
  ul,
  ol {
    ${soListStyles};
  }
`;

export const richTextBlockQuoteStyle = css`
  blockquote {
    background: ${CSS_COLORS.Grey0};
    margin: 0;

    padding: 16px 20px;
    ${CSS_MQ.atLeastMd} {
      padding: 24px 36px;
    }

    > *:first-of-type {
      margin-top: 0;
    }

    > *:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const richTextStyles = [
  richTextAnchorStyles,
  richTextBoldStyles,
  richTextListStyles,
  css`
    h1,
    h2,
    h3,
    h4 {
      font-weight: 500;
      line-height: 1.15;
    }

    h5,
    h6 {
      font-weight: 300;
      line-height: 1.15;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  `,
];
