import { useEffect } from "react";

export function useBodyScrollLock(isActive: boolean) {
  const className = "u-overflow-hidden";

  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer);

    return scrollbarWidth;
  }

  useEffect(() => {
    if (isActive) {
      const scrollPos = window.scrollY;
      const scrollBarWidth = getScrollbarWidth();

      document.documentElement.classList.add(className);
      document.documentElement.style.paddingRight = `${scrollBarWidth}px`;

      return () => {
        document.documentElement.classList.remove(className);
        window.scrollTo({ top: scrollPos });
        document.documentElement.style.paddingRight = "";
      };
    }
  }, [isActive]);
}
