import { DealerLocatorAppState, writeUrlParams } from "./index";

export function serializeStateToUrl(appState: DealerLocatorAppState) {
  return writeUrlParams(
    appState.query,
    appState.groups.filter(g => g.value).map(g => g.name),
    appState.clickAndCollect,
    appState.selectedDealerId,
  );
}
