import type { Attribute, ProductType, ProductVariant, TypedMoney } from "@commercetools/platform-sdk";
import { apply, option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import type { Option } from "fp-ts/lib/Option";
import { countryConstraint } from "./constraints/country-constraint";
import { discountConstraint } from "./constraints/discount-constraint";
import { priceConstraint } from "./constraints/price-constraint";
import { retiredProductConstraint } from "./constraints/retired-product-constraint";
import { segmentConstraint } from "./constraints/segment-constraint";
import { variantPrice } from "./helpers/variant-price";
import type { SwoProductFilterPredicateCtx } from "./swo-product-filter-predicate";

export type SwoMinProductVariant = {
  id: number;
  key: string;
  sku: string;
  attributes: Array<Attribute>;
  variantPrice: TypedMoney | undefined;
};

export const swoProductVariantFilterPredicate = (
  ctx: SwoProductFilterPredicateCtx & { productType: ProductType },
  variant: ProductVariant,
): Option<SwoMinProductVariant> => {
  const isBundle = ctx.bundleProductTypeKey === ctx.productType.key;
  const attributes = O.fromNullable(variant.attributes);
  const sku = O.fromNullable(isBundle? "": variant.sku);
  const key = O.fromNullable(isBundle? "": variant.key);

  const price =
    variant.prices && typeof ctx.locale !== "undefined" ? variantPrice(ctx.locale, variant.prices) : undefined;

  return pipe(
    apply.sequenceS(O.Applicative)({
      id: O.some(variant.id),
      attributes,
      sku,
      key,
      variantPrice: O.some(price),
    }),
    O.filter(
      ({ attributes }) =>
        (ctx.locale ? countryConstraint(ctx.locale, attributes) : true) &&
        discountConstraint(ctx, attributes, price) &&
        retiredProductConstraint(ctx, attributes) &&
        (ctx.segmentKey ? segmentConstraint(ctx.segmentKey, attributes) : true) &&
        priceConstraint(ctx, price),
    ),
  );
};
