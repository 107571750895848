import React from "react";
import { css } from "@emotion/react";
import { NewHeaderData, type HeaderSegmentSelector } from "@swo/storefront";
import { SwarovskiOptikSegmentKey } from "@swo/shared";

import { NavigationSegmentsOverlayElement } from "./NavigationSegmentsOverlayElement";

export type NavigationSegmentsOverlayProps = {
  segments: HeaderSegmentSelector["segments"];
  selectedSegmentKey: SwarovskiOptikSegmentKey;
  onSelected: (segmentKey: SwarovskiOptikSegmentKey) => void;
};

export const NavigationSegmentsOverlay: React.FC<NavigationSegmentsOverlayProps> = ({
  segments,
  onSelected,
  selectedSegmentKey,
}) => {
  return (
    <ul className="u-flex u-flex-col u-gap-5">
      {segments.map(segment => (
        <NavigationSegmentsOverlayElement
          key={segment.key}
          segment={segment}
          onSelected={onSelected}
          isSelected={segment.key === selectedSegmentKey}
        />
      ))}
    </ul>
  );
};
