import { css } from "@emotion/react";
import type { ProductsSearchResponse, TypedMoney } from "@swo/storefront";
import cn from "classnames";
import type React from "react";
import { formatStartingPrice } from "../../domain/swarovski-optik/products/price/format-price";
import { EXPERIMENTAL_TextWithProductNameRenderer } from "../../domain/swarovski-optik/products/product-name/product-name-renderer";
import { useGeneralTranslationsContext } from "../../global/hooks/use-general-translations-context";
import { usePageLocaleContext } from "../../global/page-context";
import { CSS_MQ } from "../../styles/breakpoints";
import { FONTS, fontSize, fontStyles } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { ContentfulImage2 } from "../_shared-components/image-related/ContentfulImage2";
import { SwarovskiLink } from "../_shared-components/ui-elements/SwarovskiLink";
import { FallbackImage } from "./FallbackImage";
import { NewLabel2 } from "../../layouts/product-detail-page-layout/NewLabel2";

export const gridStyles = [
  css`
    display: grid;

    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 4px;

    &.tight {
      gap: 12px;
    }

    padding-right: 4px;

    ${CSS_MQ.atLeastSm} {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      gap: 16px;
      padding-right: 16px;
    }

    ${CSS_MQ.atLeastMd} {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      gap: 20px 30px;
      padding-left: 0;
      padding-right: 0;

      &.tight {
        gap: 24px;
      }
    }

    ${CSS_MQ.atLeastXl} {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

      &.tight {
        gap: 20px;
      }
    }

    width: 100%;
  `,
];

export const ProductsSearchResultsGrid: React.FC<{
  products: ProductsSearchResponse;
}> = ({ products }) => {
  const { locale } = usePageLocaleContext();
  const fromPriceLabel = useGeneralTranslationsContext()("fromPriceLabel");

  return (
    <div css={gridStyles}>
      {products.results.map(hit => {
        const startingPrice: TypedMoney = {
          type: "centPrecision",
          ...hit.lowest_price,
        };

        const startingPriceLabel = formatStartingPrice(startingPrice, fromPriceLabel, locale, true);

        return (
          <SwarovskiLink
            link={hit.path}
            isInternal={true}
            scrollToTop={true}
            styled={"no-decoration"}
            key={hit.path}
            className={cn({ selected: false })}
            disabled={false}
            attachStyles={linkStyles}
          >
            <div css={wrapperStyles}>
              <div
                className={"u-relative u-d-flex u-jc-center u-ai-center"}
                css={css`
                  aspect-ratio: 4/3;
                `}
              >
                {hit.thumbnail ? (
                  <ContentfulImage2
                    assetId={hit.thumbnail.sys.id}
                    contentfulUrl={hit.thumbnail.url}
                    css={css`
                      object-fit: contain;
                      object-position: center;
                      width: 100%;

                      aspect-ratio: 5/3;
                    `}
                    withTransparency={true}
                    cropping={undefined}
                    sizes="(max-width: 576px) 120px, 200px"
                  />
                ) : (
                  <FallbackImage />
                )}
              </div>

              <div
                css={css`
                  flex-grow: 1;

                  display: flex;
                  justify-content: center;
                  align-items: flex-end;

                  ${CSS_MQ.atLeastMd} {
                    padding-left: 24px;
                    padding-right: 24px;
                  }
                `}
              >
                <div className={"u-ta-center"}>
                  <span css={productNameStyles} className="u-relative">
                    <NewLabel2
                      name={<EXPERIMENTAL_TextWithProductNameRenderer text={hit.name} />}
                      isNew={hit.isNew}
                      css={css`
                        font-size: 0.5em;
                      `}
                    />
                  </span>

                  <span css={startingPriceStyles}>{startingPriceLabel}</span>
                </div>
              </div>
            </div>
          </SwarovskiLink>
        );
      })}
    </div>
  );
};

const startingPriceStyles = [
  FONTS.SwarovskiFutura300,
  fontSize.x2s,
  css`
    color: black;
    margin: 0;
  `,
];

const productNameStyles = [
  fontStyles.headline5,
  css`
    margin: 0;

    min-height: 2.325em;
    display: flex;
    align-items: flex-end;
  `,
];

const wrapperStyles = css`
  @media (hover: hover) {
    &:hover {
      background-color: ${CSS_COLORS.GreenWhite};
    }
  }

  .selected & {
    background-color: ${CSS_COLORS.GreenWhite};
  }

  ${CSS_MQ.atLeastSm} {
    padding: 8px 12px;
  }

  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const linkStyles = css`
  display: flex;
  flex-shrink: 0;

  --w: 100%;

  width: var(--w);

  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 8px;

  &:hover {
    border-color: ${CSS_COLORS.Grey4};
  }
`;
