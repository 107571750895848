import { css } from "@emotion/react";
import { SwarovskiOptikSegmentKey, nonEmpty } from "@swo/shared";
import React from "react";
import { Flag, Map, Search, User } from "../../../assets";
import { CSS_MQ } from "../../../styles/breakpoints";
import { FONTS, fontSize } from "../../../styles/fonts";
import { SegmentIconSvg2 } from "../../DealerLocatorComponent/subcomponents/SegmentIconSvg2";
import { MobilePrimaryAndSecondaryPaneContainer } from "./MobilePrimaryAndSecondaryPaneContainer";
import { Actions } from "./useActions";
import { HeaderT } from "../HeaderT";
import { T } from "../../T";

const svgStyles = css`
  width: 20px;
  height: 20px;
`;

export const MobilePaneInitial: React.FC<{
  setTagState: (tag: "shop" | "content") => void;
  currentSegmentKey: SwarovskiOptikSegmentKey | undefined;
  actions: Actions["actions"];
  active: boolean;
  t: HeaderT;
}> = ({ setTagState, currentSegmentKey, actions, active, t }) => {
  const className = "u-uppercase u-block u-w-full u-ta-left";

  const elements = [
    {
      label: t("navigation.searchTitle"),
      Icon: <Search css={svgStyles} />,
      action: actions.onSearchClicked,
    },
    {
      label: t("navigation.accountTitle"),
      Icon: <User css={svgStyles} />,
      action: actions.onAccountClicked,
    },
    {
      label: t("navigation.dealerLocatorTitle"),
      Icon: <Map css={svgStyles} />,
      action: actions.onDealerLocatorClicked,
    },
    {
      label: t("navigation.languagePickerTitle"),
      Icon: <Flag css={svgStyles} />,
      action: actions.onLanguagePickerClicked,
    },
    currentSegmentKey
      ? {
          label: t("navigation.segmentsTitle"),
          Icon: <SegmentIconSvg2 segment={currentSegmentKey} attachStyles={svgStyles} />,
          action: actions.onSegmentButtonClicked,
        }
      : null,
  ].filter(nonEmpty);

  const menuElements = [
    {
      label: t("navigation.shopLabel"),
      onClick: () => setTagState("shop"),
    },
    {
      label: t("navigation.contentLabel"),
      onClick: () => setTagState("content"),
    },
  ];

  return (
    <MobilePrimaryAndSecondaryPaneContainer
      attachStyles={leftRightPadding}
      primary={
        <ul
          className="u-flex u-flex-col"
          css={[
            css`
              @media (max-height: 699px) {
                gap: 30px;
              }

              gap: 40px;
              padding-top: 30px;
            `,
          ]}
        >
          {menuElements.map(({ label, onClick }) => (
            <li key={label.key}>
              <button css={styles} className={className} onClick={onClick}>
                <T>{label}</T>
              </button>
            </li>
          ))}
        </ul>
      }
      secondary={
        <div
          css={[
            css`
              @media (max-height: 699px) {
                padding-top: 12px;
                padding-bottom: 42px;
              }

              padding-top: 20px;
              padding-bottom: 64px;
            `,
          ]}
        >
          <ul
            className="u-flex u-flex-col"
            css={css`
              @media (max-height: 699px) {
                gap: 24px;
              }

              gap: 30px;
            `}
          >
            {elements.map(({ label, Icon, action }) => (
              <li key={label.key}>
                <button
                  className="u-grid u-ai-end"
                  css={[
                    fontSize.x25,
                    css`
                      grid-template-columns: 30px 1fr;
                    `,
                  ]}
                  onClick={() => action()}
                >
                  {Icon}
                  <span
                    css={css`
                      line-height: 1;
                    `}
                  >
                    <T>{label}</T>
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      }
    />
  );
};

const leftRightPadding = css`
  --px: 40px;

  padding-left: var(--px);
  padding-right: var(--px);
`;

const styles = [
  FONTS.SwarovskiFutura300,
  css`
    font-size: 52px;

    line-height: 1;

    word-break: break-word;
  `,
];
