import React from "react";
import { Link, LinkProps } from "react-router";
import cn from "classnames";
import { SerializedStyles } from "@emotion/react";

export function LinkOrSpan(
  props: Omit<LinkProps, "to"> & { to: LinkProps["to"] | undefined } & React.RefAttributes<HTMLAnchorElement> & {
      attachStyles?: SerializedStyles | Array<SerializedStyles>;
    },
) {
  const { to, className, attachStyles, state, ...rest } = props;

  if (to) {
    return <Link to={to} className={className} css={attachStyles} state={state} {...rest} />;
  } else {
    return <span className={cn(className, "_no-link")} css={attachStyles} {...rest} />;
  }
}
