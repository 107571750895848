import { useCallback, useState } from "react";
import { useOpaqueValue } from "../use-opaque-value";

import isEqual from "react-fast-compare";

export function useEqualState<S>(s: S): readonly [S, React.Dispatch<React.SetStateAction<S>>] {
  const [state, setState] = useState<S>(s);
  const stateRef = useOpaqueValue(state);

  const setEqualState = useCallback(
    (next: S | ((prevState: S) => S)) => {
      const s = next instanceof Function ? next(stateRef.current) : next;

      if (isEqual(stateRef.current, s)) {
        return;
      } else {
        setState(s);
      }
    },
    [setState],
  );

  return [state, setEqualState];
}
