import type { TranslatedValue } from "@swo/storefront";
import { HeaderT } from "../HeaderT";
import { PaneContentState } from "./PaneContentState";

export function getLabel(state: PaneContentState, t: HeaderT): TranslatedValue | null {
  if (state === null) {
    return t("navigation.menuLabel");
  }

  switch (state.tag) {
    case "pickers": {
      return null;
    }

    case "content-pane": {
      if (state.content === null || state.content?.data?.label) {
        return null;
      }

      switch (state.content.tag) {
        case "shop":
          return t("navigation.shopLabel");
        case "content":
          return t("navigation.contentLabel");
        default: {
          const _: never = state.content.tag;
          return _;
        }
      }
    }

    default: {
      const _: never = state;
      return _;
    }
  }
}
