import React from "react";
import { MainNavigationElements } from "./MainNavigationElements";
import { MainNavigationElement } from "./props";

export const LeftSideNavigation: React.FC<{
  mainNavigationElements: Array<MainNavigationElement>;
  onClick: (tag: "content" | "shop") => void;
  activeTag: "shop" | "content" | null;
}> = ({ mainNavigationElements, onClick, activeTag }) => {
  if (mainNavigationElements.length === 0) {
    return null;
  }

  return (
    <MainNavigationElements mainNavigationElements={mainNavigationElements} onClick={onClick} activeTag={activeTag} />
  );
};
