import { css } from "@emotion/react";
import type { AssetDocument } from "@swo/storefront";
import type React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { T } from "../T";
import { iconStyles } from "./FallbackImage";
import { SearchResultSummary } from "./SearchResultSummary";
import { SearchTag, tagPadding } from "./SearchTag";
import { PdfSvg } from "../../assets";
import { mimeToLabel } from "../../utils/formatting/mime-to-label";
import { formattedFileSizeInMegabytes } from "../../utils/formatting/downloads-size-in-megabytes";

export const AssetResult: React.FC<{ asset: AssetDocument; t: SearchT }> = ({ asset, t }) => {
  const label = t("search.results.others.tag.file");

  const fileTypeLabel = mimeToLabel(asset.file.contentType).toUpperCase();
  const size = asset.file.details?.size;
  const sizeInMB = size ? formattedFileSizeInMegabytes(size) : null;

  let sizeLabel = `${fileTypeLabel}`;
  if (sizeInMB) {
    sizeLabel += `/  ${sizeInMB} MB`;
  }

  return (
    <a
      href={asset.file.url}
      download
      target="_blank"
      rel="noopener noreferrer"
      className={"u-no-decoration u-color-inherit"}
      css={css`
        display: flex;
        flex-direction: column;

        &:hover {
          ._text {
            text-decoration: underline;
          }
        }
      `}
    >
      <div
        css={css`
          aspect-ratio: 16/10;
          background-color: ${CSS_COLORS.White};
          color: ${CSS_COLORS.Green};

          position: relative;
        `}
      >
        <div className="u-d-flex u-jc-center u-ai-center u-h-full">
          <PdfSvg css={iconStyles} />
        </div>

        <SearchTag>
          <T>{label}</T>
        </SearchTag>

        <span
          css={[
            css`
              position: absolute;
              right: 0;
              bottom: 0;
              color: ${CSS_COLORS.Black};
            `,
            tagPadding,
          ]}
        >
          {sizeLabel}
        </span>
      </div>

      <SearchResultSummary title={asset.title} description={asset.description} t={t} />
    </a>
  );
};
