export type ProductVariantAvailabilityStatus =
  | {
      tag: "not-yet-available-for-sale";
      availableFrom: string /* Date ISO string*/;
      inStockFrom?: string /* Date ISO string*/;
    }
  | { tag: "preorder"; availableQuantity: number; availableFrom: string /* Date ISO string*/ }
  | { tag: "in-stock"; availableQuantity: number } // should be > 0
  | { tag: "at-dealers-only" }
  | { tag: "out-of-stock"; inStockFrom?: string /* Date ISO string*/ }
  | { tag: "discontinued" };

export function isCheckoutable(status: ProductVariantAvailabilityStatus): boolean {
  switch (status.tag) {
    case "in-stock":
      return status.availableQuantity > 0;
    case "preorder":
      return status.availableQuantity > 0;
    case "at-dealers-only":
      return false;
    case "out-of-stock":
      return false;
    case "discontinued":
      return false;
    case "not-yet-available-for-sale":
      return false;
    default: {
      const _: never = status;
      return _;
    }
  }
}
