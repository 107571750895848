import type { SwarovskiOptikLocale } from "./locale";
import type { TypedMoney } from "@commercetools/platform-sdk";

type Locale = {
  code: SwarovskiOptikLocale["code"];
  country: "INTERNATIONAL" | { isoCode: string };
  language: { isoCode: string };
};

const showPriceForFloatingPrice = (
  price: { value: number; currencyCode: string },
  locale: Locale,
  avoidDecimalsIfPossible?: boolean,
) => {
  const localeWithInternationalFallback = getLocaleWithInternationalFallback(locale);

  let options: Intl.NumberFormatOptions = {
    style: "currency",
    currency: price.currencyCode,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  if (avoidDecimalsIfPossible) {
    // backwards compatibility
    if (price.currencyCode === "EUR" || price.currencyCode === "USD") {
      options.minimumFractionDigits = 0;
    }
  }

  let value = new Intl.NumberFormat(localeWithInternationalFallback, options).format(price.value);

  if (locale.country !== "INTERNATIONAL" && locale.country.isoCode === "CA") {
    value = value.replace("$", "CA$");
  }

  return value;
};

export function getLocaleWithInternationalFallback(locale: Locale) {
  return locale.country === "INTERNATIONAL" ? locale.language.isoCode : locale.code;
}

export const showPriceL = (localeForNumberFormat: Locale, avoidDecimalsIfPossible?: boolean) => {
  return (money: TypedMoney) => {
    const value = money.centAmount / 10 ** money.fractionDigits;

    return showPriceForFloatingPrice(
      { value, currencyCode: money.currencyCode },
      localeForNumberFormat,
      avoidDecimalsIfPossible,
    );
  };
};

export const showPrice = (money: TypedMoney, localeForNumberFormat: Locale, avoidDecimalsIfPossible?: boolean) => {
  return showPriceL(localeForNumberFormat, avoidDecimalsIfPossible)(money);
};

export function summed(prices: Array<TypedMoney>, opts: { fractionDigits: number; currencyCode: string }): TypedMoney {
  if (prices.length === 0) {
    return {
      type: "centPrecision",
      centAmount: 0,
      fractionDigits: opts.fractionDigits,
      currencyCode: opts.currencyCode,
    };
  }

  return {
    ...prices[0]!,
    centAmount: prices.map(p => p.centAmount).reduce((l, r) => l + r, 0),
  };
}

export function multiplied(times: number, price: TypedMoney): TypedMoney {
  return {
    ...price,
    centAmount: price.centAmount * times,
  };
}
