import "focus-visible/dist/focus-visible.min";
import "./utils/development/why-did-you-render";

import { Theme } from "@emotion/react";

import { PublicConfigurationT } from "@swo/shared";
import { Interpolation } from "@emotion/styled";
import { importBuildTarget } from "./importBuildTarget";
import { renderForRoot } from "./renderForRoot";

export type AppProps = { config: PublicConfigurationT; runtimeMode: "ssr" | "spa" };

declare module "react" {
  interface Attributes {
    css?: Interpolation<Theme>;
  }
}

export const MainApp = await importBuildTarget();

export function renderApp() {
  const el = document.getElementById("root");

  if (!el) {
    alert("Root element not found!");

    return null;
  }

  return renderForRoot(el);
}

void renderApp();
