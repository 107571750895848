import { css } from "@emotion/react";
import { CSS_MQ } from "./breakpoints";
import { swarovskiLogoStyles } from "../components/HeaderComponent/subcomponents/swarovskiLogoStyles";
import { mainNavigationComponentStyles } from "../components/_shared-components/image-related/FixedWholeScreenElement";
import { FONTS } from "./fonts";
import { utilitiesStyles } from "./utilities";
import { CSS_COLORS, globalCssVariablesDefinition } from "./styles-css-variables";
import { CONTENT_FADEOUT_CSS_CLASSNAME } from "../hooks/use-content-fadeout";
import { outlineStyles } from "./styles-global";

export const globalStyles = [
  css`
    // Using MinervaModern tilted by the browser for now for performance reasons
    // @font-face {
    //   font-family: MinervaModern;
    //   src: url("/assets/fonts/MINERVAMODERNItalic.woff2") format("woff2");
    //   font-weight: 500;
    //   font-style: italic;
    //   font-display: swap;
    // }

    * {
      box-sizing: border-box;
    }

    html {
      overflow-y: scroll;
    }

    html,
    body {
      font-size: 16px;

      ${FONTS.SwarovskiFutura500};
    }

    .smooth-scrolling {
      scroll-behavior: smooth;
    }

    body {
      ${globalCssVariablesDefinition};

      margin: 0;
      position: relative;

      ${CSS_MQ.atMostSm} {
        // setting it is beneficial for carousels
        // but breaks web navigation (previous/next) with touchpad
        // maybe it should be set dynamically with JS when user interacts with carousel?
        overscroll-behavior-x: contain;
      }

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    #root {
      &.${CONTENT_FADEOUT_CSS_CLASSNAME} {
        filter: brightness(0.3);
        background: white;
      }
    }

    #modal-root {
      position: relative;
      // z-index: 2;
    }

    .text-aa {
      -webkit-font-smoothing: auto;
      -moz-osx-font-smoothing: antialiased;
      font-smoothing: antialiased;
    }

    button {
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;

      color: inherit;

      font-size: 1rem;
      font-family: inherit;

      > svg {
        pointer-events: none; // safari intercepts onclick events otherwise
      }
    }

    button,
    a,
    input,
    video,
    div[tabindex] {
      &.focus-visible:focus {
        ${outlineStyles};
      }

      &:focus:not(.focus-visible) {
        outline: none;
      }
    }

    /*
      This will hide the focus indicator if the element receives focus via the mouse,
      but it will still show up on keyboard focus.
  */
    .js-focus-visible :focus:not(.focus-visible) {
      outline: none;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  `,
  swarovskiLogoStyles,
  mainNavigationComponentStyles,
  utilitiesStyles,
  css`
    .so-bg-white {
      background-color: ${CSS_COLORS.White};
    }

    .so-bg-green {
      background-color: ${CSS_COLORS.Green};
    }

    .so-bg-green-dark {
      background-color: ${CSS_COLORS.GreenDark};
    }

    .so-bg-gray2024 {
      background-color: ${CSS_COLORS.Gray2024};
    }

    .so-bg-gray2024-light {
      background-color: ${CSS_COLORS.GrayLight2024};
    }

    .so-bg-cool-mint {
      background-color: ${CSS_COLORS.CoolMint};
    }

    .so-bg-debug {
      background-color: red;
    }

    .so-color-green {
      color: ${CSS_COLORS.Green};
    }

    .so-color-white {
      color: ${CSS_COLORS.White};
    }

    .so-color-black {
      color: ${CSS_COLORS.Black};
    }

    .so-scrollbar-y {
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: ${CSS_COLORS.Grey6};
      }

      &::-webkit-scrollbar-thumb {
        background: ${CSS_COLORS.Green};
        border-radius: 0;
      }
    }
  `,
];
