import { css } from "@emotion/react";

export const paddingStyles = css`
  padding: var(--padding-top) 40px 20px 40px;
`;

export const paddingXStyles = css`
  padding: 0 20px 0 40px;
`;

export const paddingRStyles = css`
  padding-right: 40px;
`;
