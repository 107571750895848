import { EventT } from "@swo/salesforce-service";

type EventDateT = EventT["startDate"] | EventT["endDate"];

export function formatDateEventDateForLocale(realLocaleCode: string, date: EventDateT): string {
  return new Date(date).toLocaleDateString(
    [realLocaleCode, ...(typeof navigator !== "undefined" ? navigator.languages : [])],
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
  );
}

export function formatDateTimeEventDateForLocale(realLocaleCode: string, date: EventDateT): string {
  return new Date(date).toLocaleDateString(
    [realLocaleCode, ...(typeof navigator !== "undefined" ? navigator.languages : [])],
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    },
  );
}

export function getEventDates(
  startDate: EventT["startDate"],
  endDate: EventT["endDate"],
  realLocaleCode: string,
): string {
  if (!isOnTheSameDay(new Date(startDate), new Date(endDate))) {
    return `${formatDateEventDateForLocale(realLocaleCode, startDate)} — ${formatDateEventDateForLocale(
      realLocaleCode,
      endDate,
    )}`;
  } else {
    return formatDateTimeEventDateForLocale(realLocaleCode, startDate);
  }
}

function isOnTheSameDay(one: Date, another: Date) {
  return (
    one.getDay() === another.getDay() &&
    one.getMonth() === another.getMonth() &&
    one.getFullYear() &&
    another.getFullYear()
  );
}
