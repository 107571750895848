import { AppProps } from ".";

export function importBuildTarget(): Promise<React.FC<AppProps>> {
  if (process.env.REACT_APP_BUILD_TARGET === "editors-app") {
    console.log(`Editors app extension build.`);

    return import("./EditorsApp").then(m => m.default);
  } else {
    console.log(`Public frontend build.`);

    return import("./App").then(m => m.default);
  }
}
