import { LoadingStateIdle, LoadingStateWithIdle } from "../domain/data-layer/loading-state-with-idle";
import { Either } from "fp-ts/es6/Either";
import { FailureLoadedState, LoadingStateLoading } from "../domain/data-layer/loading-state";
import { useResourceRelatedInfo2 } from "./use-resource-related-info-2";

export function useResourceRelatedInfo<Resource, Version, Data, Result = Resource, Error = unknown>(
  resource: LoadingStateWithIdle<Resource>,
  versionBits: (resource: Resource) => Version,
  extract: (resource: Resource) => Either<Error, Data>,
  eff: (data: Data) => Promise<Result>,
  retryPolicy?: {
    maxRetries: number;
    delay: number;
  },
  failureCase?: (u: Error) => LoadingStateIdle | LoadingStateLoading | FailureLoadedState,
): LoadingStateWithIdle<{ result: Result; version: Version }> {
  return useResourceRelatedInfo2(resource, versionBits, extract, eff, failureCase, retryPolicy).state;
}
