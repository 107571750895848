import { useCallback, useEffect, useState } from "react";
import { headerClassName } from "./MainHeaderComponent";

export type HeaderPinnedState = "pinned" | "unpinned" | "unfixed" | null;

export function useHeaderPinnedState() {
  const [state, setState] = useState<HeaderPinnedState>(null);

  const onPin = useCallback(() => {
    setState("pinned");
  }, []);

  const onUnpin = useCallback(() => {
    setState("unpinned");
  }, []);

  const onUnfix = useCallback(() => {
    setState("unfixed");
  }, []);

  useEffect(() => {
    const className = document.querySelector(`.${headerClassName}`)?.children[0]?.className;

    if (className?.includes("headroom--unfixed")) {
      return setState("unfixed");
    } else if (className?.includes("headroom--unpinned")) {
      setState("unpinned");
    } else {
      setState("pinned");
    }
  }, []);

  return [state, { onPin, onUnpin, onUnfix }] as const;
}
