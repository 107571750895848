import React, { useEffect } from "react";

export function useOnScroll<T extends HTMLElement | Document>(
  ref: React.RefObject<T | null>,
  onScrollCallback: (e: any) => void,
) {
  useEffect(() => {
    const { current } = ref;

    if (current) {
      current.addEventListener("scroll", onScrollCallback);

      return () => {
        if (current) {
          current.removeEventListener("scroll", onScrollCallback);
        }
      };
    }
  }, [onScrollCallback]);
}

export function useWindowScroll(cb: () => void) {
  useEffect(() => {
    window.addEventListener("scroll", cb);

    return () => {
      window.removeEventListener("scroll", cb);
    };
  }, [cb]);
}
