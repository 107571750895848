import { Either } from "fp-ts/lib/Either";

export function fetchApi<T>(endpoint: RequestInfo, options?: RequestInit): Promise<T> {
  return fetch(endpoint, options)
    .then(response => {
      if (!response.ok) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw response;
      }

      if (response.headers.get("content-type") === "application/json") {
        return response.json();
      } else if (response.headers.get("content-type") === "application/octet-stream") {
        return response.blob();
      } else {
        return response.text();
      }
    })
    .then((json: unknown) => json as T);
}

export function fetchApiJson<T>(endpoint: RequestInfo, options?: RequestInit): Promise<T> {
  return fetch(endpoint, options)
    .then(response => {
      if (!response.ok) {
        return response.json().then(errorResponse => {
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          return Promise.reject({ status: response.status, errorResponse });
        });
      } else {
        return response.json();
      }
    })
    .then((json: unknown) => json as T);
}

export async function fetchApiJson2<T extends Either<any, any>>(
  fallback: T,
  endpoint: RequestInfo,
  options?: RequestInit,
): Promise<T> {
  function isEither(u: unknown): u is Either<unknown, unknown> {
    return (u as any)?._tag === "Left" || (u as any)?._tag === "Right";
  }

  try {
    const response = await fetch(endpoint, options);

    if (!response.ok) {
      const errorResponse = await response.json();

      if (isEither(errorResponse)) {
        return errorResponse as T;
      } else {
        return fallback;
      }
    } else {
      const json = await response.json();

      if (isEither(json)) {
        return json as T;
      } else {
        return fallback;
      }
    }
  } catch {
    return Promise.resolve(fallback);
  }
}

export function getEndpointForFetch(path: string, baseUrl?: URL | string): string {
  if (typeof baseUrl === "undefined") {
    return path;
  } else {
    return new URL(path, baseUrl).toString();
  }
}
