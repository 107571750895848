import React from "react";
import { ReactTFC } from "../../utils/fp/missing-ts-parts";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { Spacer } from "../../components/_shared-components/arrangement/Space";
import { PageHeadline } from "../../components/_shared-components/ui-elements/PageHeadline";
import { FONTS, fontSize } from "../../styles/fonts";

export const GenericNotFoundPageLayoutShell: ReactTFC<{
  headline: React.ReactNode;
  paragraph: React.ReactNode;
  children?: React.ReactNode;
}> = ({ headline, paragraph, children }) => {
  return (
    <div
      className="u-flex u-flex-col u-items-center"
      css={css`
        padding-top: 100px;
      `}
    >
      <PageHeadline>{headline}</PageHeadline>

      <hr
        css={css`
          width: 100%;
          height: 1px;
          background-color: black;
          border: none;
          margin: 0;
        `}
      />

      <Spacer size={"md"} />

      <span
        className="u-uppercase u-ta-center"
        css={[
          FONTS.SwarovskiFutura300,
          fontSize.x4,
          css`
            color: ${CSS_COLORS.Green};
            margin-bottom: 4em;
          `,
        ]}
      >
        {paragraph}
      </span>

      {children}
    </div>
  );
};
