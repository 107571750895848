import { css } from "@emotion/react";
import { ContactDataType } from "@swo/shared";
import { HeaderNavigationDataT, type TranslatedValue } from "@swo/storefront";
import React from "react";
import { CSS_MQ } from "../../../styles/breakpoints";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { HeaderNavigationDataItem } from "./HeaderNavigationDataItem";
import { MobilePaneContentShell } from "./MobilePaneContentShell";
import { MobilePrimaryContentPane } from "./MobilePrimaryContentPane";
import { ContentPaneContent } from "./PaneContentState";

export const MobilePaneContent: React.FC<{
  data: HeaderNavigationDataT;
  state: ContentPaneContent;
  goBack: () => void;
  setSeconadary: (label: TranslatedValue | string) => void;
  contactData: ContactDataType;
  isActive: boolean;
  selectedLabel: TranslatedValue | string | undefined;
  className: string;
  requestCollapsingNavigation: () => void;
}> = ({
  data,
  state,
  goBack,
  setSeconadary,
  contactData,
  isActive,
  selectedLabel,
  className,
  requestCollapsingNavigation,
}) => {
  return (
    <MobilePaneContentShell
      data={data}
      setSeconadary={setSeconadary}
      theme={state.theme}
      isActive={isActive}
      className={className}
    >
      {data.primary.map(p => {
        const selectedLabelStr = selectedLabel
          ? typeof selectedLabel === "string"
            ? selectedLabel
            : selectedLabel.value
          : undefined;

        const isElemActive = isActive && !!selectedLabelStr && selectedLabelStr === p.label;

        return (
          <MobilePrimaryContentPane key={JSON.stringify(p)} content={p} goBack={goBack} isActive={isElemActive}>
            <HeaderNavigationDataItem
              item={p}
              contactData={contactData}
              requestCollapsingNavigation={requestCollapsingNavigation}
            />
          </MobilePrimaryContentPane>
        );
      })}
    </MobilePaneContentShell>
  );
};

export const innermostPaneStyles = css`
  --mt: 0px;
  --ml: 40px;
  top: calc(-1 * var(--mt));
  left: var(--ml);

  height: 100%;
  width: calc(100% - var(--ml));

  background-color: #f3f5f5;
  border-top-left-radius: 8px;

  padding-top: 20px;
  ${CSS_MQ.atLeastMd} {
    padding-top: 40px;
  }

  box-shadow: 0px 0px 16px 0px #00000040;
  border-top: 1px solid ${CSS_COLORS.Gray2024};
  border-left: 1px solid ${CSS_COLORS.Gray2024};

  // 20px to account for box shadow
  transform: translateX(calc(100% + 20px));
  transition: transform 0.1s ease-out;

  overflow-y: auto;

  &.active {
    transform: translateX(0);
  }
`;

export const paddingStyles = css`
  --padding-x: 20px;

  padding-left: var(--padding-x);
  padding-right: var(--padding-x);
`;

export const styles = [
  paddingStyles,
  css`
    --mlS: 20px;
    margin-top: calc(-1 * var(--mt));
    margin-left: var(--mlS);

    &.no-margin {
      --mlS: 0px;

      padding-left: 0;
      padding-right: 0;
    }
  `,
];

export const internalPaneStyles = css`
  --p: 20px;
  padding: var(--p);

  --ml: 40px;

  margin-left: var(--ml);

  margin-top: 0;

  --pane-bg-color: ${CSS_COLORS.Unknown3};

  &.theme-dark {
    --pane-bg-color: ${CSS_COLORS.Green};
  }

  background-color: var(--pane-bg-color);

  border-top-left-radius: 8px;

  // fallback
  max-height: calc(100vh - 90px);
  max-height: calc(100dvh - 90px);

  overflow-y: scroll;
  overflow-x: hidden;

  position: absolute;
  width: calc(100% - var(--ml));
  height: 100%;

  transition: all 0.15s ease-out;
  transform: translateX(calc(100% + 20px));

  &.active {
    transform: translateX(0);
    box-shadow: 0px 0px 16px 0px #00000040;
  }
`;
