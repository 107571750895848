import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { EMOTION_CSS_SSR_KEY } from "./config";
import { MainApp } from ".";
import { readPublicConfig } from "./domain/contentful/get-window-config";
import { DevelopmentAppShell } from "./DevelopmentAppShell";
import { ConfigNotFound } from "./ConfigNotFound";

export function renderForRoot(el: HTMLElement) {
  const localConfig = readPublicConfig();

  const isSSRed = el.hasChildNodes();
  if (isSSRed) {
    const cache = createCache({ key: EMOTION_CSS_SSR_KEY });

    if (localConfig === null) {
      const root = createRoot(el);

      return root.render(<ConfigNotFound />);
    }

    return hydrateRoot(
      el,
      <CacheProvider value={cache}>
        <MainApp config={localConfig} runtimeMode={"ssr"} />
      </CacheProvider>,
    );
  } else {
    const root = createRoot(el);

    return root.render(<DevelopmentAppShell localConfig={localConfig} />);
  }
}
