import React from "react";
import { MobilePaneContent } from "./InternalPane";
import { MobilePaneInitial } from "./InternalPaneFirst";
import { NewHeaderData, SwarovskiOptikLocaleFrontend, type HeaderLanguagePicker, type TranslatedValue } from "@swo/storefront";
import { ContactDataType, SwarovskiOptikSegmentKey, type Link, type SwarovskiOptikSegmentShort } from "@swo/shared";
import { ContentPaneContent, PaneContentState, PickersPaneContentState, STANDARD_PROPS } from "./PaneContentState";
import { Actions } from "./useActions";
import { State } from "./HeaderNavigationMobilePane";
import { MobilePaneContentRawShell } from "./MobilePaneContentRawShell";
import { PickersPaneContent } from "./PickersPaneContent";
import { HeaderT } from "../HeaderT";
import { HeaderLinks } from "../ContextLessHeaderComponent";
import cn from "classnames";

export const ContentPaneMobileContent: React.FC<{
  data: NewHeaderData;
  state: NonNullable<PaneContentState>;

  contents: Array<ContentPaneContent>;
  pickersContents: Array<PickersPaneContentState>;

  currentSegmentKey: SwarovskiOptikSegmentKey | undefined;
  contactData: ContactDataType;
  setState: React.Dispatch<React.SetStateAction<State>>;
  actions: Actions["actions"];
  locale: SwarovskiOptikLocaleFrontend;
  segment: SwarovskiOptikSegmentShort | null;
  t: HeaderT;
  links: HeaderLinks;
  languagePickerData: HeaderLanguagePicker;
  quickLinks: Array<Link>;
}> = ({
  contents,
  pickersContents,
  data,
  state,
  currentSegmentKey,
  contactData,
  setState,
  actions,
  locale,
  segment,
  t,
  links,
  languagePickerData,
  quickLinks,
}) => {
  const setContentTagState = (tag: "shop" | "content") => {
    return setState({
      tag: "content-pane",
      content: {
        tag,
        data: null,
        ...STANDARD_PROPS,
        title: tag === "shop" ? t("navigation.shopLabel") : t("navigation.contentLabel"),
      },
    });
  };

  const contentGoBack = (content: ContentPaneContent) => {
    setState({
      tag: "content-pane",
      content: {
        ...content,
        data: null,
      },
    });
  };

  const setContentSecondLevel = (content: ContentPaneContent) => (label: TranslatedValue | string) => {
    setState({
      tag: "content-pane",
      content: {
        ...content,
        data: {
          label,
        },
      },
    });
  };

  return (
    <>
      <MobilePaneInitial
        setTagState={setContentTagState}
        currentSegmentKey={currentSegmentKey}
        actions={actions}
        active={state.tag === "content-pane" && state.content === null}
        t={t}
      />

      {contents.map(content => {
        const selectedLabel = state.tag === "content-pane" ? state.content?.data?.label : undefined;

        return (
          <MobilePaneContent
            key={content.tag}
            className={cn(`js-mobile-content-pane-content-${content.tag}`)}
            data={content.tag === "shop" ? data.shop : data.discover}
            state={content}
            goBack={() => contentGoBack(content)}
            setSeconadary={setContentSecondLevel(content)}
            contactData={contactData}
            isActive={state.tag === "content-pane" && content.tag === state.content?.tag}
            selectedLabel={selectedLabel}
            requestCollapsingNavigation={() => setState(null)}
          />
        );
      })}

      {pickersContents.map(content => {
        const isActive = state.tag === "pickers" && state.content?.tag === content.tag;

        return (
          <MobilePaneContentRawShell
            key={content.tag}
            theme={content.theme}
            isActive={isActive}
            className={cn(`js-mobile-content-pane-pickers-${content.tag}`)}
          >
            <PickersPaneContent
              paneContent={content}
              contactData={contactData}
              languagePickerData={languagePickerData}
              links={links}
              locale={locale}
              quickLinks={quickLinks}
              segment={segment}
              t={t}
              canUseSearchInputFocus={false}
            />
          </MobilePaneContentRawShell>
        );
      })}
    </>
  );
};
