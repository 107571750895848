import React from "react";
import { css } from "@emotion/react";
import { fontSize } from "../../../styles/fonts";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { LinkNode } from "./LinkNode";
import { Node } from "./Links";
import { CSS_MQ } from "../../../styles/breakpoints";

export const LinksChildren: React.FC<{ nodes: NonEmptyArray<Node> }> = ({ nodes }) => {
  return (
    <ul
      className="u-flex u-flex-col"
      css={[
        css`
          margin-top: 16px;

          padding-left: 30px;
          gap: 6px;
          ${CSS_MQ.atLeastMd} {
            gap: 4px;
            padding-left: 40px;

            // reserve space for 'new' (nouveau in French) label
            padding-right: 12px;
          }
        `,
      ]}
    >
      {nodes.map(({ link, isNew }) => (
        <li key={link.label}>
          <span css={[fontSize.x25]}>
            <LinkNode isNew={isNew} link={link} />
          </span>
        </li>
      ))}
    </ul>
  );
};
