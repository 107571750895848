import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { NewHeaderData, HeaderNavigationDataT, SwarovskiOptikLocaleFrontend, type HeaderLanguagePicker } from "@swo/storefront";
import {
  HeaderNavigationPaneTitleLabel,
  HeaderNavigationPaneTitleLabelWithWrapper,
} from "./HeaderNavigationPaneTitleLabel";
import { ContactDataType, SwarovskiOptikSegmentKey, type Link, type SwarovskiOptikSegmentShort } from "@swo/shared";
import {
  ContentPaneContent,
  PaneContentState,
  PaneContentState__Content,
  PickersPaneContentState,
} from "./PaneContentState";
import { Actions } from "./useActions";
import { getLabel } from "./getLabel";
import { ContentPaneMobileContent } from "./ContentPaneMobileContent";
import { HeaderT } from "../HeaderT";
import { HeaderLinks } from "../ContextLessHeaderComponent";
import { PaneNavigation } from "./PaneNavigation";
import { T } from "../../T";

export type State = PaneContentState;

type HeaderNavigationMobilePaneProps = {
  contents: Array<ContentPaneContent>;
  pickersContents: Array<PickersPaneContentState>;
  data: NewHeaderData;
  contactData: ContactDataType;
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  currentSegmentKey: SwarovskiOptikSegmentKey | undefined;
  actions: Actions["actions"];
  locale: SwarovskiOptikLocaleFrontend;
  segment: SwarovskiOptikSegmentShort | null;
  t: HeaderT;
  links: HeaderLinks;
  languagePickerData: HeaderLanguagePicker;
  quickLinks: Array<Link>;
};

export const HeaderNavigationMobilePane: React.FC<HeaderNavigationMobilePaneProps> = ({
  contents,
  pickersContents,
  data,
  contactData,
  state,
  setState,
  currentSegmentKey,
  actions,
  locale,
  segment,
  t,
  links,
  languagePickerData,
  quickLinks,
}) => {
  const label = getLabel(state, t);

  const Title = label ? (
    <HeaderNavigationPaneTitleLabelWithWrapper>
      <T>{label}</T>
    </HeaderNavigationPaneTitleLabelWithWrapper>
  ) : null;

  if (state === null) {
    return null;
  }

  return (
    <div className="u-relative u-grow u-flex u-flex-col">
      <PaneNavigation state={state} setState={setState} t={t} />

      {Title}

      <ContentPaneMobileContent
        contents={contents}
        pickersContents={pickersContents}
        data={data}
        state={state}
        setState={setState}
        contactData={contactData}
        currentSegmentKey={currentSegmentKey}
        actions={actions}
        locale={locale}
        segment={segment}
        t={t}
        links={links}
        languagePickerData={languagePickerData}
        quickLinks={quickLinks}
      />
    </div>
  );
};
