import { createFallbackImage, createJpgAndWebpLinks, createPngAndWebpLinks } from "./createJpgAndWebpLinks";
import { ContentfulImageProps } from "./ContentfulImage";

export function transformImage(contentfulLink: ContentfulImageProps["contentfulLink"]): TransformImageT | string {
  const plainContentfulLink = typeof contentfulLink === "string" ? contentfulLink : contentfulLink.url;

  const requestedWidthWithFallback =
    typeof contentfulLink !== "string"
      ? contentfulLink.requestedWidth
      : 2000; /* Hardcoded for now as an hard upper limit. Please revisit and adjust per content*/

  if (plainContentfulLink.endsWith(".svg")) {
    // Just in case...
    return plainContentfulLink;
  } else {
    return transformRasterImageOf(plainContentfulLink, contentfulLink, requestedWidthWithFallback);
  }
}

export function transformRasterImageOf(
  plainContentfulLink: string,
  contentfulLink: ContentfulImageProps["contentfulLink"],
  requestedWidthWithFallback: number,
): TransformImageT {
  const withTransparency =
    plainContentfulLink.endsWith(".png") &&
    (typeof contentfulLink === "string" || !contentfulLink.noTransparentBackground);

  return transformRasterImage(plainContentfulLink, withTransparency, requestedWidthWithFallback);
}

export type TransformImageT =
  | {
      tag: "transparency-suspected";
      pngLink: string;
      webpLink: string;
      width: number;
      fallback: string;
    }
  | {
      tag: "visual-image";
      jpgLink: string;
      webpLink: string;
      width: number;
      fallback: string;
    };

export function transformRasterImage(
  plainContentfulLink: string,
  withTransparency: boolean,
  requestedWidthWithFallback: number,
): TransformImageT {
  if (withTransparency) {
    // PNGs have transparency as we assume that it is leverage
    // so WebP for browsers supporting it and PNG for others
    // It might have some performance consequences but it's better than
    // white background for images with transparent background

    const { pngLink, webpLink } = createPngAndWebpLinks(plainContentfulLink, requestedWidthWithFallback);

    return {
      tag: "transparency-suspected" as const,

      pngLink,
      webpLink,
      width: requestedWidthWithFallback,

      fallback: createFallbackImage(plainContentfulLink, requestedWidthWithFallback),
    };
  } else {
    const { jpgLink, webpLink } = createJpgAndWebpLinks(plainContentfulLink, requestedWidthWithFallback);

    return {
      tag: "visual-image" as const,

      jpgLink,
      webpLink,
      width: requestedWidthWithFallback,

      fallback: createFallbackImage(plainContentfulLink, requestedWidthWithFallback),
    };
  }
}

export function wrapToHtmlSourceElements(
  transformed: TransformImageT | string,
): Array<{ srcSet: Array<{ link: string; width: number }>; srcSetCssStr: string; type: string }> {
  if (typeof transformed === "string") {
    return [];
  } else if (transformed.tag === "transparency-suspected") {
    return [
      {
        type: "image/webp",
        srcSet: [{ link: transformed.webpLink, width: transformed.width }],
        srcSetCssStr: toCssSrcSet([{ link: transformed.webpLink, width: transformed.width }]),
      },
      {
        type: "image/png",
        srcSet: [{ link: transformed.pngLink, width: transformed.width }],
        srcSetCssStr: toCssSrcSet([{ link: transformed.pngLink, width: transformed.width }]),
      },
    ];
  } else {
    return [
      {
        type: "image/webp",
        srcSet: [{ link: transformed.webpLink, width: transformed.width }],
        srcSetCssStr: toCssSrcSet([{ link: transformed.webpLink, width: transformed.width }]),
      },
      {
        type: "image/jpeg",
        srcSet: [{ link: transformed.jpgLink, width: transformed.width }],
        srcSetCssStr: toCssSrcSet([{ link: transformed.jpgLink, width: transformed.width }]),
      },
    ];
  }
}

export function toCssSrcSet(arr: Array<{ width: number; link: string }>): string {
  return arr.map(s => `${s.link} ${s.width}w`).join(", ");
}
