import React from "react";
import { css } from "@emotion/react";
import cn from "classnames";

export const PaneCloseNavigationLayer: React.FC<{
  isActive: boolean;
  onClose: () => void;
  side: "left" | "right";
}> = props => {
  return (
    <div
      className={cn(props.isActive ? "active" : "", `pane-${props.side}`)}
      onClick={props.onClose}
      css={css`
        position: absolute;
        width: calc(100% - var(--pane-width));
        height: 100%;
        top: 0;

        &.pane-left {
          right: 0;
        }
        &.pane-right {
          left: 0;
        }

        z-index: 1;

        &:not(.active) {
          display: none;
        }
      `}
    />
  );
};
