import { productVariantAvailabilityStatus } from "./availability-states";
import type { ProductVariantAvailabilityStatus } from "./availability-status";

export function mergeProductVariantAvailabilityStatuses(
  s1: ProductVariantAvailabilityStatus,
  s2: ProductVariantAvailabilityStatus,
): ProductVariantAvailabilityStatus {
  function availabilityOfSet<T extends { availableQuantity: number }, P extends { availableQuantity: number }>(
    one: T,
    another: P,
  ): number {
    return one.availableQuantity > another.availableQuantity ? another.availableQuantity : one.availableQuantity;
  }

  function inStockFromOfSet<T extends { inStockFrom?: string }>(one: T, another: T): string | undefined {
    return one.inStockFrom && another.inStockFrom
      ? new Date(one.inStockFrom) > new Date(another.inStockFrom)
        ? one.inStockFrom
        : another.inStockFrom
      : one.inStockFrom || another.inStockFrom;
  }

  function availableFromOfSet<T extends { availableFrom: string }>(one: T, another: T): Date {
    const d = new Date(one.availableFrom) > new Date(another.availableFrom) ? one.availableFrom : another.availableFrom;

    return new Date(d);
  }

  if (s1.tag === "in-stock" && s2.tag === "in-stock") {
    return productVariantAvailabilityStatus.inStockOfQuantity(availabilityOfSet(s1, s2));
  } else if (s1.tag === "discontinued" || s2.tag === "discontinued") {
    return { tag: "discontinued" };
  } else if (s1.tag === "not-yet-available-for-sale" && s2.tag === "not-yet-available-for-sale") {
    return {
      tag: "not-yet-available-for-sale",
      availableFrom: new Date(s1.availableFrom) > new Date(s2.availableFrom) ? s1.availableFrom : s2.availableFrom,
    };
  } else if (s1.tag === "not-yet-available-for-sale") {
    return { tag: "not-yet-available-for-sale", availableFrom: s1.availableFrom };
  } else if (s2.tag === "not-yet-available-for-sale") {
    return { tag: "not-yet-available-for-sale", availableFrom: s2.availableFrom };
  } else if (s1.tag === "at-dealers-only" || s2.tag === "at-dealers-only") {
    return { tag: "at-dealers-only" };
  } else if (s1.tag === "out-of-stock" && s2.tag === "out-of-stock") {
    return {
      tag: "out-of-stock",
      inStockFrom: inStockFromOfSet(s1, s2),
    };
  } else if (s1.tag === "out-of-stock") {
    return {
      tag: "out-of-stock",
      inStockFrom: s1.inStockFrom,
    };
  } else if (s2.tag === "out-of-stock") {
    return {
      tag: "out-of-stock",
      inStockFrom: s2.inStockFrom,
    };
  } else if (s1.tag === "preorder" && s2.tag == "preorder") {
    return productVariantAvailabilityStatus.preorder(availabilityOfSet(s1, s2), availableFromOfSet(s1, s2));
  } else if (s1.tag === "preorder" && s2.tag == "in-stock") {
    return productVariantAvailabilityStatus.preorder(availabilityOfSet(s1, s2), new Date(s1.availableFrom));
  } else if (s1.tag === "in-stock" && s2.tag == "preorder") {
    return productVariantAvailabilityStatus.preorder(availabilityOfSet(s1, s2), new Date(s2.availableFrom));
  } else {
    return { tag: "out-of-stock" };
  }
}
