import React from "react";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { NewLabel, labelWithNewAttributeClassName } from "../../_shared-components/new/new-label";
import cn from "classnames";

export const HeadlineWithNew: React.FC<{ headline: string; isNew: boolean; className?: string }> = ({
  headline,
  isNew,
  className,
}) => {
  const splitHeadline = headline.split(" ");
  const [last, ...allButLastReversed] = splitHeadline.reverse();
  const allButLast = allButLastReversed.reverse();
  const allButLastJoined = allButLast.join(" ");

  const wrapperStyles = [
    css`
      color: var(--primary-text-color);
      display: block;

      &.with-new {
        padding-right: 1.5em;
        display: block;
      }
    `,
  ];

  return (
    <span css={wrapperStyles} className={cn({ "with-new": isNew }, className)}>
      <span
        css={css`
          word-break: break-word;
        `}
      >
        {allButLastJoined ? (
          <>
            <span>{allButLastJoined}</span>{" "}
          </>
        ) : null}

        <span className={labelWithNewAttributeClassName}>
          {last}{" "}
          {isNew && (
            <NewLabel
              css={css`
                font-size: 0.5em;
                color: ${CSS_COLORS.Green};
              `}
            />
          )}
        </span>
      </span>
    </span>
  );
};
