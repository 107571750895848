import { css } from "@emotion/react";
import { HEADER_HIDDEN_CSS_CLASSNAME } from "../../../hooks/use-header-hidden";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";

export const headroomStyles = css`
  z-index: 100;

  &.has-opened-pane {
    ${CSS_MQ.atMostSm} {
      z-index: 2147483002; // intercom z-index + 1
    }
  }

  position: absolute;
  width: 100%;

  .headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .headroom--unfixed {
    position: relative;
    transform: translateY(0);
  }

  .headroom--scrolled {
    transition: transform 200ms linear;
  }

  .headroom--unpinned {
    position: fixed;
    transform: translateY(-100%);
  }

  .headroom--pinned {
    position: fixed;
    transform: translateY(0%);
  }

  .${HEADER_HIDDEN_CSS_CLASSNAME} & {
    visibility: hidden;
  }

  --padding: 12px;
  ${CSS_MQ.atLeastMd} {
    --padding: 20px;
  }
  ${CSS_MQ.atLeastLg} {
    --padding: 32px;
  }
`;
