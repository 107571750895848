import React from "react";
import cn from "classnames";
import { css } from "@emotion/react";

import { CSS_MQ } from "../../../styles/breakpoints";
import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import { CSS_SPACER_SIZES } from "../../../styles/styles-css-variables";

export const Spacer = (props: { size: "sm" | "smd" | "md" | "lg" }) => {
  return (
    <div
      className={cn(`spacer`, `size-${props.size}`)}
      css={css`
        &.size-sm {
          height: ${CSS_SPACER_SIZES.sm};
        }

        &.size-smd {
          height: ${CSS_SPACER_SIZES.smd};
        }

        &.size-md {
          height: ${CSS_SPACER_SIZES.md};
        }

        &.size-lg {
          height: ${CSS_SPACER_SIZES.lg};
        }
      `}
    />
  );
};

export const SpacerCustom: ReactTFC<{ mobile: number; desktop: number }> = props => {
  return (
    <div
      className={cn("spacer")}
      css={css`
        --size: ${props.mobile}px;
        ${CSS_MQ.atLeastMd} {
          --size: ${props.desktop}px;
        }

        height: var(--size);
      `}
    />
  );
};
