import { useCurrentWidth } from "../../../hooks/use-current-width";

export function usePinStart() {
  const currentWidth = useCurrentWidth();

  if (!currentWidth) {
    return 0;
  }

  return 0;
}
