import { DealerDto } from "@swo/salesforce-service";
import { mathRound } from "../../../../utils/js/missing-js-parts";
import {
  precision,
  SEARCH_QUERY_CLICK_AND_COLLECT,
  SEARCH_QUERY_DEALER_ID,
  SEARCH_QUERY_GROUPS,
  SEARCH_QUERY_LATLNG_URL_PARAM,
  separator,
} from "./config";
import { DealerLocatorAppState } from "./index";

export function writeUrlParams(
  query: DealerLocatorAppState["query"],
  groups: Array<DealerDto["groups"][number]>,
  clickAndCollect: boolean,
  selectedDealerId: string | null,
): string {
  function toUrlValue(latLngLiteral: google.maps.LatLngBoundsLiteral): string {
    const values = [latLngLiteral.north, latLngLiteral.south, latLngLiteral.west, latLngLiteral.east];

    return values.map(v => mathRound(v, precision)).join(separator);
  }

  let search = new URLSearchParams();

  if (query.tag === "country") {
    // don't serialize it - it's already derived from locale
  } else if (query.tag === "bounds") {
    search.set(SEARCH_QUERY_LATLNG_URL_PARAM, toUrlValue(query.value));
  }

  groups.forEach(group => {
    search.append(SEARCH_QUERY_GROUPS, group);
  });

  if (clickAndCollect) {
    search.set(SEARCH_QUERY_CLICK_AND_COLLECT, "true");
  }

  if (selectedDealerId) {
    search.set(SEARCH_QUERY_DEALER_ID, selectedDealerId);
  }

  return search.toString();
}
