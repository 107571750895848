import { useEffect, useRef } from "react";

export function useEffectOnce(effect: () => boolean, dependencies: Array<unknown>) {
  const triggeredRef = useRef(false);

  useEffect(() => {
    if (triggeredRef.current) {
      return;
    }

    const res = effect();

    if (res) {
      triggeredRef.current = true;
    }
  }, dependencies);
}
