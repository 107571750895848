import { css } from "@emotion/react";
import type React from "react";
import { fontSize } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";

export const tagPadding = css`
  padding: 4px 8px;
  line-height: 1;
`;

export const SearchTag: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const tag = [
    fontSize.x2s,
    tagPadding,
    css`
      background-color: ${CSS_COLORS.White};
      color: ${CSS_COLORS.Green};

      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    `,
  ];

  return <span css={tag}>{children}</span>;
};
