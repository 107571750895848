import type { ProductVariantAvailabilityStatus } from "./availability-status";

const notYetAvailableForSale = (availableFrom: Date, inStockFrom?: Date): ProductVariantAvailabilityStatus => ({
  tag: "not-yet-available-for-sale",
  availableFrom: availableFrom.toISOString(),
  inStockFrom: inStockFrom?.toISOString(),
});

const preorder = (availableQuantity: number, availableFrom: Date): ProductVariantAvailabilityStatus =>
  availableQuantity > 0
    ? {
        tag: "preorder",
        availableQuantity,
        availableFrom: availableFrom.toISOString(),
      }
    : {
        tag: "out-of-stock",
      };

const discontinued: ProductVariantAvailabilityStatus = { tag: "discontinued" };

const inStockOfQuantity: (availableQuantity: number, inStockFrom?: Date) => ProductVariantAvailabilityStatus = (
  availableQuantity,
  inStockFrom,
) =>
  availableQuantity > 0
    ? {
        tag: "in-stock",
        availableQuantity,
      }
    : {
        tag: "out-of-stock",
        inStockFrom: inStockFrom?.toISOString(),
      };

const outOfStock: (inStockFrom?: Date) => ProductVariantAvailabilityStatus = (inStockFrom?: Date) => ({
  tag: "out-of-stock",
  inStockFrom: inStockFrom?.toISOString(),
});

export const productVariantAvailabilityStatus = {
  notYetAvailableForSale,
  preorder,
  discontinued,
  inStockOfQuantity,
  outOfStock,
};
