import React from "react";

import { SerializedStyles, css } from "@emotion/react";

import { CSS_COLORS } from "../../styles/styles-css-variables";
import { FONTS } from "../../styles/fonts";
import { SwarovskiOptikLocaleFrontend } from "@swo/storefront";
import { Flag } from "../../assets";
import { navigationButtonStyles } from "../SegmentFloatingButtonComponent/navigationButtonStyles";
import cn from "classnames";
import { LanguagePickerCountryFlag } from "../LanguagePickerComponent/LanguagePickerCountryFlag";

type Props = React.PropsWithChildren<{
  onLanguagePickerClicked: () => void;
  locale: SwarovskiOptikLocaleFrontend;
  className?: string;
  attachStyles?: SerializedStyles;
}>;

export const LanguagePickerButton: React.FC<Props> = props => {
  return (
    <button
      onClick={() => props.onLanguagePickerClicked()}
      className={cn("u-uppercase", props.className, "flag-button")}
      css={[FONTS.SwarovskiFutura500, navigationButtonStyles, props.attachStyles]}
    >
      {props.locale.country === "INTERNATIONAL" ? (
        <Flag />
      ) : (
        <LanguagePickerCountryFlag countryIsoCode={props.locale.country.isoCode} />
      )}

      {props.children}
    </button>
  );
};
