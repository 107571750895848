import type { Maybe, SwarovskiOptikSegmentKey } from "@swo/shared";
import { useCallback, useState } from "react";
import { useOnRouteChange } from "../../../hooks/use-on-route-change";
import type { HeaderT } from "../HeaderT";
import type { PaneContentState } from "./PaneContentState";
import type { Segments } from "./props";
import { segmentPickerState } from "./useActions";

export function usePaneContent({
  userPreferredSegmentKey,
  showSegmentSelector,
  segments,
  t,
}: {
  userPreferredSegmentKey: Maybe<SwarovskiOptikSegmentKey>;
  showSegmentSelector: boolean;
  segments: Segments | null;
  t: HeaderT;
}) {
  const getInitialState = useCallback((): PaneContentState => {
    if (typeof userPreferredSegmentKey === "undefined" && !showSegmentSelector && segments) {
      return segmentPickerState(segments, t);
    } else {
      return null;
    }
  }, [JSON.stringify(segments)]);

  const [paneContent, setPaneContent] = useState<PaneContentState>(getInitialState);

  useOnRouteChange(() => {
    setPaneContent(getInitialState);
  }, []);

  return { paneContent, setPaneContent };
}
