export function getLocalisedLanguageLabel(lang: { languageIsoCode: string }): string | undefined {
  try {
    return (
      new Intl.DisplayNames([lang.languageIsoCode, "en"], { type: "language", style: "short" }).of(
        lang.languageIsoCode,
      )
    );
  } catch {
    return undefined;
  }
}

export function translateLanguage({
  uiLanguageIsoCode,
  languageIsoCodeToBeTranslated,
}: {
  uiLanguageIsoCode: string;
  languageIsoCodeToBeTranslated: string;
}) {
  try {
    return (
      new Intl.DisplayNames([uiLanguageIsoCode.toLowerCase(), "en"], { type: "language", style: "short" }).of(
        languageIsoCodeToBeTranslated,
      ) ?? languageIsoCodeToBeTranslated
    );
  } catch {
    return languageIsoCodeToBeTranslated;
  }
}
