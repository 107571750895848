import { css } from "@emotion/react";
import cn from "classnames";
import type React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { FONTS, fontSize } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { T } from "../T";
import type { OthersSearchRequestQuery, TranslatedValue } from "@swo/storefront";
import { CSS_MQ } from "../../styles/breakpoints";

export const OthersSearchTags: React.FC<{
  t: SearchT;
  activeTag: OthersSearchRequestQuery["type"];
  setActiveTag: (tag: OthersSearchRequestQuery["type"]) => void;
}> = ({ t, activeTag, setActiveTag }) => {
  const labels = [
    { label: t("search.results.others.tags.all"), key: null },
    { label: t("search.results.others.tags.stories"), key: "story" },
    { label: t("search.results.others.tags.events"), key: "event" },
    { label: t("search.results.others.tags.pages"), key: "page" },
    { label: t("search.results.others.tags.files"), key: "asset" },
  ] satisfies Array<{ label: TranslatedValue; key: OthersSearchRequestQuery["type"] }>;

  return (
    <div
      className="u-flex u-flex-wrap"
      css={[
        css`
          gap: 8px;
        `,
      ]}
    >
      {labels.map(label => (
        <button
          onClick={() => setActiveTag(label.key)}
          className={cn(activeTag === label.key ? "active" : null)}
          key={label.key}
          css={[
            fontSize.x2s,
            FONTS.SwarovskiFutura300,
            css`
              border: 2px solid ${CSS_COLORS.Grey};

              &.active,
              &:hover {
                border-color: ${CSS_COLORS.Green};
              }

              border-radius: 4px;

              padding: 2px 4px;
              ${CSS_MQ.atLeastMd} {
                padding: 4px 8px;
              }

              text-transform: uppercase;

              line-height: 1;
            `,
          ]}
        >
          <T>{label.label}</T>
        </button>
      ))}
    </div>
  );
};
