import React, { useEffect, useRef } from "react";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { PaneCloseNavigationButton } from "./RightPaneCloseNavigation";
import { PaneCloseNavigationLayer } from "./RightPaneCloseLayer";
import cn from "classnames";
import { CSS_MQ, CSS_MQ_ONLY } from "../../../styles/breakpoints";

type PaneConfig = {
  width: "standard" | "wide" | "widest";
  theme: "white" | "light" | "dark";
};

type HeaderNavigationRightPaneProps = React.PropsWithChildren<{
  pane: PaneConfig | null;
  onClose: () => void;
  side: "left" | "right";
  outlet?: React.ReactNode;
  viewport: "only-md" | "all";
}>;

export const HeaderNavigationPane: React.FC<HeaderNavigationRightPaneProps> = ({
  pane,
  onClose,
  children,
  outlet,
  side,
  viewport,
}) => {
  const widthClassName = pane ? getWidthClassName(pane.width) : undefined;
  const className = pane ? cn("active", getBackgroundClassName(pane.theme)) : undefined;

  return (
    <div
      className={cn(
        "js-header-navigation-pane",
        pane ? "active" : "",
        widthClassName,
        side === "left" ? "pane-left" : "pane-right",
        `viewport-${viewport}`,
      )}
      css={wrapperStyles}
    >
      <PaneCloseNavigationLayer isActive={!!pane} onClose={onClose} side={side} />

      {/* <FocusTrap active={side === "left" && !!pane} key={JSON.stringify(pane)}> */}
      <div className={cn(className, "js-header-navigation-pane-outlet")} css={paneContentStyles}>
        {outlet}
        <PaneCloseNavigationButton onClose={onClose} side={side} />

        <div
          className="js-pane-children u-absolute"
          css={css`
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;

            overflow-y: auto;
            position: sticky;
          `}
        >
          {children}
        </div>
      </div>
      {/* </FocusTrap> */}
    </div>
  );

  function getWidthClassName(width: PaneConfig["width"]) {
    switch (width) {
      case "standard": {
        return "width-standard";
      }
      case "wide": {
        return "width-wide";
      }
      case "widest": {
        return "width-full";
      }
      default: {
        const _: never = width;
        return _;
      }
    }
  }
};

const wrapperStyles = css`
  &.viewport-only-md {
    display: none;
    ${CSS_MQ.atLeastMd} {
      display: flex;
    }
  }

  &.viewport-all {
    display: flex;
  }

  --close-pane-width: 18px;

  position: fixed;
  width: 100%;

  // Duplicate to serve as a fallback
  height: 100vh;
  height: 100dvh;

  top: 0;
  left: 0;

  overflow-x: hidden;

  transition: backdrop-filter 0.2s;
  &.active {
    backdrop-filter: blur(10px) brightness(0.5);
  }

  &:not(.active) {
    pointer-events: none;
  }

  &.pane-left {
    justify-content: flex-start;
  }

  &.pane-right {
    justify-content: flex-end;
  }

  &.width-standard {
    &.viewport-all {
      ${CSS_MQ.atMostSm} {
        --pane-width: 100%;
      }
    }

    --pane-width: 420px;

    ${CSS_MQ_ONLY.md_and_lg} {
      --pane-width: 520px;
    }
  }

  &.width-wide {
    --pane-width: 512px;

    ${CSS_MQ_ONLY.md_and_lg} {
      --pane-width: 600px;
    }
  }

  &.width-full {
    --pane-width: calc(100vw * 2 / 3);
    ${CSS_MQ_ONLY.md_and_lg} {
      --pane-width: calc(100vw - 100px);
    }

    ${CSS_MQ.atLeastXl} {
      --pane-width: min(900px, calc(100vw * 2 / 3));
    }

    ${CSS_MQ.atLeastXl} {
      --pane-width: min(900px, calc(100vw * 2 / 3));
    }
  }
`;

const paneContentStyles = css`
  &.background-dark {
    --bg-color: ${CSS_COLORS.Green};
    --text-color: ${CSS_COLORS.White};
    --text-background: ${CSS_COLORS.Unknown};
  }

  &.background-light {
    --bg-color: ${CSS_COLORS.CoolMint};
    --text-color: ${CSS_COLORS.Black};
    --text-background: ${CSS_COLORS.Unknown2};
  }

  &.background-white {
    --bg-color: ${CSS_COLORS.White};
    --text-color: ${CSS_COLORS.Black};
    --text-background: ${CSS_COLORS.Unknown2};
  }

  background-color: var(--bg-color);
  color: var(--text-color);

  .pane-right & {
    transform: translateX(100%);
    transform-origin: top right;

    padding: 120px 40px 20px 40px;
  }

  .pane-left & {
    transform: translateX(-100%);
    transform-origin: top left;

    padding: 0;
  }

  width: var(--pane-width);

  position: relative;
  z-index: 2;

  transition:
    0.15s transform ease-out,
    0.15s width ease-out;

  &.active {
    transform: translateX(0%);
  }
`;

const getBackgroundClassName = (theme: PaneConfig["theme"]) => {
  switch (theme) {
    case "white": {
      return "background-white";
    }
    case "light": {
      return "background-light";
    }
    case "dark": {
      return "background-dark";
    }
    default: {
      const _: never = theme;
      return _;
    }
  }
};
