export function mergeXes(sizeStr: string): string {
  // Replace X1...Xn[S|L] into nX[S|L]

  if (sizeStr.startsWith("X")) {
    let nX = 0;
    for (let char of sizeStr) {
      if (char === "X") {
        nX++;
      } else {
        break;
      }
    }

    if (nX > 1) {
      const remainder = sizeStr.slice(nX);
      return `${nX}X${remainder}`;
    } else {
      return sizeStr;
    }
  } else {
    return sizeStr;
  }
}
