import type { Link } from "@swo/shared";
import type React from "react";
import { SwarovskiLink } from "../_shared-components/ui-elements/SwarovskiLink";
import { css } from "@emotion/react";
import { SearchHeadline } from "./SearchHeadline";
import { soListStyles } from "../../styles/rich-text";
import { FONTS, fontSize } from "../../styles/fonts";
import type { TranslatedValue } from "@swo/storefront";
import { T } from "../T";

export const QuickLinks: React.FC<{ quickLinks: Array<Link>; headline: TranslatedValue }> = ({
  quickLinks,
  headline,
}) => {
  if (quickLinks.length === 0) {
    return null;
  }

  return (
    <div>
      <SearchHeadline className="u-mb-2" isLoading={false}>
        <T>{headline}</T>
      </SearchHeadline>

      <ul
        css={[
          soListStyles,
          css`
            li + li {
              margin-top: 8px;
            }
          `,
        ]}
      >
        {quickLinks.map(link => (
          <li
            key={link.link}
            css={[
              fontSize.x2,
              FONTS.SwarovskiFutura300,
              css`
                &:hover {
                  text-decoration: underline;
                }
              `,
            ]}
          >
            <SwarovskiLink link={link.link} styled={"no-decoration"} isInternal={true} className="u-color-black">
              {link.label}
            </SwarovskiLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
