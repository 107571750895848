import React from "react";
import { MyAccountDropdownProps, linkStyles } from "./MyAccountDropdown";
import { T } from "../../../T";

export const UnloggedUserButtons: React.FC<MyAccountDropdownProps> = ({ t, userLinks }) => (
  <>
    <a href={userLinks.login} css={linkStyles} rel="noopener noreferrer">
      <T>{t("meta.login")}</T>
    </a>
    <a href={userLinks.register} target="_blank" css={linkStyles} rel="noopener noreferrer">
      <T>{t("meta.register")}</T>
    </a>
  </>
);
