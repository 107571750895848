import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { ReactTFC } from "../../../utils/fp/missing-ts-parts";

/*
  @hidden prop should >>rather<< be used to completely disable the modal root
  and NOT to toggle its visibility (YMMV)
 */
export const ModalRootPortalEntrance: ReactTFC<{ children: React.ReactNode; hidden?: boolean }> = props => {
  const [shouldBeMounted, setShouldBeMounted] = useState(false);

  useEffect(() => {
    if (!props.hidden) {
      setShouldBeMounted(true);
    }
  }, []);

  useEffect(() => {
    setShouldBeMounted(!props.hidden);
  }, [props.hidden]);

  if (!shouldBeMounted) {
    return null;
  }

  const modalRoot = getDocumentModalRoot();

  return modalRoot ? ReactDOM.createPortal(props.children, modalRoot) : null;
};

function getDocumentModalRoot(): Element | null {
  return typeof document !== "undefined" ? document.querySelector("#modal-root") : null;
}
