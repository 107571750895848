import { LoadingStateWithIdle } from "../../domain/data-layer/loading-state-with-idle";
import { useEffect, useState } from "react";

export function useRacedLoadingStates<T>(
  one: LoadingStateWithIdle<T>,
  another: LoadingStateWithIdle<T>,
  initial: LoadingStateWithIdle<T>,
) {
  const [state, setState] = useState<LoadingStateWithIdle<T>>(initial);

  function setIfNotLoaded(value: LoadingStateWithIdle<T>) {
    if (value.__tag === "loaded" || value.__tag === "error") {
      setState(currentState => {
        if (currentState.__tag !== "loaded") {
          return value;
        } else {
          return currentState;
        }
      });
    }
  }

  useEffect(() => {
    setIfNotLoaded(one);
  }, [one]);

  useEffect(() => {
    setIfNotLoaded(another);
  }, [another]);

  return state;
}
