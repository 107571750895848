import { css } from "@emotion/react";
import React from "react";
import { interpolateLinearly } from "../../domain/translations/simple-translations-module";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { FONTS, fontSize } from "../../styles/fonts";
import { mapTranslatedValue, T } from "../T";

export const NoSearchResultsInSection: React.FC<{ t: SearchT; query: string }> = ({ t, query }) => {
  const obj = mapTranslatedValue(t("search.results.noResults.paragraph"), _ => interpolateLinearly(_, [query]));

  return (
    <div className="u-flex u-flex-col u-ai-center u-mt-4">
      <span
        css={[
          FONTS.SwarovskiFutura300,
          fontSize.x5x,
          css`
            word-break: break-word;
          `,
        ]}
        className="u-color-green u-uppercase"
      >
        <T>{t("search.results.noResults.headline")}</T>
      </span>

      <p
        css={[
          FONTS.SwarovskiFutura300,
          fontSize.x2s,
          css`
            max-width: 30em;
            text-align: center;
            text-wrap: balance;
          `,
        ]}
      >
        <T>{obj}</T>
      </p>
    </div>
  );
};
