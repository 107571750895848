import React from "react";
import { Segments } from "../../../assets";
import { SwarovskiOptikSegmentKey } from "@swo/shared";
import { SerializedStyles } from "@emotion/react";

export const SegmentIconSvg2: React.FC<{ segment: SwarovskiOptikSegmentKey; attachStyles?: SerializedStyles }> = ({
  segment,
  attachStyles,
}) => {
  switch (segment) {
    case "birding": {
      return <Segments.BirdingIcon css={attachStyles} />;
    }
    case "hunting": {
      return <Segments.HuntingIcon css={attachStyles} />;
    }
    case "outdoor": {
      return <Segments.OutdoorIcon css={attachStyles} />;
    }
    default: {
      const _: never = segment;

      return _;
    }
  }
};
