import React from "react";
import { css } from "@emotion/react";
import { CartError } from "../../../../../domain/cart/hooks/useCart";
import cn from "classnames";
import { Cart } from "../../../../../assets";
import { styles } from "./ShoppingCartContent";

export const CartIconWithDot: React.FC<{ cartError: CartError | undefined; hasAnythingInCart: boolean }> = props => {
  return (
    <>
      <Cart />
      {props.hasAnythingInCart && <span css={styles} className={cn("cart-dot", { error: !!props.cartError })} />}
    </>
  );
};
