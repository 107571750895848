import React from "react";
import { ShoppingCartDropdownCartElement } from "./ShoppingCartDropdownCartElement";

import { css } from "@emotion/react";
import { CSS_MQ } from "../../../../../styles/breakpoints";
import { ExtractedLineItemData, SwarovskiOptikClientCartLineItem } from "@swo/storefront";

type CartScrollableLineItemsListProps = {
  lineItems: Array<SwarovskiOptikClientCartLineItem & ExtractedLineItemData>;
  languageIsoCode: string;
  onLineItemRemovalRequested: (lineItemId: string) => Promise<void>;
};

export const CartScrollableLineItemsList: React.FC<CartScrollableLineItemsListProps> = props => {
  return (
    <div css={cartLineItemsContainerStyles} className="u-hide-scrollbar-x">
      {props.lineItems.map(lineItem => {
        const previousPrice = lineItem.discount?.totalLineItemPricePreDiscount;

        return (
          <ShoppingCartDropdownCartElement
            key={lineItem.id}
            name={lineItem.lineItemName}
            lineItemProductId={lineItem.productId}
            lineItemVariantId={lineItem.variant.id}
            lineItemInfo={lineItem.extendedInfo}
            attributes={lineItem.variant.uniqueAttributes.map(a => a.value.label)}
            price={{
              current: lineItem.priceLabel,
              previous: previousPrice,
            }}
            onLineItemRemovalRequested={
              lineItem.cannotBeRemoved ? undefined : () => props.onLineItemRemovalRequested(lineItem.id)
            }
            quantity={lineItem.quantity}
          />
        );
      })}
    </div>
  );
};

const cartLineItemsContainerStyles = css`
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;
