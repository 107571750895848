import React from "react";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import { css } from "@emotion/react";
import { HeaderNavigationPaneTitle } from "./RightPaneTitle";
import { HeaderNavigationDataProps } from "./HeaderNavigationData";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { SecondaryNavigation } from "./SecondaryNavigation";
import { PrimaryNavigation } from "./PrimaryNavigation";
import { MenuAimOnMouse } from "./useMenuAim";

export type Props = Omit<HeaderNavigationDataProps, "onClose"> & {
  state: HeaderPrimaryNavigationElemT;
  selectItem: (item: HeaderPrimaryNavigationElemT) => void;
  onMouse: MenuAimOnMouse;
};

export const HeaderNavigationLeftPaneFirstColumn: React.FC<Props> = ({
  data,
  paneTitle,
  state,
  selectItem,
  onMouse,
}) => {
  return (
    <div className="u-relative u-flex u-flex-col u-jc-between so-scrollbar-y" css={wrapperStyles}>
      <HeaderNavigationPaneTitle align="right" label={paneTitle} />

      <PrimaryNavigation data={data.primary} state={state} selectItem={selectItem} onMouse={onMouse} />

      <SecondaryNavigation data={data.secondary} />
    </div>
  );
};

const wrapperStyles = css`
  overflow-y: auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: calc(-1 * var(--gap));
    width: 12px;
    height: 100%;

    box-shadow: inset -8px 0px 8px -2px #0000000f;
  }
`;
