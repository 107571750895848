import React from "react";
import { HeaderNavigationDataT, type TranslatedValue } from "@swo/storefront";
import { MobilePrimaryAndSecondaryPaneContainer } from "./MobilePrimaryAndSecondaryPaneContainer";
import { SecondaryLinks } from "./SecondaryLinks";
import { PrimaryLinks } from "./PrimaryLinks";
import { paddingStyles } from "./InternalPane";
import { MobilePaneContentRawShell } from "./MobilePaneContentRawShell";
import { Theme } from "./PaneContentState";

export const MobilePaneContentShell: React.FC<
  React.PropsWithChildren<{
    data: HeaderNavigationDataT;
    setSeconadary: (label: TranslatedValue | string) => void;
    theme: Theme;
    isActive: boolean;
    className: string;
  }>
> = ({ data, setSeconadary, children, theme, isActive, className }) => {
  return (
    <MobilePaneContentRawShell theme={theme} isActive={isActive} className={className}>
      {children}

      <MobilePrimaryAndSecondaryPaneContainer
        attachStyles={paddingStyles}
        primary={<PrimaryLinks data={data.primary} setSeconadary={setSeconadary} />}
        secondary={<SecondaryLinks data={data.secondary} />}
      />
    </MobilePaneContentRawShell>
  );
};
