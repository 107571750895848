import { ReactTFC } from "../../../../utils/fp/missing-ts-parts";
import React from "react";

import { css } from "@emotion/react";

import { CSS_COLORS } from "../../../../styles/styles-css-variables";
import { useStore } from "../../../../store/hooks/use-store";
import { fontColor, FONTS, fontSize } from "../../../../styles/fonts";
import { HeaderT } from "../../HeaderT";
import { UnloggedUserButtons } from "./UnloggedUserButtons";
import { UserAspectLoggedInState } from "../../../../store/aspects/user-aspect/aspect-types";
import { LoggedInUser } from "./LoggedInUser";
import { paneHeadlineStyle } from "../cart-pane-headline-styles";
import { T } from "../../../T";

export type MyAccountDropdownProps = {
  userLinks: {
    login: string;
    register: string;
    profile: string;
  };
  t: HeaderT;
};

export const MyAccountDropdown: ReactTFC<MyAccountDropdownProps> = ({ t, userLinks }) => {
  const {
    state: { auth: stateAuth },
  } = useStore();

  return (
    <>
      <span css={paneHeadlineStyle}>
        <T>{t("meta.headline")}</T>
      </span>

      <div
        css={css`
          display: grid;
          grid-template-rows: auto;
          grid-row-gap: 16px;
        `}
      >
        {stateAuth && stateAuth.user === "logged-in-user" ? (
          <LoggedInUser state={stateAuth} t={t} userLinks={userLinks} />
        ) : (
          <UnloggedUserButtons t={t} userLinks={userLinks} />
        )}
      </div>
    </>
  );
};

export const textAlignCenter = css`
  text-align: center;
  display: block;
  width: 100%;
`;

const headlineStyles = [
  FONTS.MinervaModern500,
  fontSize.x4,
  css`
    margin: 0;
    text-align: center;

    color: ${CSS_COLORS.GreenDark};
    text-transform: uppercase;
  `,
];

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const hrStyles = css`
  margin: 16px 0;
  width: 100%;

  height: 1px;

  border: none;
  background-color: ${CSS_COLORS.Grey2};
`;

export const linkStyles = [
  FONTS.SwarovskiFutura300,
  fontSize.x2s,
  fontColor.black,
  css`
    text-align: center;
    text-decoration: none;

    &:hover {
      color: ${CSS_COLORS.Green};
    }
  `,
];

export const textStyle = [
  FONTS.SwarovskiFutura300,
  fontColor.black,
  fontSize.x2s,
  css`
    text-align: center;
  `,
];
