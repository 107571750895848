import React, { useEffect, useState } from "react";
import { ReactTFC } from "../utils/fp/missing-ts-parts";
import { ModalRootPortalEntrance } from "../components/_shared-components/technical/ModalRootPortalEntrance";

import { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";

import { Spinner } from "../components/_shared-components/ui-elements/Spinner";
import { useMountedRef } from "../hooks/use-mounted";
import cn from "classnames";

export const ModalLoadingSpinner: ReactTFC = () => {
  useEffect(() => {
    const jsIsPageLoading = "js-is-loading";
    document.body.classList.add(jsIsPageLoading);

    return () => {
      document.body.classList.remove(jsIsPageLoading);
    };
  }, []);

  return (
    <ModalRootPortalEntrance>
      <StyledLoadingSpinner />
    </ModalRootPortalEntrance>
  );
};

export const StyledLoadingSpinner: ReactTFC = () => {
  const style = css`
    position: fixed;
    top: 0;
    right: 2px;
    width: 36px;
    height: 36px;

    padding: 6px;

    z-index: 300;
  `;

  return (
    <div css={style}>
      <Spinner />
    </div>
  );
};

export const SmallLoadingSpinner: ReactTFC<{
  className?: string;
  color?: "white" | "green";
  size?: "1x" | "2x" | "3x";
  noMargin?: boolean;
}> = props => {
  const { className, ...restProps } = props;

  return <SmallUnstyledLoadingSpinner className={className} css={style} {...restProps} />;
};

export const SmallUnstyledLoadingSpinner: ReactTFC<{
  className?: string;
  css?: SerializedStyles;
  color?: "white" | "green";
  size?: "1x" | "2x" | "3x";
  noMargin?: boolean;
}> = props => {
  const { className, color, size, noMargin, ...rest } = props;

  return (
    <div
      className={cn(size ? `size-${size}` : undefined, noMargin ? "no-margin" : undefined, className)}
      css={style}
      {...rest}
    >
      <Spinner color={color || "white"} />
    </div>
  );
};

const style = css`
  width: 20px;
  height: 20px;

  &.size-2x {
    width: 36px;
    height: 36px;
  }

  &.size-3x {
    width: 48px;
    height: 48px;
  }

  margin-left: 12px;
  margin-top: -6px;
  &.no-margin {
    margin: 0;
  }

  z-index: 300;

  padding: 0;
`;

export const DelayRendering: React.FC<
  React.PropsWithChildren<{ delayMs: number; fallback?: React.ReactNode }>
> = props => {
  const [isVisible, setIsVisible] = useState(false);
  const isMounted = useMountedRef();

  useEffect(() => {
    const setVisibleH = () => {
      if (isMounted.current) {
        setIsVisible(true);
      }
    };

    const cancelId = window.setTimeout(setVisibleH, props.delayMs);

    return () => {
      window.clearTimeout(cancelId);
    };
  }, [props.delayMs]);

  return isVisible ? <>{props.children}</> : (props.fallback ?? null);
};
