import React from "react";
import { css } from "@emotion/react";

export const PaneNavigationLabel: React.FC<React.PropsWithChildren<{ onClick: () => void }>> = ({
  onClick,
  children,
}) => {
  return (
    <div
      css={css`
        writing-mode: vertical-rl;
        text-orientation: mixed;

        position: absolute;

        left: 12px;
        top: 20px;
      `}
    >
      <p
        onClick={onClick}
        tabIndex={1}
        className="u-uppercase so-color-green u-m-0"
        css={css`
          transform: rotate(180deg);
          cursor: pointer;
        `}
      >
        {children}
      </p>
    </div>
  );
};
