import { useLocation, useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";

type Cb = (location: ReturnType<typeof useLocation>) => void;

export function useOnRouteChange(cb: Cb, deps: Array<unknown>) {
  const location = useLocation();

  useEffect(() => {
    cb(location);
  }, [...deps, location.pathname]);
}

export function useOnRouteChangeOnce(cb: Cb) {
  const location = useLocation();
  const runRef = useRef(location.pathname);

  useEffect(() => {
    if (runRef.current !== location.pathname) {
      runRef.current = location.pathname;

      cb(location);
    }
  }, [location.pathname]);
}
