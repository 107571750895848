import type React from "react";
import { interpolateLinearly } from "../../domain/translations/simple-translations-module";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { fontSize, FONTS } from "../../styles/fonts";
import { T } from "../T";

export const SearchNoResultsAtAll: React.FC<{ t: SearchT; query: string }> = ({ t, query }) => {
  return (
    <div className="u-flex u-jc-center u-mt-4" css={[fontSize.x3, FONTS.SwarovskiFutura300]}>
      <T forcedValue={t("noResults")}>{interpolateLinearly(t("noResults").value, [query])}</T>
    </div>
  );
};

export const SearchResultsError: React.FC<{ t: SearchT }> = ({ t }) => {
  return (
    <div className="u-flex u-jc-center u-mt-4" css={[fontSize.x3, FONTS.SwarovskiFutura300]}>
      <T>{t("search.results.error.paragraph")}</T>
    </div>
  );
};
