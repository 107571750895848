import type { LoadingState } from "../../domain/data-layer/loading-state";
import { useFetchedWithPendingReference } from "./use-fetched-with-pending-reference";

export function useAbortableFetched<T>(
  eff: (abortSignal: AbortSignal) => Promise<T>,
  opts: { dataKey: string },
): LoadingState<T> {
  const [state, _] = useFetchedWithPendingReference(eff, opts);

  return state.data;
}
