import React from "react";
import { SerializedStyles, css } from "@emotion/react";
import { FONTS } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";
import cn from "classnames";

const textStyles = [
  FONTS.SwarovskiFutura700,
  css`
    font-size: 80px;

    @media (min-height: 900px) {
      font-size: 90px;
    }
    @media (min-height: 1200px) {
      font-size: 120px;
    }

    color: var(--text-background);
    writing-mode: vertical-rl;

    &.sticking-to-right {
      transform: scale(-1) translateX(-0.125em);
    }

    &.sticking-to-left {
      transform: scale(-1) translateX(0.25em);
    }

    line-height: 0.8;
  `,
];

export const HeaderNavigationPaneTitleLabel: React.FC<
  React.PropsWithChildren<{ attachStyles?: SerializedStyles; stickingTo: "right" | "left" }>
> = ({ children, attachStyles, stickingTo }) => {
  return (
    <span className={cn("u-uppercase", `sticking-to-${stickingTo}`)} css={[textStyles, attachStyles]}>
      {children}
    </span>
  );
};

export const HeaderNavigationPaneTitleLabelWithWrapper: React.FC<
  React.PropsWithChildren<{ attachStyles?: SerializedStyles }>
> = ({ children, attachStyles }) => {
  return (
    <div
      className="u-absolute u-right"
      css={css`
        --text-background: #0000000d;

        bottom: 20px;
        z-index: 1;
      `}
    >
      <HeaderNavigationPaneTitleLabel stickingTo="right" attachStyles={attachStyles}>
        {children}
      </HeaderNavigationPaneTitleLabel>
    </div>
  );
};
