import React from "react";

import { css } from "@emotion/react";

import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";

const fullYear = new Date().getFullYear();

export const FooterEnd: React.FC = props => {
  return (
    <div
      css={css`
        background-color: ${CSS_COLORS.Black};
        color: ${CSS_COLORS.White};

        font-size: 14px;

        display: flex;
        justify-content: center;

        padding: 15px 0 15px 0;
        ${CSS_MQ.atLeastMd} {
          padding: 15px 0 15px 0;
        }
      `}
    >
      <div
        css={css`
          max-width: var(--max-content-width-new);
          width: 100%;
        `}
      >
        <span
          className={"u-fw-300"}
          css={css`
            text-transform: uppercase;
          `}
        >
          © {fullYear} Swarovski Optik
        </span>
      </div>
    </div>
  );
};
