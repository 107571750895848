import React from "react";
import { HeaderT } from "../HeaderT";
import { paneHeadlineStyle } from "./cart-pane-headline-styles";
import { T } from "../../T";

export const CartPane: React.FC<React.PropsWithChildren<{ t: HeaderT }>> = ({ t, children }) => {
  return (
    <div>
      <span css={paneHeadlineStyle}>
        <T>{t("cart.title")}</T>
      </span>

      {children}
    </div>
  );
};
