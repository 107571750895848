import React from "react";
import { css } from "@emotion/react";
import { unlabeledInternalLinkOf } from "../../../domain/swarovski-optik/links";
import { PageListWithOverview } from "./PageListWithOverview";
import { HeaderStoriesNavT } from "@swo/storefront";
import { CSS_MQ } from "../../../styles/breakpoints";

type Props = {
  stories: HeaderStoriesNavT;
};

export const StoriesUniversalElement = React.forwardRef<HTMLDivElement, Props>(
  ({ stories: { allStories, label, stories, image } }, ref) => {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          ${CSS_MQ.atLeastMd} {
            margin-top: var(--margin-top);
          }
        `}
      >
        <PageListWithOverview
          ref={ref}
          list={stories.map(s => ({
            link: s.link,
            primaryHeadline: s.headline,
            secondaryHeadline: s.subheadline,
            additionalText: s.readingTime,
          }))}
          overview={{
            linkData: unlabeledInternalLinkOf({ pathname: allStories.link }).data,
            label: allStories.label.value,
          }}
          image={image}
        />
      </div>
    );
  },
);
