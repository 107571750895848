import React from "react";
import { CustomerServiceDropdownElement } from "./CustomerServiceDropdown";
import { Hr } from "./Hr";
import { showChatbotMessengerEffect } from "../../../../domain/intercom/show-chatbot-messenger-effect";
import { ContactDataType } from "@swo/shared";
import { HeaderT } from "../../HeaderT";
import { HeaderLinks } from "../../ContextLessHeaderComponent";
import { CustomerServiceElement } from "./CustomerServiceElement";
import { CustomerSupportSvgIcon, DealerLocatorSvgIcon } from "../../../../assets";

export const DealerLocatorPaneContent: React.FC<{
  t: HeaderT;
  links: HeaderLinks;
  customerServiceElement: CustomerServiceDropdownElement;
}> = ({ links, t, customerServiceElement }) => {
  const second: CustomerServiceDropdownElement = {
    headline: t("dealerLocator.headline"),
    line: t("dealerLocator.text"),
    firstBtn: {
      as: "a" as const,
      label: t("dealerLocator.linkLabel"),
      link: links.dealerLocatorPage,
      isInternal: true,
      upperCased: true,
    },
    secondLine: null,
  };

  return (
    <>
      <CustomerServiceElement Icon={CustomerSupportSvgIcon} {...customerServiceElement} />
      <Hr />
      <CustomerServiceElement Icon={DealerLocatorSvgIcon} {...second} />
    </>
  );
};
