import { pipe } from "fp-ts/function";
import * as O from "fp-ts/Option";
import { createJpgLink } from "../../components/_shared-components/image-related/createJpgAndWebpLinks";

export function filterContentfulAssetLinkOnly(link: string): URL | null {
  try {
    let t = link;
    if (t.startsWith("//")) {
      t = "https:" + t;
    }

    const url = new URL(t);

    if (url.host === "images.ctfassets.net") {
      // url.search = "";
      // return url.toString();
      return url;
    }

    return null;
  } catch {
    return null;
  }
}

function removeExistingParams(url: URL): URL {
  let ret = new URL(url);
  ret.search = "";

  return ret;
}

export function getIfContentfulImageAssetLink(link: string) {
  /*
    It may happen that Contentful is used to store images
    that are not referenced from Contentful (using Contentful links/references)
    but instead by copying assets' public URL.

    In such cases, it might be beneficial we try to learn if that's the case
    and use Contentful's Images API to transform an image.
   */
  return pipe(
    link,
    filterContentfulAssetLinkOnly,
    O.fromNullable,
    O.map(ctfUrl => removeExistingParams(ctfUrl)),
    O.map(url => url.toString()),
  );
}

export function transformImageIfFromContentful_jpg(link: string, requestedWidth: number) {
  return pipe(
    link,
    getIfContentfulImageAssetLink,
    O.map(url => createJpgLink(url, requestedWidth)),
    O.getOrElseW(() => link),
  );
}
