import { css } from "@emotion/react";
import { ContactDataType } from "@swo/shared";
import { HeaderServiceNavT } from "@swo/storefront";
import React, { CSSProperties } from "react";
import { RightArrowAlt } from "../../../assets";
import { showChatbotMessengerEffect } from "../../../domain/intercom/show-chatbot-messenger-effect";
import { internalLinkOf } from "../../../domain/swarovski-optik/links";
import { t2 } from "../../../domain/translations/translations";
import { usePageContext } from "../../../global/page-context";
import { CSS_MQ } from "../../../styles/breakpoints";
import { fontSize } from "../../../styles/fonts";
import { ImageWithFocalPoint } from "../../_shared-components/image-related/ImageWithFocalPoint";
import { SwarovskiButton } from "../../_shared-components/ui-elements/SwarovskiButton";
import { Links } from "./Links";
import { Wrapper } from "./Wrapper";
import { paddingXStyles } from "./paddingXStyles";
import { T } from "../../T";
import { ContentfulImage2 } from "../../_shared-components/image-related/ContentfulImage2";

type Props = {
  service: HeaderServiceNavT;
  contactData: ContactDataType;
  requestCollapsingNavigation: () => void;
};

export const ServiceUniversalElement = React.forwardRef<HTMLDivElement, Props>(
  ({ service: { links, translations, image }, contactData, requestCollapsingNavigation }, ref) => {
    const t = t2(translations);
    const { links: ctxLinks } = usePageContext();

    const buttonCta = contactData.showChatbotMessenger
      ? {
          as: "button" as const,
          children: contactData.showChatbotMessenger,
          onClick: () => {
            showChatbotMessengerEffect();
            requestCollapsingNavigation();
          },
        }
      : { as: "a" as const, children: contactData.email, link: `mailto:${contactData.email}`, isInternal: false };

    return (
      <Wrapper noPadding>
        <div
          className="u-flex u-flex-col u-grow"
          css={css`
            --py: 20px;
            ${CSS_MQ.atLeastMd} {
              --py: 30px;
            }
          `}
        >
          <div
            ref={ref}
            className="u-grow"
            css={css`
              ${CSS_MQ.atLeastMd} {
                margin-bottom: 20px;
              }
            `}
          >
            <div
              css={[
                css`
                  padding-left: var(--py);
                  padding-right: var(--py);

                  ${CSS_MQ.atLeastMd} {
                    ${paddingXStyles};
                  }

                  padding-bottom: 20px;
                `,
              ]}
            >
              <Links
                links={links.map(c => ({
                  link: internalLinkOf({ label: c.label, pathname: c.link }),
                  isNew: false,
                  children: null,
                }))}
              />
            </div>
          </div>

          <div>
            <div
              className="so-bg-cool-mint u-flex "
              css={[
                paddingXStyles,
                css`
                  padding-top: var(--py);
                  padding-bottom: var(--py);
                  padding-left: var(--py);
                  padding-right: var(--py);

                  justify-content: space-between;
                  gap: 8px;
                `,
              ]}
            >
              <div className="u-flex u-flex-col u-jc-between">
                <div className="u-flex u-items-center">
                  <span
                    className="u-uppercase"
                    css={css`
                      margin-bottom: 12px;
                    `}
                  >
                    <T>{t("customerService")}</T>
                  </span>
                </div>

                <div
                  className="u-flex u-flex-col u-jc-center"
                  css={css`
                    grid-area: 2 / 1;
                  `}
                >
                  <span className="u-mb-3">
                    <SwarovskiButton {...buttonCta} variant="outline" size="small" />
                  </span>

                  {/* <span>{t("otherContactOptions")}</span> */}
                </div>
              </div>

              <div className="u-flex u-justify-end u-align-center">
                <img
                  src={contactData.media.url}
                  css={css`
                    --w: 60px;
                    ${CSS_MQ.atLeastMd} {
                      --w: 100px;
                    }

                    width: var(--w);
                    height: var(--w);

                    max-width: 100%;
                    max-height: 100%;
                  `}
                />
              </div>
            </div>

            {image && (
              <div
                className="u-grid so-bg-green so-color-white"
                css={css`
                  padding-right: 20px;
                  gap: 12px;

                  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
                  ${CSS_MQ.atLeastXl} {
                    grid-template-columns: minmax(0, 5fr) minmax(0, 6fr);
                  }
                `}
                style={
                  {
                    "--ar": image.image.width / image.image.height,
                  } as CSSProperties
                }
              >
                <ContentfulImage2
                  contentfulUrl={image.image.url}
                  cropping={image}
                  sizes={"200px"}
                  withTransparency={true}
                  assetId={image.image.sys.id}
                  css={css`
                    display: flex;
                    width: 100%;
                    height: 100%;

                    aspect-ratio: var(--ar);
                  `}
                />

                <div
                  css={css`
                    color: white;

                    padding-top: var(--py);

                    ${CSS_MQ.atLeastMd} {
                      padding-top: 30px;
                      padding-bottom: 12px;
                    }

                    word-break: break-word;
                  `}
                >
                  <a
                    href={ctxLinks.myServiceProfile}
                    target="_blank"
                    className="u-uppercase"
                    css={[
                      fontSize.x25,
                      css`
                        color: inherit;
                        text-decoration: none;

                        display: flex;
                        align-items: center;

                        &:hover {
                          text-decoration: underline;
                        }
                      `,
                    ]}
                  >
                    <T>{t("goToMyServiceHeadline")}</T> <RightArrowAlt />
                  </a>
                  <p>
                    <T>{t("gotoMyServiceDescription")}</T>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    );
  },
);
