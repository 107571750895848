import React from "react";
import cn from "classnames";
import { MenuMetaComponent } from "./MenuMetaComponent/MenuMetaComponent";
import { css } from "@emotion/react";
import { ContactDataType, SwarovskiOptikShopMode } from "@swo/shared";
import { SwarovskiOptikLocaleFrontend } from "@swo/storefront";
import { HeaderLinks } from "../ContextLessHeaderComponent";
import { CartIconWithDot } from "./MenuMetaComponent/ShoppingCart/CartIconWithDot";
import { navigationButtonStyles } from "../../SegmentFloatingButtonComponent/navigationButtonStyles";
import { HeaderT } from "../HeaderT";
import { LanguagePickerButton } from "../../MainNavigationComponent/LanguagePickerButton";
import { CloseButtonSvg2, BurgerMenuAlt2, Map, Search, User } from "../../../assets";
import { GTMClassHooks } from "../../../domain/google-tag-manager/GoogleTagManagerIntegration";
import { SegmentIconSvg2 } from "../../DealerLocatorComponent/subcomponents/SegmentIconSvg2";
import { Actions } from "./useActions";
import { MainNavigationElement, Segments } from "./props";
import { PickersPaneContentState } from "./PaneContentState";
import { NavigationTitle } from "./NavigationTitle";

type SecondaryNavigationElementsProps = {
  t: HeaderT;
  links: HeaderLinks;
  contactData: ContactDataType;
  chatbotEnabled: boolean;
  cartMode: SwarovskiOptikShopMode["cart"];
  hideCartElement: boolean;
  segments: Segments | null;
  locale: SwarovskiOptikLocaleFrontend;
  hasAnythingInCart: boolean;
  onMobilePaneOpened: () => void;
  hasMobilePaneOpened: boolean;
  actions: Actions["actions"];
  active: PickersPaneContentState["tag"] | undefined;
  mainNavigationElements: Array<MainNavigationElement>;
};

export const RightSideNavigation: React.FC<SecondaryNavigationElementsProps> = ({
  t,
  links,
  contactData,
  chatbotEnabled,
  cartMode,
  hideCartElement,
  locale,
  segments,
  hasAnythingInCart,
  onMobilePaneOpened,
  hasMobilePaneOpened,
  actions,
  active,
  mainNavigationElements,
}) => {
  if (mainNavigationElements.length === 0 || !segments || !segments.current) {
    return null;
  }

  return (
    <MenuMetaComponent
      t={t}
      links={links}
      contactData={contactData}
      chatbotEnabled={chatbotEnabled}
      cartMode={cartMode}
    >
      {!hideCartElement ? (
        <button
          onClick={actions.onCartClicked}
          css={navigationButtonStyles}
          className={cn(active === "cart" ? "active" : undefined, "show-mobile")}
        >
          <span
            css={css`
              position: relative;
              display: block;

              svg {
                margin-left: -4px;
                margin-top: 1px;
              }
            `}
          >
            <CartIconWithDot cartError={undefined} hasAnythingInCart={hasAnythingInCart} />
          </span>

          <NavigationTitle title={t("navigation.cartTitle")} />
        </button>
      ) : null}

      <button
        css={[
          navigationButtonStyles,
          css`
            svg {
              margin-left: 1px;
              margin-top: 1px;
            }
          `,
        ]}
        onClick={e => {
          actions.onSearchClicked();
        }}
        className={cn(active === "search" ? "active" : undefined)}
      >
        <Search />

        <NavigationTitle title={t("navigation.searchTitle")} />
      </button>

      <button
        css={[
          navigationButtonStyles,
          css`
            svg {
              margin-left: 0;
              margin-top: 1px;
            }
          `,
        ]}
        onClick={actions.onDealerLocatorClicked}
        className={cn(active === "dealer-locator" ? "active" : undefined)}
      >
        <Map />

        <NavigationTitle title={t("navigation.dealerLocatorTitle")} />
      </button>

      <button
        css={navigationButtonStyles}
        onClick={actions.onAccountClicked}
        className={cn(active === "account" ? "active" : undefined)}
      >
        <User />
        <NavigationTitle title={t("navigation.accountTitle")} />
      </button>

      <LanguagePickerButton
        onLanguagePickerClicked={actions.onLanguagePickerClicked}
        locale={locale}
        className={cn(active === "language-picker" ? "active" : undefined)}
      >
        <NavigationTitle title={t("navigation.languagePickerTitle")} />
      </LanguagePickerButton>

      <button
        css={navigationButtonStyles}
        className={cn(
          GTMClassHooks.segmentPickerFloatingValue(segments.current.key),
          active === "segment-picker" ? "active" : undefined,
        )}
        onClick={actions.onSegmentButtonClicked}
      >
        <SegmentIconSvg2 segment={segments.current.key} />
        <NavigationTitle title={t("navigation.segmentsTitle")} stickTo={"right"} />
      </button>

      <button css={navigationButtonStyles} className="show-mobile hide-desktop" onClick={onMobilePaneOpened}>
        {hasMobilePaneOpened ? <CloseButtonSvg2 strokeWidth={4} /> : <BurgerMenuAlt2 />}
      </button>
    </MenuMetaComponent>
  );
};
