import React, { useEffect } from "react";

export function useScrollToElementOnIdle<T extends HTMLElement>(
  ref: React.RefObject<T | null>,
  shouldScroll: boolean,
  callbackOnDone: (() => void) | (() => Promise<void>),
) {
  useEffect(() => {
    const timingFunction = (window as any).requestIdleCallback || window.requestAnimationFrame;

    if (shouldScroll) {
      if (ref.current) {
        const current = ref.current;

        timingFunction(() => {
          current.scrollIntoView({ behavior: "smooth" });

          void callbackOnDone();
        });
      }
    }
  }, [shouldScroll]);
}
