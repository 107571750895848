import { FONTS, fontSize } from "../../../styles/fonts";
import { css } from "@emotion/react";

export const paneHeadlineStyle = [
  fontSize.x2,
  FONTS.SwarovskiFutura700,
  css`
    margin: 0;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    text-transform: uppercase;
  `,
];
