import React from "react";
import { css } from "@emotion/react";
import { fontSize } from "../../../styles/fonts";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { SegmentIconSvg2 } from "../../DealerLocatorComponent/subcomponents/SegmentIconSvg2";
import { NavigationSegmentsOverlayProps } from "./NavigationSegmentsOverlay";
import cn from "classnames";
import { T } from "../../T";

export const NavigationSegmentsOverlayElement: React.FC<{
  segment: NavigationSegmentsOverlayProps["segments"][number];
  onSelected: NavigationSegmentsOverlayProps["onSelected"];
  isSelected: boolean;
}> = ({ segment, onSelected, isSelected }) => {
  const styles = css`
    gap: 12px;
  `;

  const iconStyles = css`
    width: 48px;
    svg {
      width: 100%;
      height: auto;
    }
  `;

  return (
    <li key={segment.key} css={fontSize.x3}>
      <SwarovskiLink
        isInternal={true}
        scrollToTop={true}
        link={segment.link}
        onClick={() => onSelected(segment.key)}
        attachStyles={styles}
        className="u-flex u-items-center u-color-white"
        styled="no-decoration"
      >
        <span css={iconStyles}>
          <SegmentIconSvg2 segment={segment.key} />
        </span>

        <span
          css={[
            fontSize.x25,
            css`
              &.selected {
                text-decoration: underline;
              }
            `,
          ]}
          className={cn("u-uppercase", isSelected ? "selected" : "")}
        >
          <T>{segment.label}</T>
        </span>
      </SwarovskiLink>
    </li>
  );
};
