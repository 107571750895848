import { useCallback } from "react";
import { useStore } from "../store/hooks/use-store";

/*
  This serves as a primary Translations utility
  
  For now, it's a compat-layer between string-valued-translations and ones wrapped in TranslatedValue object.

  In future, this should handle displaying translation overlays for editors, etc.
 */

export function useContentfulLink() {
  const { state } = useStore();
  const { space, environment } = state.config.contentful;

  return useCallback(
    (_: string | { assetId: string } | { entryId: string }) => {
      if (typeof _ === "string" || "entryId" in _) {
        const id = typeof _ === "string" ? _ : _.entryId;

        return `https://app.contentful.com/spaces/${space}/environments/${environment}/entries/${id}`;
      }

      return `https://app.contentful.com/spaces/${space}/environments/${environment}/assets/${_.assetId}`;
    },
    [space, environment],
  );
}
