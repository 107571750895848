import React from "react";
import { SerializedStyles, css } from "@emotion/react";
import cn from "classnames";
import { CSS_MQ } from "../../../styles/breakpoints";

export const MobilePrimaryAndSecondaryPaneContainer: React.FC<{
  primary: React.ReactNode;
  secondary: React.ReactNode;
  attachStyles?: SerializedStyles | Array<SerializedStyles>;
  className?: string;
}> = ({ primary, secondary, attachStyles, className }) => {
  return (
    <div
      className={cn(
        "js-mobile-primary-and-secondary-pane-container u-flex u-flex-col u-jc-between u-grow u-ai-start",
        className,
      )}
      css={attachStyles}
    >
      {primary}

      {secondary && (
        <div>
          <hr
            css={css`
              width: 100%;
              max-width: 100px;

              --m: 30px;
              ${CSS_MQ.atLeastMd} {
                --m: 40px;
              }

              margin: var(--m) 0 var(--m) 0;

              background-color: #00000033;
              border: none;
              height: 1px;
            `}
          />

          {secondary}
        </div>
      )}
    </div>
  );
};
