import React from "react";

export const useMountedThreshold = (thresholdMs: number) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setMounted(true);
    }, thresholdMs);

    return () => {
      clearTimeout(timeout);
    };
  }, [thresholdMs]);

  return mounted;
};
