import React from "react";
import { css } from "@emotion/react";
import { HeaderPrimaryNavigationElemT, type TranslatedValue } from "@swo/storefront";
import { fontSize } from "../../../styles/fonts";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { T } from "../../T";

export const PrimaryLinks: React.FC<{
  data: NonEmptyArray<HeaderPrimaryNavigationElemT>;
  setSeconadary: (label: TranslatedValue | string) => void;
}> = ({ data, setSeconadary }) => {
  return (
    <ul
      className="u-flex u-flex-col"
      css={[
        css`
          padding-top: 20px;

          gap: 40px;
        `,
      ]}
    >
      {data.map(item => (
        <li key={JSON.stringify(item)}>
          <button
            className="u-uppercase u-block u-w-full u-ta-left"
            css={fontSize.x25}
            onClick={() => setSeconadary(item.label)}
          >
            <T>{item.label}</T>
          </button>
        </li>
      ))}
    </ul>
  );
};
