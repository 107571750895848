export function mimeToLabel(fileType: string) {
  switch (fileType) {
    case "application/pdf": {
      return "pdf";
    }
    case "image/jpeg": {
      return "jpeg";
    }
    case "image/png": {
      return "png";
    }
    case "video/mp4": {
      return "mp4";
    }
    default: {
      return fileType;
    }
  }
}
