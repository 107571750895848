import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import { ContentfulImageProps } from "./ContentfulImage";
import { css, SerializedStyles } from "@emotion/react";
import { transformImage, wrapToHtmlSourceElements } from "./transformImage";
import React from "react";
import { useImageAssetHintsStateCtx } from "../../cms/edit-overlay/ToolbarStateProvider";
import { ContentfulImageAssetHint } from "./ContentfulImage2";

export const ImgWithObjectPosition: ReactTFC<{
  focalPointCssStr: string;
  contentfulLink: ContentfulImageProps["contentfulLink"];
  lazyLoaded?: "lazy" | "eager";
  css?: SerializedStyles;
  attachPictureStyles?: SerializedStyles;
  alt?: string;
  style?: React.CSSProperties;
  assetId: string | undefined;
}> = props => {
  const imageAssetHints = useImageAssetHintsStateCtx();

  const {
    contentfulLink,
    lazyLoaded,
    focalPointCssStr,
    css: attachStyles,
    alt,
    attachPictureStyles,
    style,
    assetId,
    ...rest
  } = props;

  const loading: "lazy" | "eager" | undefined = typeof lazyLoaded === "undefined" || lazyLoaded ? "lazy" : undefined;
  const transformed = transformImage(contentfulLink);

  if (typeof transformed === "string") {
    return null;
  }

  return (
    <>
      <picture css={attachPictureStyles} style={style}>
        {wrapToHtmlSourceElements(transformed).map(source => {
          return <source key={JSON.stringify(source)} srcSet={source.srcSetCssStr} type={source.type} />;
        })}

        <img
          src={transformed.fallback}
          {...rest}
          loading={loading}
          alt={alt}
          css={[
            css`
              position: absolute;

              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              object-fit: cover;
            `,
            attachStyles,
          ]}
          style={
            {
              objectPosition: props.focalPointCssStr,
            } as any
          }
        />
      </picture>

      {imageAssetHints && assetId && <ContentfulImageAssetHint assetId={assetId} />}
    </>
  );
};
