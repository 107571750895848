import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../../../styles/styles-css-variables";

export const styles = css`
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  --w: 10px;

  width: var(--w);
  height: var(--w);

  top: calc(var(--padding) - 2px);
  right: calc(var(--padding) - 6px);

  border-radius: 100%;

  background-color: currentColor;
  // color: var(--ctx-cart-color);
  border: 2px solid transparent;

  z-index: 1;

  .active & {
    --w: 12px;
    background-color: ${CSS_COLORS.White};
    border: 2px solid ${CSS_COLORS.Green} !important;
  }

  .opened-pickers & {
    --w: 12px;
    border: 2px solid #e6ecea;
  }

  &.error {
    background-color: ${CSS_COLORS.RedCustom};
  }
`;
