import React from "react";
import { css } from "@emotion/react";
import { fontSize } from "../../../styles/fonts";
import { Link } from "@swo/shared";
import { SwarovskiLinkLink } from "../../_shared-components/ui-elements/SwarovskiLink";

export const SecondaryLinks: React.FC<{ data: Array<Link> }> = ({ data }) => {
  return (
    <ul
      className="u-flex u-flex-col"
      css={[
        css`
          gap: 30px;
        `,
      ]}
    >
      {data.map(item => (
        <li key={item.label}>
          <SwarovskiLinkLink
            className="u-uppercase u-block u-w-full u-ta-left"
            attachStyles={[
              fontSize.x25,
              css`
                color: inherit;
              `,
            ]}
            link={item}
            styled="no-decoration"
          />
        </li>
      ))}
    </ul>
  );
};
