import React, { useCallback, useState } from "react";
import cn from "classnames";
import Headroom from "react-headroom";
import { usePinStart } from "./use-pin-start";
import { headerClassName } from "./MainHeaderComponent";
import { MainHeaderProps } from "./props";
import { headroomStyles } from "./headroomStyles";
import { SerializedStyles } from "@emotion/react";
import { useOnScroll, useScrollPosition, useWindowScroll } from "../../../hooks";
import { useHeaderPinnedState } from "./use-header-pinned-state";
import type { HeaderTheme } from "@swo/storefront";

export const HeaderHeadroom: React.FC<
  React.PropsWithChildren<
    Pick<MainHeaderProps, "floatingDisabled" | "headerHooks"> & {
      attachStyles?: SerializedStyles;
      className?: string;
      theme: HeaderTheme;
    }
  >
> = props => {
  const pinStart = usePinStart();
  const [scrollBelowZero, set] = useState(false);
  const [headerState, internalHeaderHooks] = useHeaderPinnedState();

  const cb = useCallback(() => {
    set(window.scrollY < 0);
  }, []);

  useWindowScroll(cb);

  const headerHooks = {
    onPin: () => {
      internalHeaderHooks.onPin();
      props.headerHooks?.onPin && props.headerHooks.onPin();
    },
    onUnfix: () => {
      internalHeaderHooks.onUnfix();
      props.headerHooks?.onUnfix && props.headerHooks.onUnfix();
    },
    onUnpin: () => {
      internalHeaderHooks.onUnpin();
      props.headerHooks?.onUnpin && props.headerHooks.onUnpin();
    },
  };

  return (
    <Headroom
      pinStart={pinStart}
      downTolerance={10}
      disableInlineStyles={true}
      disable={props.floatingDisabled || !!scrollBelowZero}
      css={[headroomStyles, props.attachStyles]}
      className={cn(
        props.theme === "white" ? "header-opaque" : "header-transparent",
        headerClassName,
        props.className,
        `js-header-state-${headerState}`,
      )}
      {...headerHooks}
    >
      {props.children}
    </Headroom>
  );
};
