import React from "react";
import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import { css } from "@emotion/react";
import cn from "classnames";
import { CSS_COLORS } from "../../../styles/styles-css-variables";

export const Spinner: ReactTFC<{ color?: "white" | "green" }> = props => {
  return (
    <div css={styles} className={cn(props.color || "green")}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

const styles = css`
  --w: 100%;
  --z: calc(0.8 * var(--w));
  --m: calc(0.1 * var(--w));

  &.green {
    --color: ${CSS_COLORS.Green};
  }

  &.white {
    --color: ${CSS_COLORS.White};
  }

  display: inline-block;
  position: relative;

  width: var(--w);
  height: var(--w);

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: var(--z);
    height: var(--z);

    margin: var(--m);
    border-style: solid;
    border-color: ${CSS_COLORS.Green};
    border-width: var(--m);

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--color) transparent transparent transparent;
  }

  div:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  div:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  div:nth-of-type(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
