import React from "react";
import { css } from "@emotion/react";
import cn from "classnames";
import { T } from "../../T";
import type { TranslatedValue } from "@swo/storefront";

export const NavigationTitle: React.FC<{ title: TranslatedValue; stickTo?: "right" }> = ({ title, stickTo }) => (
  <span
    className={cn("u-absolute _navigation-title u-uppercase", stickTo === "right" ? "stick-right" : "stick-center")}
    css={css`
      top: calc(100% - 0.2em);
      width: 8em;

      &.stick-right {
        right: 0;
        text-align: right;
      }

      &.stick-center {
        left: 0;
        text-align: center;
      }
    `}
  >
    <T>{title}</T>
  </span>
);
