import React, { useEffect, useRef } from "react";
import { HeaderNavigationDataT, HeaderPrimaryNavigationElemT, type TranslatedValue } from "@swo/storefront";
import { css } from "@emotion/react";
import { FONTS, fontSize } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { ContactDataType } from "@swo/shared";
import { HeaderNavigationLeftPaneFirstColumn } from "./HeaderNavigationLeftPaneFirstColumn";
import { HeaderNavigationLeftPaneSecondColumn } from "./HeaderNavigationLeftPaneSecondColumn";
import cn from "classnames";
import { firstFocusableChildrenOf } from "../../../layouts/_layout-helpers/a18y/firstFocusableChildrenOf";
import { useMenuAim } from "./useMenuAim";

export type HeaderNavigationDataProps = {
  data: HeaderNavigationDataT;
  contactData: ContactDataType;
  tag: "shop" | "content";
  paneTitle: TranslatedValue;
  onClose: () => void;
};

export const HeaderNavigationData: React.FC<HeaderNavigationDataProps> = ({
  contactData,
  data,
  paneTitle,
  tag,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const secondaryContentRef = useRef<HTMLDivElement | null>(null);
  const secondaryContentPrimaryHeadlineRef = useRef<HTMLDivElement | null>(null);

  const { onMouse, element, hasInteracted, state } = useMenuAim(
    ref,
    secondaryContentPrimaryHeadlineRef,
    data.primary[0],
    { debug: false },
  );

  useEffect(() => {
    const el = ref.current;

    if (!el) {
      return;
    }

    const handler = (e: KeyboardEvent): void => {
      switch (e?.key) {
        case "Escape": {
          return onClose();
        }
        case "ArrowLeft": {
          const contentEl = ref.current;

          if (!contentEl) {
            return;
          }

          return firstFocusableChildrenOf(contentEl)?.focus();
        }
        case "ArrowRight": {
          const contentEl = secondaryContentRef.current;

          if (!contentEl) {
            return;
          }

          return firstFocusableChildrenOf(contentEl)?.focus();
        }
      }
    };

    el.addEventListener("keydown", handler);

    return () => {
      el.removeEventListener("keydown", handler);
    };
  }, []);

  useEffect(() => {
    if (!hasInteracted) {
      return;
    }

    const el = secondaryContentRef.current;

    if (!el) {
      return;
    }

    firstFocusableChildrenOf(el)?.focus();
  }, [state.label]);

  function selectItem(item: HeaderPrimaryNavigationElemT) {
    // setHasInteracted(true);
    // setState(item);
  }

  const wrapperStyles = css`
    --gap: 0px;
    gap: var(--gap);

    grid-template-columns: minmax(0, 5fr) minmax(0, 4fr);
  `;

  return (
    <div className={cn("js-header-navigation-data", "u-grid u-h-full")} css={wrapperStyles} ref={ref}>
      {element}

      <HeaderNavigationLeftPaneFirstColumn
        contactData={contactData}
        data={data}
        paneTitle={paneTitle}
        tag={tag}
        state={state}
        selectItem={selectItem}
        key={tag}
        onMouse={onMouse}
      />

      <HeaderNavigationLeftPaneSecondColumn
        contactData={contactData}
        data={state}
        ref={secondaryContentRef}
        requestCollapsingNavigation={onClose}
        secondaryContentPrimaryHeadlineRef={secondaryContentPrimaryHeadlineRef}
      />
    </div>
  );
};

export const headlineStyles = [
  fontSize.x5d,
  css`
    text-transform: uppercase;
    word-break: break-word;
  `,
];

export const elementStyles = [
  FONTS.SwarovskiFutura300,
  css`
    cursor: pointer;
    text-align: left;

    &.selected {
      ${FONTS.SwarovskiFutura700};
      color: ${CSS_COLORS.Green};
    }
  `,
];

export const paddingStyles = css`
  padding: var(--padding-top) 0 20px 40px;
`;
