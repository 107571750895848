import { PublicConfigurationT } from "@swo/shared";

export function prepareOauthLink(
  returnRelativePath: string,
  authServiceHost: URL,
  config: PublicConfigurationT,
  { languageIsoCode, countryIsoCode }: { languageIsoCode: string; countryIsoCode: string },
): { login: string; register: string; profile: string } {
  const FALLBACK_URL = "https://www.swarovskioptik.com/";

  function getLoginUrl() {
    const loginSearchParams = new URLSearchParams({
      client_id: config.myService.clientId,
      redirect_uri: new URL("/auth/login", authServiceHost).toString(),
      response_type: "code",
      state: returnRelativePath,
      language: languageIsoCode,
      country: countryIsoCode.toUpperCase(),
    });
    try {
      return `${new URL(config.myService.loginUrl).toString()}?${loginSearchParams.toString()}`;
    } catch {
      return FALLBACK_URL;
    }
  }

  const languageParams = new URLSearchParams({
    language: languageIsoCode,
    country: countryIsoCode.toUpperCase(),
  });

  function getRegisterUrl() {
    try {
      return `${new URL(config.myService.registerUrl)}?${languageParams.toString()}`;
    } catch {
      return FALLBACK_URL;
    }
  }

  function getProfileUrl() {
    try {
      return `${new URL(config.myService.profileUrl)}?${languageParams.toString()}`;
    } catch {
      return FALLBACK_URL;
    }
  }

  const loginUrl = getLoginUrl();
  const registerUrl = getRegisterUrl();
  const profileUrl = getProfileUrl();

  return { login: loginUrl, register: registerUrl, profile: profileUrl };
}
