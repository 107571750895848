import React, { useEffect, useRef, useState } from "react";
import { LoadingState, LoadingStateOps } from "../../domain/data-layer/loading-state";
import { timedWithPrint } from "../../utils/development/development";
import { LoadingStateWithIdle, LoadingStateWithIdleOps } from "../../domain/data-layer/loading-state-with-idle";

type FetchedWIthPendingReferenceOpts<T> = { dataKey: string; initialState?: T };

type FetchedWithPendingReferenceRet<T> = [
  { data: LoadingState<T>; dataKey: string },
  React.RefObject<{ id: string } | null>,
];

function getEff<T>(dataKey: string, eff: (abortSignal: AbortSignal) => Promise<T>) {
  const abortController = new AbortController();
  const signal = abortController.signal;
  const measurementMarkName = dataKey;

  const effect = measurementMarkName ? timedWithPrint(() => eff(signal), measurementMarkName) : eff(signal);

  return {
    effect,
    controller: abortController,
  };
}

export function useFetchedWithPendingReference<T>(
  eff: (abortSignal: AbortSignal) => Promise<T>,
  opts: FetchedWIthPendingReferenceOpts<T>,
): FetchedWithPendingReferenceRet<T> {
  function getInitialState() {
    return {
      data: opts.initialState ? LoadingStateOps.asLoaded(opts.initialState) : LoadingStateWithIdleOps.idleS,
      dataKey: opts.dataKey,
    };
  }

  const [state, setState] = useState<{ data: LoadingStateWithIdle<T>; dataKey: string }>(getInitialState);

  const pendingRequestRef = useRef<null | { id: string; controller: AbortController }>(null);

  const dataKeyHasChanged = opts.dataKey !== state.dataKey;
  const currentStateInIdle = opts.dataKey === state.dataKey && state.data.__tag === "idle";

  if (dataKeyHasChanged || currentStateInIdle) {
    const requestId = Math.random().toString();
    const { effect, controller } = getEff(opts.dataKey, eff);

    // Newest wins semantics
    const previous = pendingRequestRef.current;
    if (previous) {
      previous.controller.abort();
    }
    pendingRequestRef.current = { id: requestId, controller };

    if (process.env.NODE_ENV === "development") {
      console.debug(`Fetching '${opts.dataKey}'`);
    }

    setState({ data: LoadingStateOps.loadingS, dataKey: opts.dataKey });

    effect
      .then(data => {
        if (pendingRequestRef.current?.id === requestId) {
          setState({ data: LoadingStateOps.asLoaded(data), dataKey: opts.dataKey });
        }
      })
      .catch(error => {
        if (pendingRequestRef.current?.id === requestId) {
          setState({ data: LoadingStateOps.asFailed(error), dataKey: opts.dataKey });
        }
      })
      .finally(() => {
        if (pendingRequestRef.current?.id === requestId) {
          pendingRequestRef.current = null;
        }
      });
  }

  const externalState = {
    data: LoadingStateWithIdleOps.toLoadingState(state.data),
    dataKey: state.dataKey,
  };

  return [externalState, pendingRequestRef];
}
