import { ContactDataType } from "@swo/shared";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import React from "react";
import { CustomLinksUniversalElement } from "./CustomLinks";
import { ServiceUniversalElement } from "./Service";
import { StoriesUniversalElement } from "./Stories";
import { EventsUniversalElement } from "./EventsUniversalElement";
import { ProductCategoriesUniversalElement } from "./ProductCategoriesUniversalElement";

type HeaderNavigationDataItemProps = {
  item: HeaderPrimaryNavigationElemT;
  contactData: ContactDataType;
  requestCollapsingNavigation: () => void;
};

export const HeaderNavigationDataItem = React.forwardRef<HTMLDivElement, HeaderNavigationDataItemProps>(
  ({ item, contactData, requestCollapsingNavigation }, ref) => {
    switch (item.type) {
      case "Custom Links": {
        return <CustomLinksUniversalElement content={item} ref={ref as any} />;
      }
      case "Events": {
        return <EventsUniversalElement content={item} ref={ref} />;
      }
      case "Product Categories": {
        return <ProductCategoriesUniversalElement content={item} ref={ref} />;
      }
      case "Service": {
        return (
          <ServiceUniversalElement
            service={item}
            contactData={contactData}
            ref={ref}
            requestCollapsingNavigation={requestCollapsingNavigation}
          />
        );
      }
      case "Stories": {
        return <StoriesUniversalElement stories={item} ref={ref} />;
      }

      default: {
        const _: never = item;
        return _;
      }
    }
  },
);
