import React from "react";
import { useCartContext } from "../../../global/cart-context/cart-context";
import { ShoppingCartDropdownSummaryElement } from "./MenuMetaComponent/ShoppingCart/ShoppingCartDropdownSummaryElement";
import { HeaderT } from "../HeaderT";
import { SwarovskiButton } from "../../_shared-components/ui-elements/SwarovskiButton";
import { CartPane } from "./CartPane";
import { EmptyCart } from "./EmptyCart";
import { SmallLoadingSpinner } from "../../../routes/modal-loading-spinner";
import { computeCartData } from "./MenuMetaComponent/ShoppingCart/useNonEmptyCartData";
import { usePageContext } from "../../../global/page-context";
import { CartLineItems } from "./CartLineItems";
import { NewHeaderData } from "@swo/storefront";
import { T } from "../../T";

export const CartPaneContent: React.FC<{ t: HeaderT; cartData: NewHeaderData["cart"]; linkToCartPage: string }> = ({
  t,
  cartData,
  linkToCartPage,
}) => {
  const { cart: cartCtx } = useCartContext();
  const page = usePageContext();

  const languageIsoCode = page.pageBase.locale.language.isoCode.toLowerCase();

  const { shouldHaveDropdownNonEmptyCartData } = computeCartData(
    cartCtx._tag === "Right" ? cartCtx.right.cart : null,
    page,
  );

  function onLineItemRemovalRequested(lineItemId: string) {
    if (cartCtx._tag === "Right") {
      return cartCtx.right.dispatchAction({ tag: "remove-line-item-from-cart", lineItemId });
    } else {
      return Promise.resolve();
    }
  }

  const CartButton = (
    <SwarovskiButton variant={"green"} as={"a"} isInternal link={linkToCartPage}>
      <T>{t("cart.toTheCart")}</T>
    </SwarovskiButton>
  );

  const EmptyCartButtonWithFallback = cartData.productGuide ? (
    <SwarovskiButton
      variant={"green"}
      as={"a"}
      isInternal={cartData.productGuide.type === "Internal Link"}
      link={cartData.productGuide.data}
      target={!!cartData.productGuide.openInNewTab ? "_blank" : undefined}
    >
      {cartData.productGuide.label}
    </SwarovskiButton>
  ) : (
    CartButton
  );

  if (cartCtx._tag === "Left") {
    return (
      <div>
        <SmallLoadingSpinner color="green" noMargin />
      </div>
    );
  }

  const { cart } = cartCtx.right;

  if (cart === null || cart.computed.lineItems.length === 0) {
    return (
      <CartPane t={t}>
        <EmptyCart t={t}>{EmptyCartButtonWithFallback}</EmptyCart>
      </CartPane>
    );
  }

  return (
    <CartPane t={t}>
      {shouldHaveDropdownNonEmptyCartData && (
        <CartLineItems
          lineItems={shouldHaveDropdownNonEmptyCartData.lineItems}
          languageIsoCode={languageIsoCode}
          onLineItemRemovalRequested={onLineItemRemovalRequested}
        />
      )}

      <ShoppingCartDropdownSummaryElement cartPrice={cart.computed.cartPrice} totalPriceLabel={t("cart.totalPrice")}>
        {CartButton}
      </ShoppingCartDropdownSummaryElement>
    </CartPane>
  );
};
