import { css } from "@emotion/react";
import { UnionTypeFromTuple } from "../utils/fp/small-fp-utils";

const breakpointsWidths = { smMin: 576, mdMin: 768, lgMin: 992, xlMin: 1400 };
const DIFF = 0.05;

const atLeastMqs = Object.entries(breakpointsWidths).map(([key, value]) => atLeastMediaQuery(value));
const atMostMqs = Object.entries(breakpointsWidths).map(([key, value]) => atMostMediaQuery(value));

export function atLeastMediaQuery(valuePx: number): string {
  return `@media (min-width: ${valuePx}px)`;
}

export function atMostMediaQuery(valuePx: number): string {
  return `@media (max-width: ${valuePx - DIFF}px)`;
}

export function betweenMediaQuery(fromPx: number, toPx: number): string {
  return `@media (min-width: ${fromPx}px) and (max-width: ${toPx - DIFF}px)`;
}

export const breakpoints = ["xs", "sm", "md", "lg", "xl"] as const;

export type CSSBreakpoint = UnionTypeFromTuple<typeof breakpoints>;

export function getCssBreakpointFromContainerWidth(cw: number): CSSBreakpoint {
  if (cw < breakpointsWidths.smMin) {
    return "xs";
  } else if (cw < breakpointsWidths.mdMin) {
    return "sm";
  } else if (cw < breakpointsWidths.lgMin) {
    return "md";
  } else if (cw < breakpointsWidths.xlMin) {
    return "lg";
  } else {
    return "xl";
  }
}

export const CSS_MQ_ONLY: { [key in CSSBreakpoint | `md_and_lg`]: string } = {
  xs: atMostMediaQuery(breakpointsWidths.smMin - DIFF),
  sm: betweenMediaQuery(breakpointsWidths.smMin, breakpointsWidths.mdMin - DIFF),
  md: betweenMediaQuery(breakpointsWidths.mdMin, breakpointsWidths.lgMin - DIFF),
  lg: betweenMediaQuery(breakpointsWidths.lgMin, breakpointsWidths.xlMin - DIFF),
  md_and_lg: betweenMediaQuery(breakpointsWidths.mdMin, breakpointsWidths.xlMin - DIFF),
  xl: atLeastMediaQuery(breakpointsWidths.xlMin),
};

export const BREAKPOINTS = {
  atLeastSm: breakpointsWidths.smMin,
  atLeastMd: breakpointsWidths.mdMin,
  atLeastLg: breakpointsWidths.lgMin,
  atLeastXl: breakpointsWidths.xlMin,
};

export const CSS_MQ = {
  atLeastSm: atLeastMqs[0],
  atLeastMd: atLeastMqs[1],
  atLeastLg: atLeastMqs[2],
  atLeastXl: atLeastMqs[3],

  atMostXs: atMostMqs[0],
  atMostSm: atMostMqs[1],
  atMostMd: atMostMqs[2],
  atMostLg: atMostMqs[3],

  lowResNotebook: `@media (min-width: ${breakpointsWidths.lgMin}px) and (max-width: ${
    breakpointsWidths.xlMin - DIFF
  }px) and (max-height: 768px)`,

  atLeastMidScreen: atLeastMediaQuery(1160),
};

export const MQ_DISPLAY = {
  onlyAtLeastMd: css`
    ${CSS_MQ.atMostSm} {
      display: none;
    }
  `,
  onlyAtLeastLg: css`
    ${CSS_MQ.atMostMd} {
      display: none;
    }
  `,
  onlyAtMostSm: css`
    ${CSS_MQ.atLeastMd} {
      display: none;
    }
  `,
  onlyAtMostMd: css`
    ${CSS_MQ.atLeastLg} {
      display: none;
    }
  `,
  onlyAtMostLg: css`
    ${CSS_MQ.atLeastXl} {
      display: none;
    }
  `,
};
