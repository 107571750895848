import { useEffect } from "react";

export const CONTENT_FADEOUT_CSS_CLASSNAME = "content-fadeout";

export function useContentFadeout(isActive: boolean) {
  return useGenericContentFadeout(isActive, CONTENT_FADEOUT_CSS_CLASSNAME);
}

function useGenericContentFadeout(isActive: boolean, className: string) {
  useEffect(() => {
    if (isActive) {
      const rootEl = document.getElementById("root");

      if (rootEl) {
        rootEl.classList.add(className);
      }

      return () => {
        if (rootEl) {
          rootEl.classList.remove(className);
        }
      };
    }
  }, [isActive]);
}
