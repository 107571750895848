import React from "react";

// It might be much better to just have them as plain assets
// and not SVG-in-JS. We're not manipulating the flags in any way for this approach to make sense.
const Lazy_AustriaFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Austria })));
const Lazy_BelgiumFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Belgium })));
const Lazy_CanadaFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Canada })));
const Lazy_FranceFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.France })));
const Lazy_GermanyFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Germany })));
const Lazy_ItalyFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Italy })));
const Lazy_LuxembourgFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Luxembourg })));
const Lazy_NetherlandsFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Netherlands })));
const Lazy_SwedenFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Sweden })));
const Lazy_GBFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.GB })));
const Lazy_USAFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.USA })));
const Lazy_SpainFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Spain })));
const Lazy_SwitzerlandFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Switzerland })));
const Lazy_DenmarkFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Denmark })));
const Lazy_NorwayFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Norway })));
const Lazy_PolandFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Poland })));
const Lazy_FinlandFlag = React.lazy(() => import("./country-flags").then(m => ({ default: m.Finland })));

export const Lazy_OtherCountriesFlag = React.lazy(() =>
  import("./country-flags").then(m => ({ default: m.OtherCountries })),
);

export const flagsForCountries = {
  AT: Lazy_AustriaFlag,
  BE: Lazy_BelgiumFlag,
  CA: Lazy_CanadaFlag,
  FR: Lazy_FranceFlag,
  DE: Lazy_GermanyFlag,
  IT: Lazy_ItalyFlag,
  LU: Lazy_LuxembourgFlag,
  NL: Lazy_NetherlandsFlag,
  SE: Lazy_SwedenFlag,
  GB: Lazy_GBFlag,
  US: Lazy_USAFlag,
  ES: Lazy_SpainFlag,
  CH: Lazy_SwitzerlandFlag,
  DK: Lazy_DenmarkFlag,
  NO: Lazy_NorwayFlag,
  PL: Lazy_PolandFlag,
  FI: Lazy_FinlandFlag,
};
