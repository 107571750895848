import React from "react";
import { css } from "@emotion/react";
import cn from "classnames";

export const GoBackButton: React.FC<React.PropsWithChildren<{ onClick: () => void; isActive: boolean }>> = ({
  onClick,
  isActive,
  children,
}) => (
  <span
    className={cn({ active: isActive })}
    css={css`
      &:not(.active) {
        filter: opacity(0);
      }

      display: block;

      position: absolute;
      left: 12px;
      top: 20px;

      &.active {
        filter: opacity(1);
      }

      transition: all 0.1s ease-out;
    `}
  >
    <p
      onClick={onClick}
      className={cn("u-uppercase so-color-green u-m-0")}
      css={css`
        writing-mode: vertical-rl;
        transform: scale(-1);
        cursor: pointer;
      `}
    >
      {children}
    </p>
  </span>
);
