import type { TranslatedValue } from "@swo/storefront";
import type React from "react";
import { useTranslationsStateCtx } from "./cms/edit-overlay/ToolbarStateProvider";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../styles/styles-css-variables";
import { useContentfulLink } from "./useContentfulLink";
import cn from "classnames";

export const T: React.FC<{
  children: string | TranslatedValue;
  forcedValue?: TranslatedValue;
}> = props => {
  const isEnabled = useTranslationsStateCtx();
  const contentfulLink = useContentfulLink();

  if (typeof props.children === "string") {
    return <>{props.children}</>;
  }

  if (!props.children) {
    console.error("T: children is undefined", props.children, new Error().stack);
    return null;
  }

  if (!("value" in props.children)) {
    console.error("T: children is not a TranslatedValue", props.children);

    return null;
  }

  if (!isEnabled) {
    return <>{props.children.value}</>;
  } else {
    return (
      <InternalWrapper
        value={props.children.value}
        type={props.children.type}
        translationKey={props.forcedValue?.key ?? props.children.key}
        contentfulLink={props.children.type === "translation" ? contentfulLink(props.children.id) : undefined}
      />
    );
  }
};

export const InternalWrapper: React.FC<{
  value: string | React.ReactNode;
  translationKey: string | undefined;
  contentfulLink: string | undefined;
  type: TranslatedValue["type"];
}> = ({ value, translationKey, contentfulLink, type }) => {
  const onClick = contentfulLink
    ? () => {
        window.open(contentfulLink, "_blank");
      }
    : () => {
        if (typeof value === "string") {
          navigator.clipboard.writeText(value).catch(error => {
            console.error("Failed to copy translation key to clipboard:", error);
          });
        }
      };

  return (
    <span
      className={cn("js-translation-token", `js-key-${translationKey}`)}
      css={css`
        position: relative;
      `}
    >
      <span>{value}</span>

      <span
        onClick={onClick}
        className={cn("js-translation-token-overlay", {
          translation: type === "translation",
          fallback: type === "fallback",
        })}
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          display: block;

          &.translation {
            background: ${CSS_COLORS.YellowCustom};
          }

          &.fallback {
            background: ${CSS_COLORS.RedTransparentCustom2};
            cursor: copy;
          }
        `}
      >
        <span
          css={css`
            z-index: 1;

            position: absolute;
            top: -1.2em;
            left: 0;
            transform: translateX(-25%);

            font-family: sans-serif;
            font-size: 13px;
            line-height: 1;
            letter-spacing: -0.05em;

            color: black;
            background: white;
            width: max-content;

            font-weight: 300;
            text-transform: none;
          `}
        >
          {translationKey}
        </span>
      </span>
    </span>
  );
};

export const TWrapper: React.FC<{
  forcedValue: TranslatedValue;
  children: React.ReactNode;
}> = props => {
  const isEnabled = useTranslationsStateCtx();

  if (!isEnabled) {
    return <>{props.children}</>;
  }

  return (
    <InternalWrapper
      value={props.children}
      translationKey={props.forcedValue.key}
      contentfulLink={undefined}
      type={props.forcedValue.type}
    />
  );
};

export function mapTranslatedValue(t: TranslatedValue, mapV: (v: string) => string): TranslatedValue {
  return {
    ...t,
    value: mapV(t.value),
  };
}

export const tObj = {
  fallbackOf: (value: string): TranslatedValue => ({
    value,
    key: undefined,
    type: "fallback",
  }),
};
