import { useEffect } from "react";

export function useBodyOverflowStyles(isActive: boolean, usePositionFixed: boolean = false) {
  useEffect(() => {
    const currentOverflowY = document.body.style.overflowY;
    const currentMarginRight = document.body.style.marginRight;
    const currentPosition = document.body.style.position;
    const currentWidth = document.body.style.width;
    const currentScroll = window.scrollY;

    if (isActive) {
      const scrollbarWidth = measureScrollbarWidth();

      document.body.style.overflowY = "hidden";
      document.body.style.marginRight = `${scrollbarWidth}px`;

      if (usePositionFixed) {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
      }

      return () => {
        document.body.style.overflowY = currentOverflowY;
        document.body.style.marginRight = currentMarginRight;

        if (usePositionFixed) {
          document.body.style.width = currentWidth;
          document.body.style.position = currentPosition;
          window.scrollTo(0, currentScroll);
        }
      };
    }
  }, [isActive]);

  function measureScrollbarWidth() {
    const el = document.createElement("div");
    el.style.overflow = "scroll";

    document.body.appendChild(el);
    const scrollbarWidth = el.offsetWidth - el.clientWidth;
    document.body.removeChild(el);

    return scrollbarWidth;
  }

  return undefined;
}
