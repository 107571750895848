import React from "react";
import { Global } from "@emotion/react";
import { globalStyles } from "./styles/globalStyles";
import { EmptyHeaderComponent } from "./components/HeaderComponent/EmptyHeaderComponent";
import { AppView } from "./styles/AppView";
import { BrowserRouter as Router } from "react-router";
import { Route, Routes } from "react-router";
import { HeaderSpacer } from "./layouts/_layout-helpers/spacers";
import { Spacer } from "./components/_shared-components/arrangement/Space";
import { FooterEnd } from "./components/FooterComponent/subcomponents/FooterEnd";

export const SinglePageFallbackAppShell: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Global styles={globalStyles} />

      <Router>
        <Routes>
          <Route
            path="*"
            element={
              <AppView>
                <EmptyHeaderComponent />
                <HeaderSpacer />
                <Spacer size={"md"} />

                <div className="expand">{children}</div>

                <FooterEnd />
              </AppView>
            }
          />
        </Routes>
      </Router>
    </>
  );
};
