import React from "react";
import { Link } from "@swo/shared";
import { css } from "@emotion/react";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { paddingStyles } from "./paddingXStyles";
import { CSS_MQ } from "../../../styles/breakpoints";
import { NewLabel } from "../../_shared-components/new/new-label";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { LinkElement } from "./LinkElement";

export type Node = {
  link: Link;
  isNew: boolean;
};

type Props = {
  links: Array<
    Node & {
      children: NonEmptyArray<Node> | null;
    }
  >;
};

export const Links = React.forwardRef<HTMLDivElement, Props>(({ links }, ref) => {
  return (
    <ul
      ref={ref as any}
      className="u-flex u-flex-col"
      css={[
        css`
          gap: 30px;
          ${CSS_MQ.atLeastMd} {
            gap: 40px;
          }
        `,
      ]}
    >
      {links.map(({ link, isNew, children }) => (
        <LinkElement key={link.label} children={children} isNew={isNew} link={link} />
      ))}
    </ul>
  );
});
