import React from "react";
import { css } from "@emotion/react";
import { FONTS, fontSize } from "../../../styles/fonts";
import { SwarovskiLinkLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { Props } from "./HeaderNavigationLeftPaneFirstColumn";

export const SecondaryNavigation: React.FC<{ data: Props["data"]["secondary"] }> = ({ data }) => {
  if (data.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        gap: 40px;
        margin-bottom: 40px;
        padding: var(--padding-top) 0 0 40px;

        z-index: 1;

        position: relative;

        &:before {
          content: "";
          position: absolute;

          top: calc(var(--padding-top) - 2em);
          left: 40px;
          height: 2px;

          width: 100%;
          max-width: 100px;

          background-color: #00000033;
        }
      `}
    >
      <ul
        className="u-flex u-flex-col"
        css={css`
          gap: 32px;
        `}
      >
        {data.map(item => (
          <li key={item.label}>
            <SwarovskiLinkLink
              link={item}
              styled="no-decoration"
              attachStyles={[
                FONTS.SwarovskiFutura500,
                fontSize.x25,
                css`
                  color: inherit;

                  &:hover {
                    text-decoration: underline;
                  }
                `,
              ]}
              className="u-uppercase"
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
