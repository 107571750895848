import React from "react";
import { SwarovskiHomepageLink } from "./SwarovskiLogoComponent";
import { css } from "@emotion/react";
import { SwarovskiLogoComponentWithChildren } from "./SwarovskiLogoComponentWithChildren";
import { SwarovskiOptikLogotype } from "../../../assets";
import { CSS_MQ } from "../../../styles/breakpoints";

export const SwarovskiHeaderLogo: React.FC<{ link: SwarovskiHomepageLink }> = ({ link }) => {
  return (
    <SwarovskiLogoComponentWithChildren
      homepageLink={link}
      className="u-relative _logo"
      attachStyles={css`
        display: inline-block;
        color: currentColor;

        svg {
          max-height: 100%;
          max-width: 100%;
        }

        ${CSS_MQ.atMostSm} {
          height: 49px;
        }
      `}
    >
      <SwarovskiOptikLogotype />
    </SwarovskiLogoComponentWithChildren>
  );
};
