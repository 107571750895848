import React from "react";
import { ContactDataType } from "@swo/shared";
import { NewHeaderData } from "@swo/storefront";
import { HeaderNavigationData } from "./HeaderNavigationData";
import { ContentPaneContent } from "./PaneContentState";

export function getContentPaneContent(
  paneContent: ContentPaneContent,
  contactData: ContactDataType,
  headerNew: NewHeaderData,
  onClose: () => void,
) {
  switch (paneContent.tag) {
    case "shop": {
      return (
        <HeaderNavigationData
          data={headerNew.shop}
          contactData={contactData}
          key="shop"
          tag={paneContent.tag}
          paneTitle={paneContent.title}
          onClose={onClose}
        />
      );
    }
    case "content": {
      return (
        <HeaderNavigationData
          data={headerNew.discover}
          contactData={contactData}
          key="discover"
          tag={paneContent.tag}
          paneTitle={paneContent.title}
          onClose={onClose}
        />
      );
    }
    default: {
      const _: never = paneContent.tag;
      return _;
    }
  }
}
