import React, { useCallback, useEffect } from "react";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import { useEqualState } from "../../../hooks";

type Pos = {
  x: number;
  y: number;
};

const GRACE_SPACE = 20;

type State = {
  startPos: Pos;
  targetPosTop: Pos;
  targetPosBottom: Pos;
  item: HeaderPrimaryNavigationElemT;
} | null;

export function useMenuAim(
  ref: React.RefObject<HTMLDivElement | null>,
  secondaryContentPrimaryHeadlineRef: React.RefObject<HTMLDivElement | null>,
  initiallySelected: HeaderPrimaryNavigationElemT,
  { debug }: { debug: boolean },
) {
  const [hasInteracted, setHasInteracted] = useEqualState(false);
  const [state, setState] = React.useState<HeaderPrimaryNavigationElemT>(initiallySelected);
  const [tick, setTick] = React.useState(0);

  const stateRef = React.useRef<State>(null);

  const onMouse = useCallback(
    (item: HeaderPrimaryNavigationElemT) =>
      (type: "over" | "leave" | "move", event: React.MouseEvent, element: "wrapper" | "text") => {
        event.stopPropagation();

        const { pageX, pageY } = event;

        if (type === "over") {
          setTimeout(() => setHasInteracted(true), 0);
        }

        if (type === "leave") {
          //
        } else {
          const targetEl = secondaryContentPrimaryHeadlineRef.current;

          if (!targetEl) {
            return;
          }

          const startPos = { x: pageX, y: pageY };
          const rect = targetEl.getBoundingClientRect().toJSON() as DOMRect;

          const targetPosTop = {
            x: Math.round(rect.x),
            y: Math.round(rect.y),
          };
          const targetPosBottom = {
            x: Math.round(rect.x),
            y: Math.round(rect.y + rect.height),
          };

          if (element === "wrapper" && stateRef.current && checkIfInTriangle({ x: pageX, y: pageY })) {
            return false;
          }

          if (element === "wrapper" || element === "text") {
            stateRef.current = { startPos, targetPosTop, targetPosBottom, item };
            setState(item);
          }

          if (debug) {
            setTick(t => t + 1);
          }
        }
      },
    [],
  );

  useEffect(() => {
    const handler = (event: MouseEvent): void => {
      const { pageX, pageY } = event;
      const isInTriangle = checkIfInTriangle({ x: pageX, y: pageY });

      if (!isInTriangle) {
        stateRef.current = null;
      }
    };

    window.addEventListener("mousemove", handler);

    return () => {
      window.removeEventListener("mousemove", handler);
    };
  }, []);

  function getDims(state: State) {
    if (!state) {
      return null;
    }

    let lineTopDraft = {
      x1: state.startPos.x,
      y1: state.startPos.y,
      x2: state.targetPosTop.x,
      y2: state.targetPosTop.y,
    };

    let lineBottomDraft = {
      x1: state.startPos.x,
      y1: state.startPos.y,
      x2: state.targetPosBottom.x,
      y2: state.targetPosBottom.y,
    };

    const lineTopProps = getLineProps(lineTopDraft);
    const lineBottomProps = getLineProps(lineBottomDraft);

    return {
      lineTop: adjustLineDraftWithProps(lineTopDraft, lineTopProps.a, lineBottomProps.a, "top"),
      lineBottom: adjustLineDraftWithProps(lineBottomDraft, lineTopProps.a, lineBottomProps.a, "bottom"),
      state,
    };
  }

  function adjustLineDraftWithProps(
    lineDraft: { x1: number; y1: number; x2: number; y2: number },
    a1: number,
    a2: number,
    v: "top" | "bottom",
  ) {
    let line = lineDraft;

    if (a1 * a2 < 0) {
      if (v === "top") {
        line.x1 = line.x1 - GRACE_SPACE;
        line.y1 = line.y1;
        line.x2 = line.x2;
        line.y2 = line.y2 - GRACE_SPACE;
      } else {
        line.x1 = line.x1 - GRACE_SPACE;
        line.y1 = line.y1;
        line.x2 = line.x2;
        line.y2 = line.y2 + GRACE_SPACE;
      }
    } else {
      if (v === "top") {
        line.x1 = line.x1 - GRACE_SPACE;
        line.y1 = line.y1 - GRACE_SPACE;
        line.x2 = line.x2;
        line.y2 = line.y2 - GRACE_SPACE;
      } else {
        line.x1 = line.x1 + GRACE_SPACE;
        line.y1 = line.y1 + GRACE_SPACE;
        line.x2 = line.x2;
        line.y2 = line.y2 + GRACE_SPACE;
      }
    }

    return {
      line: line,
      props: getLineProps(line),
    };
  }

  function getElement() {
    const dims = getDims(stateRef.current);

    if (!dims) {
      return null;
    }

    const startPoint = createPointEl(dims.state.startPos);
    const endPoint = createPointEl(dims.state.targetPosTop);

    const lineTopEl = createLineEl(dims.lineTop.line, dims.lineTop.props, debug);
    const lineBottomEl = createLineEl(dims.lineBottom.line, dims.lineBottom.props, debug);

    return debug ? (
      <>
        {startPoint}
        {endPoint}
        {lineTopEl}
        {lineBottomEl}
      </>
    ) : null;
  }

  function checkIfInTriangle(point: Pos) {
    const dim = getDims(stateRef.current);

    if (!dim) {
      return false;
    }

    const { lineTop, lineBottom } = dim;

    const topLineParams = getLineProps(lineTop.line);
    const bottomLinePars = getLineProps(lineBottom.line);

    const upperY = topLineParams.f(point.x);
    const lowerY = bottomLinePars.f(point.x);

    const c = upperY < point.y;
    const d = lowerY > point.y;
    const isInRange = c && d;

    if (isInRange) {
      return true;
    } else {
      return false;
    }
  }

  return { onMouse, element: getElement(), hasInteracted, state };
}

export type MenuAimOnMouse = (
  item: HeaderPrimaryNavigationElemT,
) => (type: "over" | "leave" | "move", event: React.MouseEvent, container: "wrapper" | "text") => void;

function createPointEl({ x, y }: Pos) {
  const style: React.CSSProperties = {
    position: "fixed",
    left: x + "px",
    top: y + "px",
    width: "5px",
    height: "5px",
    borderRadius: "50%",
    backgroundColor: "black",
    zIndex: "9999",
    pointerEvents: "none",
  };

  return <div style={style}></div>;
}

function getLineProps({ x1, x2, y1, y2 }: { x1: number; x2: number; y1: number; y2: number }) {
  const dx = x2 - x1;
  const dy = y2 - y1;

  const alpha = Math.atan2(dy, dx);

  const a = dy / dx;
  const b = (y1 + y2 - a * (x1 + x2)) / 2;

  const width = Math.sqrt(dx * dx + dy * dy);

  const f = (_x: number) => {
    return a * _x + b;
  };

  return { dx, dy, alpha, width, f, a };
}

type LineProps = ReturnType<typeof getLineProps>;

function createLineEl(params: { x1: number; y1: number }, lineProps: LineProps, useDebug: boolean) {
  const { alpha, width } = lineProps;

  const style: React.CSSProperties = {
    position: "fixed",
    left: params.x1 + "px",
    top: params.y1 + "px",
    width: width + "px",
    transform: `rotate(${alpha}rad)`,
    transformOrigin: "center left",
    height: "2px",
    backgroundColor: "red",
    zIndex: "9999",
    pointerEvents: "none",
  };

  return (
    <div style={style}>
      {useDebug && (
        <span className="u-absolute u-top u-left">
          x: {params.x1}, y: {params.y1}
        </span>
      )}
    </div>
  );
}
