import React from "react";

import { css } from "@emotion/react";

import { ReactTFC } from "../../utils/fp/missing-ts-parts";
import { flagsForCountries, Lazy_OtherCountriesFlag } from "./lazy-flags";

const getStaticKeys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];
const supportedCountries = getStaticKeys(flagsForCountries);

export function getSvgIconForCountryIsoCode(countryIsoCode: string) {
  const upperCasedCountryIsoCode = countryIsoCode.toUpperCase();

  if (supportedCountries.includes(upperCasedCountryIsoCode as any)) {
    //@ts-expect-error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return flagsForCountries[upperCasedCountryIsoCode];
  } else {
    return Lazy_OtherCountriesFlag;
  }
}

const styles = css`
  display: block;

  --size: 30px;

  width: var(--size);
  height: var(--size);

  border-radius: 100%;
`;

export const LanguagePickerCountryFlag: React.FC<{
  countryIsoCode: string;
  className?: string;
  fallback?: React.JSX.Element;
}> = props => {
  const Component = getSvgIconForCountryIsoCode(props.countryIsoCode);

  return (
    <React.Suspense fallback={props.fallback || IconPlaceholder}>
      <Component css={styles} className={props.className} />
    </React.Suspense>
  );
};

const IconPlaceholder = <div css={styles} />;
