import React, { useCallback, useMemo } from "react";
import { PaneContentState, PickersPaneContentState, pickersPaneContent } from "./PaneContentState";
import { Segments } from "./props";
import { createStates } from "./createStates";
import { nonEmpty, type Link } from "@swo/shared";
import { HeaderT } from "../HeaderT";
import { NewHeaderData } from "@swo/storefront";

export function useActions(
  setPaneContent: React.Dispatch<React.SetStateAction<PaneContentState>>,
  segments: Segments | null,
  cart: { link: string; data: NewHeaderData["cart"] } | null,
  t: HeaderT,
) {
  const states = useMemo(() => createStates(segments, cart, t), [segments, cart, t]);
  const { dealersState, searchState, accountState, segmentsState, cartState, languagePickerStateState } = states;

  const togglePaneContent = useCallback((newState: PickersPaneContentState) => {
    setPaneContent(currentState =>
      currentState?.tag === "pickers" && currentState.content?.tag === newState.tag
        ? currentState
        : pickersPaneContent(newState),
    );
  }, []);

  const onCartClicked = useCallback(() => {
    cartState ? togglePaneContent(cartState) : null;
  }, [cartState, togglePaneContent]);

  const onDealerLocatorClicked = useCallback(() => {
    togglePaneContent(dealersState);
  }, [dealersState, togglePaneContent]);

  const onSearchClicked = useCallback(() => {
    togglePaneContent(searchState);
  }, [searchState, togglePaneContent]);

  const onAccountClicked = useCallback(() => {
    togglePaneContent(accountState);
  }, [togglePaneContent, accountState]);

  const onLanguagePickerClicked = useCallback(() => {
    togglePaneContent(languagePickerStateState);
  }, [togglePaneContent, languagePickerStateState]);

  const onSegmentButtonClicked = useCallback(() => {
    return segmentsState ? togglePaneContent(segmentsState) : undefined;
  }, [togglePaneContent, segmentsState]);

  return {
    actions: {
      onCartClicked,
      onDealerLocatorClicked,
      onSearchClicked,
      onAccountClicked,
      onLanguagePickerClicked,
      onSegmentButtonClicked,
    },
    states: Object.values(states).filter(nonEmpty),
  };
}

export function segmentPickerState(segments: Segments, t: HeaderT) {
  return pickersPaneContent({
    tag: "segment-picker",
    segments: segments,
    title: t("navigation.segmentsTitle"),
    theme: "dark",
    width: "standard",
  });
}

export type Actions = ReturnType<typeof useActions>;
