import React from "react";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { ChevronDoubleRight, ChevronDoubleLeft } from "../../../assets";
import cn from "classnames";

export const PaneCloseNavigationButton: React.FC<{ side: "left" | "right"; onClose: () => void }> = props => {
  return (
    <div
      className={cn(`pane-${props.side}`)}
      css={css`
        position: absolute;

        z-index: 10;

        svg {
          color: ${CSS_COLORS.Green};
        }

        .background-dark & {
          svg {
            color: ${CSS_COLORS.GreenDark};
          }
        }

        width: var(--close-pane-width);

        height: 100%;
        top: 0;

        &.pane-left {
          left: calc(var(--pane-width) - var(--close-pane-width) + 1px);

          button {
            left: -1px;
          }
        }

        &.pane-right {
          right: calc(var(--pane-width) - var(--close-pane-width));

          button {
            left: 0;
          }
        }

        button {
          position: relative;

          top: 19px;
        }

        button:hover {
          svg {
            color: ${CSS_COLORS.GreenDark};
          }
        }

        display: none;
        .active & {
          display: block;
        }
      `}
    >
      <button onClick={props.onClose} className={`side-${props.side}`}>
        <svg width="18" height="57" viewBox="0 0 12 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          {props.side === "left" ? (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.3465 5.3115C3.23556 6.37087 0 7.47267 0 11.7399V13.8182V26.3409V26.672C0 30.7895 3.23556 31.8527 6.3465 32.8749C9.22666 33.8212 12 34.7325 12 38V24.4645V6.47727V0C12 3.38631 9.22666 4.33072 6.3465 5.3115Z"
              fill="currentColor"
            />
          ) : (
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.6535 5.3115C8.76444 6.37087 12 7.47267 12 11.7399V16.2803V26.3409V26.672C12 30.7895 8.76444 31.8527 5.6535 32.8749C2.77334 33.8212 0 34.7325 0 38V24.4645V8.20455V0C0 3.38631 2.77334 4.33072 5.6535 5.3115Z"
              fill="currentColor"
            />
          )}
        </svg>

        <span
          css={css`
            position: absolute;

            .side-left & {
              left: 5px;
            }
            .side-right & {
              left: 3px;
            }

            top: 19px;
          `}
        >
          {props.side === "left" ? <ChevronDoubleLeft /> : <ChevronDoubleRight />}
        </span>
      </button>
    </div>
  );
};
