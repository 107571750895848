import { css } from "@emotion/react";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { CSS_MQ } from "../../styles/breakpoints";

export const navigationButtonStyles = css`
  position: relative;

  --padding: 20px;

  --nav-size: calc(20px + 2 * var(--padding));

  height: var(--nav-size);
  width: var(--nav-size);

  position: relative;

  --after-padding: 8px;
  &.flag-button {
    --after-padding: 13px;
  }

  &:after {
    content: "";
    position: absolute;

    top: var(--after-padding);
    left: var(--after-padding);

    width: calc(100% - 2 * var(--after-padding));
    height: calc(100% - 2 * var(--after-padding));

    border-radius: 50%;
    // transition: background-color 0.2s linear;
  }

  &.active {
    .dark & {
      --icon-color: ${CSS_COLORS.Green};

      &:after {
        background-color: ${CSS_COLORS.White};
      }
    }

    .light & {
      --icon-color: ${CSS_COLORS.White};

      &:after {
        background-color: ${CSS_COLORS.Green};
      }
    }

    &.flag-button {
      .light & {
        &:after {
          background-color: ${CSS_COLORS.Black};
        }
      }
    }
  }

  &:not(.show-mobile) {
    ${CSS_MQ.atMostSm} {
      display: none;
    }
  }

  &.hide-desktop {
    ${CSS_MQ.atLeastMd} {
      display: none;
    }
  }

  svg {
    padding: 16px;
    width: 100%;
    height: auto;
    object-fit: contain;

    color: var(--icon-color, inherit);

    position: relative;
    z-index: 1;
  }

  --t: calc(-50% + 0.5 * var(--nav-size));

  ._navigation-title {
    filter: opacity(0);

    ${CSS_MQ.atMostSm} {
      display: none;
    }

    &.stick-center {
      transform: translate(var(--t), -0.7em);
    }

    &.stick-right {
      transform: translate(0, -0.7em);
    }

    transition:
      filter 0.12s linear,
      transform 0.12s 0.05s linear;
  }

  &:hover,
  &.focus-visible {
    ._navigation-title {
      filter: opacity(1);

      &.stick-center {
        transform: translate(var(--t), 0);
      }

      &.stick-right {
        transform: translate(0, 0);
      }
    }
  }
`;
