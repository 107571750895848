import styled from "@emotion/styled";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { CSS_MQ } from "../../../styles/breakpoints";
import { fontColor, FONTS, fontSize } from "../../../styles/fonts";
import { css } from "@emotion/react";

export const PageHeadline = styled.h1<{ align?: "left" | "center" }>`
  ${FONTS.SwarovskiFutura300};
  ${fontSize.x6s};

  color: ${CSS_COLORS.Green};

  text-transform: uppercase;
  text-align: ${props => (props.align === "left" ? "left" : "center")};
`;

export const PageHeadlineMedium = styled.span<{ align?: "left" | "center" }>`
  ${FONTS.MinervaModern500};

  color: ${CSS_COLORS.Green};
  text-align: center;
  text-transform: uppercase;

  font-size: 24px;
  letter-spacing: 0.4px;
  line-height: 26px;

  text-align: ${props => (props.align === "left" ? "left" : "center")};
  display: block;

  ${CSS_MQ.atLeastMd} {
    font-size: 30px;
    letter-spacing: 1.5px;
    line-height: 30px;
  }
`;

export const PageMiniHeadline = styled.span(
  FONTS.SwarovskiFutura500,
  fontColor.black,
  fontSize.x2,
  css`
    text-transform: uppercase;
  `,
);
