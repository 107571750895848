import { SwarovskiOptikLocaleFrontend, TypedMoney, type TranslatedValue } from "@swo/storefront";
import { showPrice } from "@swo/shared";
import { mapTranslatedValue, tObj } from "../../../../components/T";

export function formatStartingPrice(
  price: TypedMoney,
  fromPriceLabelTranslation: TranslatedValue,
  localeForNumberFormat: SwarovskiOptikLocaleFrontend,
  avoidDecimalsIfPossible?: boolean,
): string {
  return formatStartingPriceWithTwoElements(
    price,
    fromPriceLabelTranslation,
    localeForNumberFormat,
    avoidDecimalsIfPossible,
  ).join(" ");
}
export function formatStartingPriceWithTwoElements(
  price: TypedMoney,
  fromPriceLabelTranslation: string | TranslatedValue,
  localeForNumberFormat: SwarovskiOptikLocaleFrontend,
  avoidDecimalsIfPossible?: boolean,
): [string, string] {
  const fromPriceLabelTranslationStr =
    typeof fromPriceLabelTranslation === "string" ? fromPriceLabelTranslation : fromPriceLabelTranslation.value;

  return [fromPriceLabelTranslationStr, showPrice(price, localeForNumberFormat, avoidDecimalsIfPossible)];
}

export function formatOptionalStartingPrice(
  price: TypedMoney | undefined,
  fromPriceLabelTranslation: TranslatedValue,
  localeForNumberFormat: SwarovskiOptikLocaleFrontend,
  avoidDecimalsIfPossible?: boolean,
) {
  if (typeof price === "undefined") {
    return mapTranslatedValue(fromPriceLabelTranslation, _ => `_ -`);
  } else {
    return formatStartingPrice(price, fromPriceLabelTranslation, localeForNumberFormat, avoidDecimalsIfPossible);
  }
}
