import { useCallback, useMemo } from "react";
import { getLocaleWithInternationalFallback } from "@swo/shared";
import { formatDateEventDateForLocale } from "../../../domain/swarovski-optik/events";
import { usePageContext } from "../../../global/page-context";
import type { EventT } from "@swo/salesforce-service";

type EventDateT = EventT["startDate"] | EventT["endDate"];

export function useFormatters() {
  const { pageBase } = usePageContext();

  const realLocaleCode = useMemo(() => getLocaleWithInternationalFallback(pageBase.locale), [pageBase.locale.code]);

  const formatDateEventDateForLocale_ = useCallback(
    (date: EventDateT) => formatDateEventDateForLocale(realLocaleCode, date),
    [realLocaleCode],
  );

  return {
    formatDateEventDateForLocale: formatDateEventDateForLocale_,
  };
}
