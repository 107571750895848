import React from "react";
import { css } from "@emotion/react";
import { useStore } from "../../../../store/hooks/use-store";
import { SwarovskiButton } from "../../../_shared-components/ui-elements/SwarovskiButton";
import { AppStoreUserAspectLoggedInState } from "../../../../store/app-store/app-store-ctx-provider";
import { MyAccountDropdownProps, textStyle, textAlignCenter } from "./MyAccountDropdown";
import { SmallUnstyledLoadingSpinner } from "../../../../routes/modal-loading-spinner";
import { T } from "../../../T";

export const LoggedInUser: React.FC<MyAccountDropdownProps & { state: AppStoreUserAspectLoggedInState }> = ({
  t,
  userLinks,
  state,
}) => {
  const { dispatch } = useStore();

  function logoutRequested() {
    dispatch({ type: "user-logged-out" });
  }

  if (state.userProfileSf.__tag !== "loaded") {
    return <SmallUnstyledLoadingSpinner />;
  }

  const userProfileSfData = state.userProfileSf.data;

  return (
    <>
      <div
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {userProfileSfData.name && <a css={[textStyle]}>{userProfileSfData.name}</a>}
        <span css={textStyle}>{userProfileSfData.email}</span>
      </div>

      <div
        css={css`
          display: grid;
          grid-gap: 10px;
        `}
      >
        <SwarovskiButton
          css={[
            ...textStyle,
            css`
              display: flex;
              align-items: center;
            `,
          ]}
          as={"a"}
          link={userLinks.profile}
          isInternal={false}
          target={"_blank"}
          variant={"green"}
          size={"small"}
        >
          <span css={textAlignCenter}>MyService</span>
        </SwarovskiButton>

        <SwarovskiButton onClick={logoutRequested} css={textStyle} as={"button"} variant={"outline"} size={"small"}>
          <T>{t("meta.logout")}</T>
        </SwarovskiButton>
      </div>
    </>
  );
};
