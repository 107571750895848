import { css } from "@emotion/react";
import type { EventDocument } from "@swo/storefront";
import type React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { T } from "../T";
import { SearchResultSummary } from "./SearchResultSummary";
import { SearchTag } from "./SearchTag";
import { Link } from "react-router";
import { transformImageIfFromContentful_jpg } from "../../domain/contentful/transform-image-if-from-contentful";

export const EventResult: React.FC<{ event: EventDocument; t: SearchT }> = ({ event, t }) => {
  const label = t("search.results.others.tag.event");

  return (
    <Link
      to={event.path}
      className="u-d-block u-no-decoration"
      css={css`
        &:hover {
          ._text {
            text-decoration: underline;
          }
        }
      `}
    >
      <div
        css={css`
          aspect-ratio: 16/10;
          background-color: ${CSS_COLORS.White};
          color: ${CSS_COLORS.Green};

          position: relative;
        `}
      >
        <div className="u-d-flex u-jc-center u-ai-center u-h-full">
          <img
            src={transformImageIfFromContentful_jpg(event.imageUrl, 300)}
            alt={event.name}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </div>

        <SearchTag>
          <T>{label}</T>
        </SearchTag>
      </div>

      <SearchResultSummary title={event.name} description={event.description} t={t} />
    </Link>
  );
};
