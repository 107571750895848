import type React from "react";
import type { SearchMetadata, SearchState } from "./MainNavigationSearchComponent";
import { ResponseView } from "./ResponseView";

import { useState } from "react";
import isEqual from "react-fast-compare";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import type { OthersSearchRequestQuery } from "@swo/storefront";
import type { LoadingState } from "../../domain/data-layer/loading-state";
import { SearchHeadline } from "./SearchHeadline";
import { SearchNoResultsAtAll, SearchResultsError } from "./SearchNoResultsAtAll";

export const SearchResultsComponent: React.FC<{
  resultsLs: LoadingState<SearchState>;
  children: React.ReactNode;
  onLoadMore: (type: "products" | "others") => void;
  t: SearchT;
  quickLinks: React.ReactNode;
  activeTag: OthersSearchRequestQuery["type"];
  setActiveTag: (tag: OthersSearchRequestQuery["type"]) => void;
  searchMetadata: SearchMetadata;
}> = ({ resultsLs, children, onLoadMore, t, quickLinks, activeTag, setActiveTag, searchMetadata }) => {
  const [previousState, setPreviousState] = useState<SearchState | null>(null);

  switch (resultsLs.__tag) {
    case "loading": {
      const fallback =
        previousState && (previousState.response.products || previousState.response.others) ? (
          <ResponseView
            result={previousState}
            onLoadMore={onLoadMore}
            t={t}
            quickLinks={quickLinks}
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            searchMetadata={searchMetadata}
          >
            {children}
          </ResponseView>
        ) : (
          <div className="u-mt-2">
            <SearchHeadline isLoading={true}>{""}</SearchHeadline>
          </div>
        );

      return fallback;
    }

    case "loaded": {
      if (!isEqual(resultsLs.data, previousState)) {
        setPreviousState(resultsLs.data);
      }

      return (
        <ResponseView
          result={resultsLs.data}
          onLoadMore={onLoadMore}
          t={t}
          quickLinks={quickLinks}
          activeTag={activeTag}
          setActiveTag={setActiveTag}
          searchMetadata={searchMetadata}
        >
          {children}
        </ResponseView>
      );
    }
    case "error": {
      return <SearchResultsError t={t} />;
    }
    default: {
      const _: never = resultsLs;
      return _;
    }
  }
};
