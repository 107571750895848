export type LoadingStateLoading = {
  __tag: "loading";
};

export type LoadingState<T, E = unknown> = LoadingStateLoading | LoadedState<T, E>;

export type SuccessfullyLoadedState<T> = {
  __tag: "loaded";
  data: T;
};

export type FailureLoadedState<E = unknown> = {
  __tag: "error";
  error: E;
};

export type LoadedState<T, E = unknown> = SuccessfullyLoadedState<T> | FailureLoadedState<E>;
export type LoadingOrLoaded<T> = SuccessfullyLoadedState<T> | LoadingStateLoading;

export const LoadingStateOps = {
  asLoaded<T, E = unknown>(data: T): LoadingState<T, E> {
    return {
      __tag: "loaded",
      data,
    };
  },

  asLoaded_<T, E = unknown>(data: T): SuccessfullyLoadedState<T> {
    return {
      __tag: "loaded",
      data,
    };
  },

  asFailed<T = any, E = unknown>(error: E): LoadingState<T, E> {
    return {
      __tag: "error",
      error: error,
    };
  },

  loading<T, E = unknown>(): LoadingState<T, E> {
    return LoadingStateOps.loadingS;
  },

  loadingS: {
    __tag: "loading" as const,
  },

  both<A, B>(one: LoadingState<A>, next: LoadingState<B>): LoadingState<readonly [A, B]> {
    if (one.__tag === "loading" || next.__tag === "loading") {
      return this.loadingS;
    } else if (one.__tag === "error" || next.__tag === "error") {
      return {
        __tag: "error",
        error: [...(one.__tag === "error" ? [one.error] : []), ...(next.__tag === "error" ? [next.error] : [])],
      };
    } else {
      return {
        __tag: "loaded",
        data: [one.data, next.data],
      };
    }
  },

  map<A, B>(fa: LoadingState<A>, m: (a: A) => B): LoadingState<B> {
    if (fa.__tag === "loaded") {
      return {
        __tag: "loaded",
        data: m(fa.data),
      };
    } else {
      return fa;
    }
  },

  flatMap<A, B>(fa: LoadingState<A>, m: (a: A) => LoadingState<B>): LoadingState<B> {
    if (fa.__tag === "loaded") {
      return m(fa.data);
    } else {
      return LoadingStateOps.loadingS;
    }
  },

  flatten<A, B>(fa: LoadingState<A | undefined>): LoadingState<A> {
    if (fa.__tag === "loaded") {
      if (typeof fa.data !== "undefined") {
        return LoadingStateOps.asLoaded(fa.data);
      } else {
        return LoadingStateOps.asFailed(fa.data);
      }
    } else {
      return LoadingStateOps.loadingS;
    }
  },

  pureOfNullable<A>(a: A | undefined): LoadingState<A> {
    if (typeof a === "undefined") {
      return LoadingStateOps.asFailed(undefined);
    } else {
      return LoadingStateOps.asLoaded(a);
    }
  },

  toNullable<A>(ls: LoadingState<A>): A | undefined {
    if (ls.__tag === "loaded") {
      return ls.data;
    } else {
      return undefined;
    }
  },
};
