export const SEARCH_QUERY_URL_PARAM = "q";
export const SEARCH_QUERY_LATLNG_URL_PARAM = "l";

export const SEARCH_QUERY_GROUPS = "g";
export const SEARCH_QUERY_CLICK_AND_COLLECT = "c";

export const SEARCH_QUERY_DEALER_ID = "dealerid";

export const precision = 7;
export const separator = "_";
