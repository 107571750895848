import { HeaderProductCategoriesNavT } from "@swo/storefront";
import React from "react";
import { Links } from "./Links";
import { Wrapper } from "./Wrapper";
import { nonEmptyArray as NEA } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { css } from "@emotion/react";

type Props = {
  content: HeaderProductCategoriesNavT;
};

export const ProductCategoriesUniversalElement = React.forwardRef<HTMLDivElement, Props>(({ content }, ref) => {
  const { categories } = content;

  return (
    <div
      className="u-w-full u-overflow-x-hidden u-flex u-flex-col u-jc-between u-grow so-scrollbar-y"
      css={css`
        overflow-y: auto;
        padding-bottom: 20px;
      `}
    >
      <Wrapper>
        <Links
          ref={ref}
          links={categories.map(c => {
            const children = pipe(
              c.families,
              NEA.map(f => ({
                link: f.link,
                isNew: f.isNew,
              })),
            );

            return {
              link: c.link,
              isNew: c.isNew,
              children,
            };
          })}
        />
      </Wrapper>
    </div>
  );
});
