import React, { useRef } from "react";
import { ExtractedLineItemData, SwarovskiOptikClientCartLineItem } from "@swo/storefront";
import { CartScrollableLineItemsList } from "./MenuMetaComponent/ShoppingCart/CartScrollableLineItemsList";

export const CartLineItems: React.FC<{
  lineItems: Array<SwarovskiOptikClientCartLineItem & ExtractedLineItemData>;
  languageIsoCode: string;
  onLineItemRemovalRequested: (lineItemId: string) => Promise<void>;
}> = ({ lineItems, languageIsoCode, onLineItemRemovalRequested }) => {
  return (
    <CartScrollableLineItemsList
      lineItems={lineItems}
      languageIsoCode={languageIsoCode}
      onLineItemRemovalRequested={onLineItemRemovalRequested}
    />
  );
};
