import { DealerWithLinkT } from "@swo/storefront";
import { DealerInfoSummary, GetDealersByLocationEffectResponse } from "@swo/storefront";
import { fetchApi } from "../../../data-layer/fetch-api";
import { optionsGetJson } from "../../options";

export interface DealerLocatorClientI {
  getDealersSummaryInBounds(bounds: {
    east: number;
    north: number;
    south: number;
    west: number;
  }): Promise<Array<DealerWithLinkT>>;

  getDealersSummaryForCountry(countryIsoCode: string): Promise<Array<DealerWithLinkT>>;
  getDealersInVicinity(location: google.maps.LatLngLiteral): Promise<GetDealersByLocationEffectResponse>;
}

export const dealerLocatorClient: (context: { localeCode: string; segmentKey: string }) => DealerLocatorClientI = ({
  localeCode,
  segmentKey,
}) => {
  return {
    getDealersSummaryForCountry: (countryIsoCode: string) => {
      const params = new URLSearchParams({
        localeCode: localeCode,
        segmentKey: segmentKey,
        countryIsoCode: countryIsoCode,
      });

      return fetchApi(`/storefront/dealers?${params.toString()}`, optionsGetJson);
    },
    getDealersSummaryInBounds: (bounds: { north: number; south: number; west: number; east: number }) => {
      const params = new URLSearchParams({
        localeCode: localeCode,
        segmentKey: segmentKey,
        north: bounds.north.toString(),
        south: bounds.south.toString(),
        west: bounds.west.toString(),
        east: bounds.east.toString(),
      });

      return fetchApi<Array<DealerWithLinkT>>(`/storefront/dealers/by-bounds?${params.toString()}`, optionsGetJson);
    },

    getDealersInVicinity: (location: google.maps.LatLngLiteral) => {
      const params = new URLSearchParams({
        localeCode: localeCode,
        segmentKey: segmentKey,
        lat: location.lat.toString(),
        lng: location.lng.toString(),
        addressVerified: "true",
      });

      return fetchApi(`/storefront/dealers/by-location?${params.toString()}`, optionsGetJson);
    },
  };
};
