import React, { CSSProperties } from "react";
import { Link } from "react-router";

import { SerializedStyles } from "@emotion/react";
import { css } from "@emotion/react";
import { Path } from "react-router";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { UnwrapReactComponent } from "../../../utils/fp/missing-ts-parts";
import cn from "classnames";
import { removeLeadingQuestionMark } from "../../DealerLocatorComponent/state/use-sync-to-url";
import { Link as SOLink } from "@swo/shared";
import { preload } from "react-dom";
import { STOREFRONT_PATH_PREFIX } from "../../../const";

export type SwarovskiLinkLinkTypeProps =
  // In practise, it's impractical to use true/false here
  | { isInternal: boolean /* true */; scrollToTop: boolean; expectSimilarLayout?: boolean }
  | { isInternal: boolean /* false */; openInNewTab?: boolean };

export type SwarovskiLinkData = {
  pathname: string;
  search?: string | undefined;
  state?: object;
  hash?: string;
};

// not exported from react router
type PrefetchBehavior = "intent" | "render" | "none" | "viewport";

export type SwarovskiLinkProps = {
  link: SwarovskiLinkData | string;
  styled?: "underline" | "underline-plain" | "no-decoration";
  upperCased?: boolean;
  attachStyles?: SerializedStyles | Array<SerializedStyles>;
  className?: string;
  onClick?: () => void;
  title?: string;
  style?: CSSProperties;
  disabled?: boolean;
  prefetch?: PrefetchBehavior;
} & SwarovskiLinkLinkTypeProps;

export const SwarovskiLinkLink: React.FC<
  {
    link: SOLink;
  } & Omit<SwarovskiLinkProps, "isInternal" | "link" | "openInNewTab">
> = ({ link: { data, label, link, type, openInNewTab }, ...props }) => {
  return (
    <SwarovskiLink {...props} isInternal={type === "Internal Link"} link={data} openInNewTab={openInNewTab}>
      {label}
    </SwarovskiLink>
  );
};

export const SwarovskiLink: React.FC<React.PropsWithChildren<SwarovskiLinkProps>> = props => {
  const className = cn([props.styled, { "upper-cased": props.upperCased }, props.className]);
  const css1 = [linkCss, props.attachStyles];

  if (props.disabled) {
    return (
      <span
        css={[
          ...css1,
          css`
            cursor: not-allowed;
          `,
        ]}
        className={className}
        onClick={props.onClick}
        style={props.style}
      >
        {props.children}
      </span>
    );
  }

  const scrollToTop = "scrollToTop" in props ? props.scrollToTop : false;
  const expectSimilarLayout = "expectSimilarLayout" in props ? props.expectSimilarLayout : false;

  if (props.isInternal) {
    const { link, state } = getToLink(props.link, scrollToTop, !!expectSimilarLayout);

    return (
      <Link
        to={link}
        css={css1}
        className={className}
        onClick={props.onClick}
        title={props.title}
        style={props.style}
        state={state}
        onMouseEnter={() => {
          if (props.prefetch && link.pathname) {
            preload(`${STOREFRONT_PATH_PREFIX}${link.pathname}`, { as: "script", fetchPriority: "high" });
          }
        }}
        // prefetch={props.prefetch}
      >
        {props.children}
      </Link>
    );
  } else {
    const target = "openInNewTab" in props && props.openInNewTab ? "_blank" : undefined;

    return (
      <a
        href={typeof props.link === "string" ? props.link : asStringLink(props.link)}
        css={css1}
        className={className}
        target={target}
        onClick={props.onClick}
        rel="noopener noreferrer"
        style={props.style}
      >
        {props.children}
      </a>
    );
  }
};

function getToLink(
  l: UnwrapReactComponent<typeof SwarovskiLink>["link"],
  scrollToTop: boolean,
  expectSimilarLayout: boolean,
): { link: Partial<Path>; state: any } {
  const state = { scrollToTop: scrollToTop, expectSimilarLayout: expectSimilarLayout };

  if (typeof l === "string") {
    return { link: { pathname: l }, state: state };
  } else {
    return { link: { pathname: l.pathname, search: l.search }, state: { ...l.state, ...state } };
  }
}

export function asStringLink({
  pathname,
  search,
  hash,
}: {
  pathname: string;
  search?: string | undefined;
  hash?: string | undefined;
}): string {
  if (!search) {
    return pathname;
  }

  let ret = `${pathname}?${removeLeadingQuestionMark(search)}`;

  if (hash) {
    if (hash.startsWith("#")) {
      ret += `${hash}`;
    } else {
      ret += `#${hash}`;
    }
  }

  return ret;
}

const linkCss = css`
  cursor: pointer;

  &.underline {
    color: ${CSS_COLORS.Green};
    text-transform: uppercase;

    // This is displayed very weirdly sometimes - no lines, multiple lines, lol?
    text-underline-position: under;
    text-underline-offset: 1px;

    font-size: 18px;
  }

  &.underline-plain {
    color: ${CSS_COLORS.Green};
    text-transform: uppercase;

    text-decoration: underline;

    font-size: 18px;
  }

  &.no-decoration {
    text-decoration: none;
  }

  &.upper-cased {
    text-transform: uppercase;
  }
`;
