import React from "react";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import { MobilePrimaryAndSecondaryPaneContainer } from "./MobilePrimaryAndSecondaryPaneContainer";
import { GoBackButton } from "./GoBackButton";
import { innermostPaneStyles, styles } from "./InternalPane";
import cn from "classnames";
import { T } from "../../T";

export const MobilePrimaryContentPane: React.FC<
  React.PropsWithChildren<{ content: HeaderPrimaryNavigationElemT; goBack: () => void; isActive: boolean }>
> = ({ content, goBack, isActive, children }) => {
  return (
    <>
      <GoBackButton onClick={goBack} isActive={isActive}>
        <T>{content.label}</T>
      </GoBackButton>

      <div
        className={cn("js-innermost-pane u-absolute u-left u-flex u-flex-col", { active: isActive })}
        css={innermostPaneStyles}
      >
        <MobilePrimaryAndSecondaryPaneContainer
          className={content.type === "Service" ? "no-margin" : undefined}
          attachStyles={styles}
          primary={children}
          secondary={null}
        />
      </div>
    </>
  );
};
