import {
  sparePartsCategoryFinder,
  sparePartsProductFinder,
  sparePartsSubfamilyFinder,
} from "./spare-parts-category-finder";
import { SparePartsFinderStorefrontClientI } from "./spare-parts-storefront-client-i";
import { useMemo } from "react";

export const sparePartsStorefrontClient: (context: {
  localeCode: string;
  segmentKey: string;
}) => SparePartsFinderStorefrontClientI = context => {
  return {
    forCategory: data => sparePartsCategoryFinder(context, data),
    forSubfamily: data => sparePartsSubfamilyFinder(context, data),
    forProducts: data => sparePartsProductFinder(context, data),
  };
};

export function useSparePartsClient(context: { localeCode: string; segmentKey: string }) {
  return useMemo(() => sparePartsStorefrontClient(context), [context.localeCode, context.segmentKey]);
}
