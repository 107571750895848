// https://www.youtube.com/watch?v=YR5WdGrpoug
import { Option } from "fp-ts/es6/Option";
import { option } from "fp-ts";

export type Maybe<T> = T | undefined;

export function optToMaybe<T>(opt: Option<T>): Maybe<T> {
  // compatibility layer between Maybe<T> and fp-ts
  // one should be ditched eventually

  return option.fold(
    () => undefined,
    (some: T) => some,
  )(opt);
}

export type UnionTypeFromTuple<T extends readonly any[]> = T[number];

export function nonEmpty<T>(t: T | undefined | null): t is T {
  return typeof t !== "undefined" && t !== null;
}
