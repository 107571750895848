import { useMemo } from "react";
import type { TranslatedValue, TranslationsScopes } from "@swo/storefront";

export type TranslationScopeKey = TranslationsScopes;
export type TranslationScopeOrScopes = Array<TranslationScopeKey> | TranslationScopeKey;

function t<Data extends { [key: string]: TranslatedValue }>(data: Data, key: keyof Data & string): TranslatedValue {
  function decorated(str: string) {
    return `_${str}_`;
  }

  const translatedField = data[key]; //?.value?.trim();

  if (!translatedField) {
    return { value: decorated(key), key: undefined, type: "fallback" };
  }

  return translatedField;
}

export function t2<Data extends { [key: string]: TranslatedValue }>(data: Data) {
  type Key = keyof Data & string;

  return (key: Key) => {
    return t(data, key);
  };
}

export type Translated<Data extends { [key: string]: TranslatedValue }> = ReturnType<typeof t2<Data>>;

export function useT2<Data extends { [key: string]: TranslatedValue }>(data: Data) {
  return useMemo(() => t2(data), [data]);
}
