import { css } from "@emotion/react";
import { HeaderEventsNavT } from "@swo/storefront";
import React from "react";
import { unlabeledInternalLinkOf } from "../../../domain/swarovski-optik/links";
import { PageListWithOverview } from "./PageListWithOverview";
import { useFormatters } from "./use-event-date";
import { CSS_MQ } from "../../../styles/breakpoints";

type Props = {
  content: HeaderEventsNavT;
};

export const EventsUniversalElement = React.forwardRef<HTMLDivElement, Props>(({ content }, ref) => {
  const { formatDateEventDateForLocale } = useFormatters();

  return (
    <div
      ref={ref}
      css={css`
        ${CSS_MQ.atLeastMd} {
          margin-top: var(--margin-top);
        }
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        ${CSS_MQ.atMostSm} {
          flex-grow: 1;
        }
      `}
    >
      <PageListWithOverview
        list={content.events.map(s => ({
          link: s.link,
          primaryHeadline: s.headline,
          secondaryHeadline: undefined,
          additionalText: formatDateEventDateForLocale(s.startDate),
        }))}
        overview={{
          linkData: unlabeledInternalLinkOf({ pathname: content.allEvents.link }).data,
          label: content.allEvents.label,
        }}
        image={content.image}
      />
    </div>
  );
});
