import { flow } from "fp-ts/lib/function";
import { translateSize } from "./translate-size";
import { mergeXes } from "./merge-xs";
import type { VariantAttribute } from "../products";

export function mapToUserFriendlyLocalisedLabel(sizeKey: string, countryIsoCode: string): string {
  return flow(translateSize, mergeXes)(sizeKey, countryIsoCode);
}

export function getVariantAttributeLabel(
  attribute: VariantAttribute,
  translations: {
    yesLabel: string;
    noLabel: string;
  },
  countryIsoCode: string,
): string | undefined {
  switch (attribute.type) {
    case "clothing-size": {
      return mapToUserFriendlyLocalisedLabel(attribute.value.data, countryIsoCode);
    }
    case "reticle": {
      return attribute.value?.reticleKey;
    }
    case "color": {
      return attribute.value?.label;
    }
    case "price-only": {
      return attribute.value.label;
    }
    case "product": {
      return undefined;
    }
    case "product variant": {
      return attribute.value.name;
    }
    case "plain": {
      const v = attribute.value;
      if (!v) {
        return undefined;
      }

      switch (v.type) {
        case "enum-set": {
          return v.data.join(", ");
        }
        case "boolean": {
          return v.data ? translations.yesLabel : translations.noLabel;
        }
        case "enum": {
          return v.data.label;
        }
        case "number": {
          return v.data.toString();
        }
        case "number-set": {
          return v.data.join(", ");
        }
        case "text-set": {
          return v.data.join(", ");
        }
        case "text": {
          return v.data;
        }
        case "reference": {
          return undefined;
        }
        default: {
          const _: never = v;
          return _;
        }
      }
    }
    default: {
      const _: never = attribute;
      return _;
    }
  }
}
