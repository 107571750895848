import { css } from "@emotion/react";
import { globalFonts } from "./globalFonts";
import { polishFonts } from "./polishFonts";

export const outlineStyles = css`
  outline-style: dotted;
  outline-width: 2px;
  outline-color: var(--focus-outline, var(--so-green));
`;

export function getGlobalFontStyles(languageIsoCode: string) {
  return languageIsoCode.toUpperCase() === "PL" ? polishFonts : globalFonts;
}
