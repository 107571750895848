import { DealerLocatorGroup } from "@swo/storefront";
import {
  SEARCH_QUERY_CLICK_AND_COLLECT,
  SEARCH_QUERY_DEALER_ID,
  SEARCH_QUERY_GROUPS,
  SEARCH_QUERY_LATLNG_URL_PARAM,
  separator,
} from "./config";
import { DealerLocatorAppState } from "./index";
import { LoadingStateWithIdleOps } from "../../../../domain/data-layer/loading-state-with-idle";
import { availableGroups } from "@swo/shared";
import { FORM_ELEMENTS } from "../../subcomponents/SearchForm/form-elements";
import { DealerDto } from "@swo/salesforce-service";

export function readUrlParams(params: URLSearchParams, countryIsoCode: string): DealerLocatorAppState {
  function readQuery(): DealerLocatorAppState["query"] {
    const l = params.get(SEARCH_QUERY_LATLNG_URL_PARAM);

    if (l) {
      try {
        const dirs = l.split(separator).map(i => Number.parseFloat(i));

        if (dirs.length === 4 && dirs.every(d => Number.isFinite(d) && d < 180)) {
          const [north, south, west, east] = dirs;

          //@ts-expect-error
          return { tag: "bounds" as const, value: { north, south, west, east } };
        } else {
          return {
            tag: "country" as const,
            countryIsoCode: countryIsoCode,
            bounds: LoadingStateWithIdleOps.idleS,
          };
        }
      } catch {
        return {
          tag: "country" as const,
          countryIsoCode: countryIsoCode,
          bounds: LoadingStateWithIdleOps.idleS,
        };
      }
    } else {
      return {
        tag: "country" as const,
        countryIsoCode: countryIsoCode,
        bounds: LoadingStateWithIdleOps.idleS,
      };
    }
  }

  const activeQuery = readQuery();

  const activeQueryGroups = params.getAll(SEARCH_QUERY_GROUPS) as Array<DealerDto["groups"][number]>;

  const queryGroups = getQueryGroups(activeQueryGroups);

  const activeClickAndCollect = !!params.get(SEARCH_QUERY_CLICK_AND_COLLECT);

  const selectedDealerId = params.get(SEARCH_QUERY_DEALER_ID);

  return {
    query: activeQuery,
    groups: queryGroups,
    clickAndCollect: activeClickAndCollect,
    selectedDealerId: selectedDealerId,
    input: {
      searchInputValue: "",
      groups: queryGroups,
      withClickAndCollect: activeClickAndCollect,
      suggestions: new Map(),
    },
    userLocation: LoadingStateWithIdleOps.idleS,
    filterExpanded: false
  };
}

export function getQueryGroups(activeQueryGroups: Array<DealerDto["groups"][number]>) {
  let acc = getCheckboxes(false);

  activeQueryGroups.forEach(activeQueryGroup => {
    const idx = acc.findIndex(v => v.name === activeQueryGroup);

    acc[idx] = { name: activeQueryGroup, value: true };
  });

  return acc;
}

function getCheckboxes(state: boolean) {
  return Object.entries(FORM_ELEMENTS).map(([_, name]) => ({ value: state, name: name }));
}
