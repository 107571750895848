import { css } from "@emotion/react";
import { CSS_COLORS } from "./styles-css-variables";
import { CSS_MQ } from "./breakpoints";

export const FONTS = {
  SwarovskiFutura700: css`
    font-family: SwarovskiFutura, Futura, sans-serif;
    font-weight: 700;
  `,
  SwarovskiFutura500: css`
    font-family: SwarovskiFutura, Futura, sans-serif;
    font-weight: 500;
  `,
  SwarovskiFutura300: css`
    font-family: SwarovskiFutura, Futura, sans-serif;
    font-weight: 300;
  `,
  MinervaModern500: css`
    font-family: MinervaModern, sans-serif;
    font-weight: 500;
  `,
  MinervaModernItalic500: css`
    font-family: MinervaModern, sans-serif;
    font-weight: 500;
    font-style: italic;
  `,
};

export const fontSize = {
  x1: css`
    font-size: 30px;
    letter-spacing: 1px;
    ${CSS_MQ.atLeastLg} {
      font-size: 48px;
      letter-spacing: 2px;
    }
  `,
  x2ss: css`
    font-size: 14px;
    line-height: 1.2;
    // letter-spacing: 0.9px;
    ${CSS_MQ.atLeastMd} {
      font-size: 15px;
    }
  `,
  x2s: css`
    font-size: 15px;
    line-height: 1.2;
    //letter-spacing: 0.9px;
    ${CSS_MQ.atLeastMd} {
      font-size: 16px;
    }
  `,
  x2: css`
    font-size: 16px;
    line-height: 1.2;
    //letter-spacing: 0.9px;
    ${CSS_MQ.atLeastMd} {
      font-size: 18px;
    }
  `,
  x25: css`
    font-size: 18px;
    line-height: 1.2;
    // letter-spacing: 0.9px;
    ${CSS_MQ.atLeastMd} {
      font-size: 21px;
    }
  `,
  x3: css`
    font-size: 21px;
    // letter-spacing: 0.35px;
    line-height: 26px;

    ${CSS_MQ.atLeastMd} {
      font-size: 24px;
      line-height: 28px;
      // letter-spacing: 1.5px;
    }
  `,
  x4: css`
    font-size: 24px;
    // letter-spacing: 1px;

    ${CSS_MQ.atLeastMd} {
      font-size: 30px;
      // letter-spacing: 1.5px;
    }
  `,
  x5: css`
    font-size: 24px;

    ${CSS_MQ.atLeastMd} {
      font-size: 32px;
    }
  `,
  x5d: css`
    font-size: 32px;

    ${CSS_MQ.atLeastMd} {
      font-size: 40px;
    }
  `,
  x5x: css`
    font-size: 24px;
    line-height: 1.2;

    ${CSS_MQ.atLeastMd} {
      font-size: 30px;
    }

    ${CSS_MQ.atLeastXl} {
      font-size: 48px;
    }
  `,
  x6: css`
    font-size: 30px;
    ${CSS_MQ.atLeastMd} {
      font-size: 48px;
    }
  `,
  x6s: css`
    font-size: 36px;
    ${CSS_MQ.atLeastMd} {
      font-size: 48px;
    }
  `,
  x7: css`
    font-size: 30px;
    line-height: 1.05;

    ${CSS_MQ.atLeastMd} {
      font-size: 45px;
    }

    ${CSS_MQ.atLeastXl} {
      font-size: 60px;
    }
  `,
};

export const fontColor = {
  green: css`
    color: ${CSS_COLORS.Green};
  `,
  darkGreen: css`
    color: ${CSS_COLORS.GreenDark};
  `,
  black: css`
    color: ${CSS_COLORS.Black};
  `,
  grey: css`
    color: ${CSS_COLORS.Grey3};
  `,
  white: css`
    color: ${CSS_COLORS.White};
  `,
  red: css`
    color: ${CSS_COLORS.RedCustom};
  `,
};

export const fontStyles = {
  headline1: css`
    ${FONTS.MinervaModern500};
    color: ${CSS_COLORS.Green};
    text-transform: uppercase;

    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 26px;
    ${CSS_MQ.atLeastLg} {
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 30px;
    }
  `,
  headline2: css`
    ${FONTS.SwarovskiFutura300};
    color: ${CSS_COLORS.Green};

    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 26px;
    ${CSS_MQ.atLeastLg} {
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 30px;
    }
  `,
  headline3: css`
    ${FONTS.SwarovskiFutura500};
    ${fontColor.black};

    font-size: 16px;
    letter-spacing: 0.8px;
    ${CSS_MQ.atLeastLg} {
      font-size: 18px;
      letter-spacing: 0.9px;
    }

    line-height: calc(21 / 18);
  `,
  headline4: css`
    ${FONTS.SwarovskiFutura300};
    ${fontColor.black};

    font-size: 16px;
    letter-spacing: 0.8px;
    ${CSS_MQ.atLeastLg} {
      font-size: 18px;
      letter-spacing: 0.9px;
    }

    line-height: calc(21 / 18);

    text-transform: uppercase;
  `,
  headline5: css`
    ${FONTS.SwarovskiFutura500};
    ${fontColor.black};

    font-size: 16px;
    // letter-spacing: 1px;
    display: block;
    text-transform: uppercase;

    ${CSS_MQ.atLeastLg} {
      font-size: 18px;
      // letter-spacing: 0.9px;
    }

    line-height: calc(21 / 18);
  `,
};
