import React from "react";

import { LoadingState } from "../../domain/data-layer/loading-state";
import { useFetchedWithPendingReference } from "./use-fetched-with-pending-reference";

export { useFetchedWithPendingReference } from "./use-fetched-with-pending-reference";
export { useFetchedConstant } from "./useFetchedConstant";
export { useAbortableFetched } from "./useAbortableFetched";

export function useFetched<T>(eff: () => Promise<T>, opts: { dataKey: string }): LoadingState<T> {
  const [state, _] = useFetchedWithPendingReference(eff, opts);

  return state.data;
}

type FetchedFold<T> = {
  onLoading: () => React.ReactElement;
  onLoaded: (data: T) => React.ReactElement;
  onError: (err: unknown) => React.ReactElement;
};

export function useAsyncValue<T>(eff: () => Promise<T>, opts: { dataKey: string }) {
  const f = useFetched(eff, opts);

  return {
    fold: (fetchedFoldF: FetchedFold<T>) => {
      switch (f.__tag) {
        case "loaded": {
          return fetchedFoldF.onLoaded(f.data);
        }
        case "loading": {
          return fetchedFoldF.onLoading();
        }
        case "error": {
          return fetchedFoldF.onError(f.error);
        }
      }
    },
  };
}
