import { useEqualState } from "./state/use-equal-state";
import { BREAKPOINTS, CSSBreakpoint } from "../styles/breakpoints";
import { useEffect } from "react";
import { useMountedRef } from "./use-mounted";

export function useCurrentWidth(): CSSBreakpoint | null {
  const isMounted = useMountedRef();
  const [state, setState] = useEqualState<CSSBreakpoint | null>(null);

  function getBreakpointFromSize(width: number): CSSBreakpoint {
    if (width < BREAKPOINTS.atLeastSm) {
      return "xs";
    } else if (window.innerWidth < BREAKPOINTS.atLeastMd) {
      return "sm";
    } else if (window.innerWidth < BREAKPOINTS.atLeastLg) {
      return "md";
    } else if (window.innerWidth < BREAKPOINTS.atLeastXl) {
      return "lg";
    } else {
      return "xl";
    }
  }

  function onResize() {
    if (isMounted.current) {
      setState(getBreakpointFromSize(window.innerWidth));
    }
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    onResize();
  }, []);

  return state;
}
