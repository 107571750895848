import { Theme } from "./PaneContentState";

export function getBackgroundClassName(theme: Theme) {
  switch (theme) {
    case "white": {
      return "background-white";
    }
    case "light": {
      return "background-light";
    }
    case "dark": {
      return "background-dark";
    }
    default: {
      const _: never = theme;
      return _;
    }
  }
}
