import React, { ReactNode } from "react";
import cn from "classnames";

import { css } from "@emotion/react";

import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { useBodyOverflowStyles } from "../../../hooks/use-body-overflow-styles-for";
import isEqual from "react-fast-compare";
import { ReactTFC } from "../../../utils/fp/missing-ts-parts";

const animation = "cubic-bezier(0.21, 0.84, 0.55, 0.97)";

// Externalize them and inject into global styles for performance reasons
export const mainNavigationComponentStyles = css`
  &.global-main-navigation {
    background-color: ${CSS_COLORS.White};

    &.bg-grey {
      background-color: ${CSS_COLORS.GreenWhite};
    }

    position: fixed;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;

    z-index: 110;
    width: 100%;
    height: 100%;

    overflow-y: scroll;

    &.from-left,
    &.from-right {
      transform: translateX(0);
      filter: opacity(1);
      transition: 0.25s ${animation} transform;
    }

    &:not(.active) {
      filter: opacity(0.9);

      &.from-left {
        transform: translateX(-100%);
        transition: 0.25s ${animation} transform;
      }

      &.from-right {
        transform: translateX(100%);
        transition: 0.2s ${animation} transform;
      }
    }
  }
`;

const FixedWholeScreenElementRaw: ReactTFC<{
  active: boolean;
  slideDirection?: "left" | "right";
  Component: React.JSXElementConstructor<any>;
  children?: ReactNode;
  bgGrey?: boolean;
}> = props => {
  useBodyOverflowStyles(props.active);

  return (
    <props.Component
      className={cn(
        "global-main-navigation",
        { "bg-grey": !!props.bgGrey },
        props.active ? "active" : "inactive",
        props.slideDirection && `from-${props.slideDirection}`,
      )}
      css={mainNavigationComponentStyles}
    >
      {props.children}
    </props.Component>
  );
};

export const FixedWholeScreenElement = React.memo(FixedWholeScreenElementRaw, isEqual);

// This is important they are defined top-level and not inline so that there are created only once.

type NavProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
export const NavElement: (props: NavProps) => React.JSX.Element = (props: NavProps) => <nav {...props} />;

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
export const DivElement: (props: DivProps) => React.JSX.Element = (props: DivProps) => <div {...props} />;
