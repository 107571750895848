import React from "react";
import { SinglePageFallbackAppShell } from "./SinglePageFallbackAppShell";
import { ContentContainer } from "./components/_shared-components/arrangement/Container";
import { GenericNotFoundPageLayoutShell } from "./layouts/generic-not-found/GenericNotFoundPageLayoutShell";

export const ConfigNotFound: React.FC = () => {
  return (
    <SinglePageFallbackAppShell>
      <ContentContainer className="u-flex u-justify-center u-align-center">
        <GenericNotFoundPageLayoutShell headline="Page not found" paragraph="Config not found." />
      </ContentContainer>
    </SinglePageFallbackAppShell>
  );
};
