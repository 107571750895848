import { css } from "@emotion/react";
import { labelWithNewAttributeClassName } from "../../components/_shared-components/new/new-label";
import { useGeneralTranslationsContext } from "../../global/hooks/use-general-translations-context";
import cn from "classnames";
import { CSS_COLORS } from "../../styles/styles-css-variables";

export const NewLabel2: React.FC<{ name: string | React.ReactNode; isNew: boolean }> = ({ name, isNew }) => {
  const newLabel = useGeneralTranslationsContext()("newLabel");

  return (
    <span className={labelWithNewAttributeClassName}>
      <span
        className={cn({ "is-new": isNew }, "u-relative u-text-balance u-block")}
        css={css`
          &.is-new::after {
            content: "${newLabel.value}";
            font-size: 0.5em;
            color: ${CSS_COLORS.Green};
            position: absolute;
            text-transform: uppercase;
          }
        `}
      >
        {name}
      </span>
    </span>
  );
};
