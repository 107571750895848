import { NonEmptyArray } from "fp-ts/es6/NonEmptyArray";
import { nonEmptyArray as NEA, option as O } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { regexp } from "./regexp";

export function interpolateLinearly(
  translationStringWithInterpolatedValues: string,
  args: NonEmptyArray<string>,
): string {
  if (args.length === 0) {
    return translationStringWithInterpolatedValues;
  }

  const matches = regexp.exec(translationStringWithInterpolatedValues);

  if (!matches) {
    return translationStringWithInterpolatedValues;
  }

  const front = translationStringWithInterpolatedValues.slice(0, matches.index);
  const back = translationStringWithInterpolatedValues.slice(matches.index + matches[0].length);
  const interpolatedValue = NEA.head(args);

  const result = `${front}${interpolatedValue}${back}`;

  return pipe(
    args,
    NEA.tail,
    NEA.fromArray,
    O.fold(
      () => {
        return result;
      },
      tail => interpolateLinearly(result, tail),
    ),
  );
}
