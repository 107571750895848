import { Maybe } from "../../utils/fp/small-fp-utils";
import { ReactTFC } from "../../utils/fp/missing-ts-parts";

import { css } from "@emotion/react";

import React from "react";
import { ContentFulComponents } from "@swo/storefront";

export function shouldHeaderOverlayNextComponent(nextComponent: Maybe<ContentFulComponents["__typename"] | "custom">) {
  if (nextComponent === "ComponentHeroImage") {
    return true;
  } else {
    return false;
  }
}

export function AfterHeaderAutoSpacerComponent(props: {
  componentAfterHeaderTypeName: Maybe<ContentFulComponents["__typename"] | "custom">;
}) {
  if (shouldHeaderOverlayNextComponent(props.componentAfterHeaderTypeName)) {
    return null;
  } else {
    return <HeaderSpacer />;
  }
}

export const HeaderSpacer: ReactTFC = () => {
  return (
    <div
      css={css`
        height: calc(var(--header-unfixed-height));
      `}
    />
  );
};
