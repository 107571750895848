import React from "react";
import { ContactDataType, type Link, type SwarovskiOptikSegmentShort } from "@swo/shared";
import { NewHeaderData, type HeaderLanguagePicker, type SwarovskiOptikLocaleFrontend } from "@swo/storefront";
import { NavigationSegmentsOverlay } from "./NavigationSegmentsOverlay";
import { useStore } from "../../../store/hooks/use-store";
import { actionCreators } from "../../SegmentFloatingButtonComponent/actionCreators";
import { DealerLocatorPaneContent } from "./MenuMetaComponent/DealerLocatorPaneContent";
import { LanguagePickerLayout } from "../layout/LanguagePickerLayout";
import { MyAccountDropdown } from "./MenuMetaComponent/MyAccountDropdown";
import { CartPaneContent } from "./CartPaneContent";
import { MainNavigationSearchComponent } from "../../MainNavigationComponent/MainNavigationSearchComponent";
import { PickersPaneContentState } from "./PaneContentState";
import { HeaderT } from "../HeaderT";
import { HeaderLinks } from "../ContextLessHeaderComponent";
import type { CustomerServiceDropdownElement } from "./MenuMetaComponent/CustomerServiceDropdown";
import { showChatbotMessengerEffect } from "../../../domain/intercom/show-chatbot-messenger-effect";

export const PickersPaneContent: React.FC<{
  paneContent: PickersPaneContentState;
  locale: SwarovskiOptikLocaleFrontend;
  segment: SwarovskiOptikSegmentShort | null;
  t: HeaderT;
  links: HeaderLinks;
  contactData: ContactDataType;
  languagePickerData: HeaderLanguagePicker;
  quickLinks: Array<Link>;
  canUseSearchInputFocus: boolean;
}> = ({
  paneContent,
  locale,
  segment,
  t,
  links,
  contactData,
  languagePickerData,
  quickLinks,
  canUseSearchInputFocus,
}) => {
  const store = useStore();

  const { onSegmentSelected } = actionCreators(store.dispatch);

  const { chatbotEnabled } = locale;

  const customerServiceElement: CustomerServiceDropdownElement = {
    headline: contactData.headline,
    line: contactData.availabilityHours || null,
    secondLine: contactData.phone,
    firstBtn: {
      as: "a" as const,
      label: contactData.email,
      link: `mailto:${contactData.email}`,
      isInternal: false,
      upperCased: false,
    },
    secondBtn:
      chatbotEnabled && contactData.showChatbotMessenger
        ? {
            as: "button" as const,
            label: contactData.showChatbotMessenger,
            onClick: showChatbotMessengerEffect,
          }
        : undefined,
  };

  switch (paneContent.tag) {
    case "segment-picker": {
      return (
        <NavigationSegmentsOverlay
          segments={paneContent.segments.all}
          onSelected={onSegmentSelected}
          selectedSegmentKey={paneContent.segments.current.key}
        />
      );
    }
    case "cart": {
      return <CartPaneContent cartData={paneContent.cartData} linkToCartPage={paneContent.cartLink} t={t} />;
    }
    case "dealer-locator": {
      return <DealerLocatorPaneContent t={t} links={links} customerServiceElement={customerServiceElement} />;
    }
    case "account": {
      return <MyAccountDropdown t={t} userLinks={links.user} />;
    }
    case "language-picker": {
      return <LanguagePickerLayout languagePicker={languagePickerData} t={t} locale={locale} />;
    }
    case "search": {
      return segment ? (
        <MainNavigationSearchComponent
          searchText={t("search.placeholder")}
          searchPageUrl={links.searchPage}
          locale={locale}
          segment={segment}
          customerServiceElement={customerServiceElement}
          t={t}
          quickLinks={quickLinks}
          canUseSearchInputFocus={canUseSearchInputFocus}
        />
      ) : null;
    }
    default: {
      const _: never = paneContent;

      return _;
    }
  }
};
