import { css } from "@emotion/react";
import { CSS_COLORS } from "./styles-css-variables";

export const utilitiesStyles = css`
  .u-uppercase {
    text-transform: uppercase;
  }

  .u-underline {
    text-decoration: underline;
  }

  .u-no-decoration {
    text-decoration: none;
  }

  .u-d-none {
    display: none;
  }

  .u-d-block {
    display: block;
  }

  .u-d-flex {
    display: flex;
  }

  .u-gap-2 {
    gap: 0.25rem;
  }

  .u-gap-2 {
    gap: 0.5rem;
  }

  .u-fd-column {
    flex-direction: column;
  }

  .u-fg-1 {
    flex-grow: 1;
  }

  .u-ai-start {
    align-items: flex-start;
  }

  .u-ai-center {
    align-items: center;
  }

  .u-ai-end {
    align-items: flex-end;
  }

  .u-jc-start {
    justify-content: flex-start;
  }

  .u-jc-center {
    justify-content: center;
  }

  .u-jc-between {
    justify-content: space-between;
  }

  .u-jc-end {
    justify-content: flex-end;
  }

  .u-flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .u-self-center {
    align-self: center;
  }

  .u-justify-self-end {
    justify-self: flex-end;
  }

  .u-relative {
    position: relative;
  }

  .u-absolute {
    position: absolute;
  }

  .u-bottom {
    bottom: 0;
  }

  .u-top {
    top: 0;
  }

  .u-bottom-0 {
    bottom: 0;
  }

  .u-top-0 {
    top: 0;
  }

  .u-right {
    right: 0;
  }

  .u-left {
    left: 0;
  }

  .u-right-0 {
    right: 0;
  }

  .u-left-0 {
    left: 0;
  }

  .u-left-50 {
    left: 50%;
  }

  .u-right-50 {
    right: 50%;
  }

  .u-top-50 {
    top: 50%;
  }

  .u-top-25 {
    top: 25%;
  }

  .u-bottom-50 {
    bottom: 50%;
  }

  .u-absolute-full {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .u-fw-300 {
    font-weight: 300;
  }

  .u-ta-left {
    text-align: left;
  }

  .u-ta-right {
    text-align: right;
  }

  .u-ta-center {
    text-align: center;
  }

  .u-hide-scrollbar-x {
    scrollbar-width: none; // firefox
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .u-overflow-hidden {
    overflow: hidden;
  }

  .u-overflow-x-hidden {
    overflow-x: hidden;
  }

  .u-overflow-y-hidden {
    overflow-y: hidden;
  }

  .u-overflow-y-scroll {
    overflow-y: scroll;
  }

  .u-overflow-y-auto {
    overflow-y: auto;
  }

  .u-color-inherit {
    color: inherit;
  }

  .u-hidden {
    visibility: hidden;
  }

  .u-none {
    display: none;
  }

  .u-overscroll-behavior-x-contain {
    overscroll-behavior-x: contain;
  }

  .u-m-0 {
    margin: 0;
  }

  .u-mt-0 {
    margin-top: 0;
  }

  .u-mb-0 {
    margin-bottom: 0;
  }

  .u-mb-0_5 {
    margin-bottom: 2px;
  }

  .u-mt-1 {
    margin-top: 4px;
  }

  .u-mb-1 {
    margin-bottom: 4px;
  }

  .u-mt-2 {
    margin-top: 8px;
  }

  .u-mb-2 {
    margin-bottom: 8px;
  }

  .u-mt-3 {
    margin-top: 12px;
  }

  .u-mb-3 {
    margin-bottom: 12px;
  }

  .u-mt-4 {
    margin-top: 16px;
  }

  .u-mb-4 {
    margin-bottom: 16px;
  }

  .u-mt-5 {
    margin-top: 20px;
  }

  .u-mb-5 {
    margin-bottom: 20px;
  }

  .u-mt-6 {
    margin-top: 24px;
  }

  .u-mb-6 {
    margin-bottom: 24px;
  }

  .u-mt-7 {
    margin-top: 28px;
  }

  .u-mb-7 {
    margin-bottom: 28px;
  }

  .u-mt-8 {
    margin-top: 32px;
  }

  .u-mb-8 {
    margin-bottom: 32px;
  }

  .u-ml-1 {
    margin-left: 4px;
  }

  .u-ml-2 {
    margin-left: 8px;
  }

  .u-ml-3 {
    margin-left: 12px;
  }

  .u-ml-4 {
    margin-left: 16px;
  }

  .u-ml-5 {
    margin-left: 20px;
  }

  .u-ml-6 {
    margin-left: 24px;
  }

  .u-ml-7 {
    margin-left: 28px;
  }

  .u-ml-8 {
    margin-left: 32px;
  }

  .u-color-green {
    color: ${CSS_COLORS.Green};
  }

  .u-color-white {
    color: ${CSS_COLORS.White};
  }

  .u-color-black {
    color: ${CSS_COLORS.Black};
  }

  .u-bg-black {
    background: ${CSS_COLORS.Black};
  }

  .u-bg-so-green-white {
    background: ${CSS_COLORS.GreenWhite};
  }

  .u-block {
    display: block;
  }

  .u-inline-block {
    display: inline-block;
  }

  .u-flex {
    display: flex;
  }

  .u-inline-flex {
    display: inline-flex;
  }

  .u-flex-wrap {
    flex-wrap: wrap;
  }

  .u-flex-col {
    flex-direction: column;
  }

  .u-flex-row {
    flex-direction: row;
  }

  .u-items-start {
    align-items: flex-start;
  }

  .u-items-center {
    align-items: center;
  }

  .u-items-end {
    align-items: flex-end;
  }

  .u-justify-center {
    justify-content: center;
  }

  .u-justify-end {
    justify-content: flex-end;
  }

  .u-flex-center {
    justify-content: center;
    align-items: center;
  }

  .u-grow {
    flex-grow: 1;
  }

  .u-grid {
    display: grid;
  }

  .u-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .u-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .u-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .u-h-full {
    height: 100%;
  }

  .u-w-full {
    width: 100%;
  }

  .u-w-max-content {
    width: max-content;
  }

  .u-w-min-content {
    width: min-content;
  }

  .u-scroll-x {
    overflow-x: scroll;
  }

  .u-scroll-y {
    overflow-y: scroll;
  }

  .u-scroll-y-auto {
    overflow-y: auto;
  }

  .u-gap-0_5 {
    gap: 2px;
  }

  .u-gap-1 {
    gap: 4px;
  }

  .u-gap-2 {
    gap: 8px;
  }

  .u-gap-3 {
    gap: 12px;
  }

  .u-gap-4 {
    gap: 16px;
  }

  .u-gap-5 {
    gap: 20px;
  }

  .u-gap-6 {
    gap: 24px;
  }

  .u-cursor-pointer {
    cursor: pointer;
  }

  .u-aspect-video {
    aspect-ratio: 16 / 9;
  }

  .u-text-balance {
    text-wrap: balance;
  }
`;
