import React, { CSSProperties } from "react";
import { css } from "@emotion/react";

import { SerializedStyles } from "@emotion/react";
import { CSS_MQ } from "../../../styles/breakpoints";
import cn from "classnames";

export const ContentContainer: React.FC<
  React.PropsWithChildren<{
    attachStyles?: SerializedStyles | Array<SerializedStyles>;
    className?: string;
    style?: CSSProperties;
    width?: "narrow";
  }>
> = props => {
  return (
    <div
      className={cn(props.className, { narrow: props.width === "narrow" })}
      css={[
        css`
          max-width: var(--max-content-width-new);
          &.narrow {
            max-width: var(--max-narrow-content-width);
          }

          width: 100%;

          margin-left: auto;
          margin-right: auto;
        `,
        props.attachStyles,
      ]}
      style={props.style}
    >
      {props.children}
    </div>
  );
};

export const ContentContainer2: React.FC<
  React.PropsWithChildren<{
    attachStyles?: SerializedStyles;
    className?: string;
  }>
> = props => {
  return (
    <div
      className={props.className}
      css={[
        css`
          max-width: 100%;
          ${CSS_MQ.atLeastMd} {
            max-width: var(--max-content-width-new);
          }

          width: 100%;

          margin-left: auto;
          margin-right: auto;
        `,
        props.attachStyles,
      ]}
    >
      {props.children}
    </div>
  );
};
