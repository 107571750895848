import { ReactTFC } from "../../../utils/fp/missing-ts-parts";
import React from "react";
import { createPngLink, createWebpLink } from "./createJpgAndWebpLinks";
import { FocalPointPosition } from "./ImageWithFocalPoint";
import { useImageAssetHintsStateCtx, useToolbarStateCtx } from "../../cms/edit-overlay/ToolbarStateProvider";
import { css } from "@emotion/react";
import { fontSize } from "../../../styles/fonts";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { ReactComponent as ContentfulIconSvg } from "../../cms/SiteMapComponent/contentful-icon.svg";
import { useContentfulLink } from "../../useContentfulLink";

const regex = /^\d+px$/;

type Props = {
  contentfulUrl: string | undefined;
  quality?: number;
  withTransparency: boolean;
  cropping:
    | {
        focalPoint: {
          x: number;
          y: number;
        };
        image: {
          width: number;
          height: number;
        };
      }
    | undefined;
  assetId: string | undefined;
} & Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src" | "srcSet">;

export const ContentfulImage2: ReactTFC<Props> = props => {
  const imageAssetHints = useImageAssetHintsStateCtx();

  const { contentfulUrl: src, withTransparency, cropping, sizes, assetId, ...restProps } = props;

  const linkCreator = withTransparency
    ? (src: string, size: number) => createWebpLink(src, size, props.quality || 80)
    : (src: string, size: number) => createPngLink(src, size, props.quality || 80);

  const simpleSizesParam = sizes ? regex.test(sizes) : false;

  function getSrcSet(src: string): string {
    let widths = [150, 300, 600, 1000, 1600, 2200];

    if (simpleSizesParam && sizes) {
      let lastMatchingWidth = widths.findLastIndex(w => w <= parseInt(sizes));
      if (lastMatchingWidth < 0) {
        lastMatchingWidth = 1;
      }

      widths = widths.filter((_, idx) => idx <= lastMatchingWidth + 1);
    }

    return widths.map(size => `${linkCreator(src, size)} ${size}w`).join(", ");
  }

  const fp = cropping ? FocalPointPosition.create(cropping.image, cropping.focalPoint).asCssString() : undefined;

  return (
    <>
      <img
        src={src}
        alt={""}
        srcSet={src ? getSrcSet(src) : undefined}
        {...restProps}
        style={fp ? { objectPosition: fp, objectFit: "cover" } : undefined}
        sizes={sizes}
      />

      {imageAssetHints && assetId && <ContentfulImageAssetHint assetId={assetId} />}
    </>
  );
};

export const ContentfulImageAssetHint: React.FC<{ assetId: string }> = ({ assetId }) => {
  const contentfulLink = useContentfulLink();
  const entryLink = contentfulLink({ assetId });

  return (
    <div className=" js-contentful-image-asset-hint">
      <span
        onClick={() => window.open(entryLink, "_blank")}
        className="u-absolute u-left-50 u-top-25"
        css={css`
          background: ${CSS_COLORS.YellowCustom};

          transition: transform 0.2s linear;
          &:hover {
            transform: scale(1.1);
          }

          padding: 4px;
          border-radius: 100%;

          width: 32px;
          height: 32px;

          display: flex;
          align-items: center;
          justify-content: center;

          cursor: pointer;
        `}
      >
        <ContentfulIconSvg title={`Asset ${assetId} in Contentful`} />

        <span
          className="u-absolute"
          css={[
            fontSize.x2s,
            css`
              bottom: -1.25em;
              left: -2px;
              position: absolute;

              background: ${CSS_COLORS.YellowCustom};
              color: ${CSS_COLORS.Black};

              padding: 1px 4px;
              line-height: 1;
              display: inline-block;

              width: max-content;
            `,
          ]}
        >
          Asset
        </span>
      </span>
    </div>
  );
};
