import { css } from "@emotion/react";

import React from "react";
import { ReactTFC } from "../../../../../utils/fp/missing-ts-parts";
import { FONTS, fontSize } from "../../../../../styles/fonts";
import type { TranslatedValue } from "@swo/storefront";
import { T } from "../../../../T";

export const ShoppingCartDropdownSummaryElement: ReactTFC<
  React.PropsWithChildren<{
    cartPrice: { totalPrice: string; pretaxPrice?: string };
    totalPriceLabel: TranslatedValue;
  }>
> = props => {
  return (
    <div
      className="u-flex u-flex-col u-ai-center u-relative"
      css={css`
        padding: 20px 18px 15px;
      `}
    >
      <span css={[FONTS.SwarovskiFutura500, fontSize.x2]}>{props.cartPrice.totalPrice}</span>
      <span
        css={[
          FONTS.SwarovskiFutura300,
          fontSize.x2,
          css`
            margin-bottom: 10px;
          `,
        ]}
      >
        <T>{props.totalPriceLabel}</T>
      </span>

      {props.children}
    </div>
  );
};
