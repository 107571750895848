import React from "react";
import { css } from "@emotion/react";
import { MainApp } from ".";
import { fetchPublicConfig } from "./domain/contentful/get-window-config";
import { PublicConfigurationT } from "@swo/shared";
import { useFetched, useStale } from "./hooks";
import { SmallLoadingSpinner } from "./routes/modal-loading-spinner";
import { ConfigNotFound } from "./ConfigNotFound";
import { ContentContainer } from "./components/_shared-components/arrangement/Container";
import { SinglePageFallbackAppShell } from "./SinglePageFallbackAppShell";
import { useMountedThreshold } from "./useMountedThreshold";

export const DevelopmentAppShell: React.FC<{ localConfig: PublicConfigurationT | null }> = ({ localConfig }) => {
  const remoteConfig = useFetched(fetchPublicConfig, {
    dataKey: "config",
  });

  const mounted = useMountedThreshold(300);

  if (localConfig !== null) {
    return <MainApp config={localConfig} runtimeMode={"spa"} />;
  }

  switch (remoteConfig.__tag) {
    case "loading":
      return mounted ? (
        <SinglePageFallbackAppShell>
          <ContentContainer className="u-flex u-justify-center u-align-center">
            <span className="u-flex u-flex-col u-items-center">
              <SmallLoadingSpinner color="green" noMargin size="3x" />
              <span>Fetching storefront configuration...</span>
            </span>
          </ContentContainer>
        </SinglePageFallbackAppShell>
      ) : null;
    case "error":
      return <ConfigNotFound />;
    case "loaded": {
      if (remoteConfig.data) {
        return <MainApp config={remoteConfig.data} runtimeMode={"spa"} />;
      } else {
        return <ConfigNotFound />;
      }
    }
    default: {
      const _: never = remoteConfig;
      return _;
    }
  }
};
