import React, { ButtonHTMLAttributes, useState, type ForwardedRef } from "react";

import { css, SerializedStyles } from "@emotion/react";

import { Link, LinkProps } from "react-router";
import { CSS_MQ } from "../../../styles/breakpoints";
import { CSS_COLORS } from "../../../styles/styles-css-variables";

import cn from "classnames";
import { FONTS } from "../../../styles/fonts";
import { useMountedRef } from "../../../hooks/use-mounted";
import { asStringLink, SwarovskiLinkData } from "./SwarovskiLink";

const BtnCss = css`
  --padding-v: 12px;
  --padding-h: 25px;

  --padding-h-add-right: 0px;
  --padding-h-add-left: 0px;
  --pd: 2px;

  &.size-tiny {
    --padding-v: 4px;
    --padding-h: 8px;
    --pd: 1px;
  }

  &.size-small {
    --padding-v: 8px;
    --padding-h: 20px;
  }

  &.size-big {
    --padding-v: 16px;
    --padding-h: 32px;
  }

  ${CSS_MQ.atLeastMd} {
    --padding-v: 13px;
    --padding-h: 44px;

    &.size-tiny {
      --padding-v: 8px;
      --padding-h: 15px;
      --pd: 1px;
    }

    &.size-small {
      --padding-v: 9px;
      --padding-h: 22px;
    }

    &.size-big {
      --padding-v: 24px;
      --padding-h: 42px;
    }
  }

  padding: calc(var(--padding-v) + var(--pd)) calc(var(--padding-h) + var(--padding-h-add-right))
    calc(var(--padding-v) - var(--pd)) calc(var(--padding-h) + var(--padding-h-add-left));

  line-height: 1;

  transition:
    0.1s linear background-color,
    0.1s linear color;
  &:hover,
  &:focus {
    transition:
      0.1s linear background-color,
      0.1s linear color;
  }

  &.variant-green {
    border: 1px solid transparent;

    &:not([disabled]),
    &:not(.disabled) {
      color: ${CSS_COLORS.White};
      background-color: ${CSS_COLORS.Green};
    }

    &[disabled],
    &.disabled {
      color: ${CSS_COLORS.White};
      background-color: ${CSS_COLORS.Grey5};
    }

    &:hover:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not(&.disabled),
    &:focus:not(&.disabled) {
      color: ${CSS_COLORS.White};
      background-color: ${CSS_COLORS.GreenDark};
      outline: none;
    }
  }

  &.variant-unstyled {
    padding: 0;
  }

  &.variant-normal,
  &.variant-normal-underline {
    border: 1px solid white;
    color: inherit;

    &:hover,
    &:focus {
      background-color: ${CSS_COLORS.White};
      color: ${CSS_COLORS.Green};
      outline: none;
    }

    &:focus {
      outline: 2px dotted ${CSS_COLORS.Green};
    }
  }

  &.variant-normal-underline {
    text-decoration: underline;
  }

  &.variant-outline {
    border: 1px solid var(--so-green);
    color: var(--so-green);

    &:hover,
    &:focus {
      color: ${CSS_COLORS.White};
      background-color: var(--so-green);
      outline: none;
    }
  }

  &.variant-outline-inherit {
    border: 1px solid currentColor;
    color: inherit;

    &:hover,
    &:focus {
      background-color: ${CSS_COLORS.Green};
      color: ${CSS_COLORS.White};
      border: 1px solid ${CSS_COLORS.Green};
      outline: none;
    }
  }

  &.variant-plain-underline {
    padding: 0;
    color: ${CSS_COLORS.Green};
    text-decoration: underline;
  }

  &.variant-ghost-underline {
    color: ${CSS_COLORS.Green};
    text-decoration: underline;
  }

  &.variant-white {
    color: ${CSS_COLORS.Green};
    background-color: ${CSS_COLORS.White};
  }

  &[disabled],
  &.disabled {
    cursor: not-allowed;
    color: ${CSS_COLORS.Grey2};
  }

  ${FONTS.SwarovskiFutura500};
  font-size: 18px;

  &.size-tiny,
  &.size-small {
    font-size: 16px;
  }

  &.click-action-pending {
    cursor: progress;
  }

  &.with-icon {
    position: relative;

    svg {
      position: absolute;

      width: 18px;
      ${CSS_MQ.atLeastMd} {
        width: 26px;
      }

      top: 0;
      height: 100%;
    }

    &.icon-left {
      --padding-h-add-left: 15px;
      svg {
        left: 2px;
      }

      ${CSS_MQ.atLeastMd} {
        --padding-h-add-left: 12px;

        svg {
          left: 4px;
        }
      }
    }

    &.icon-right {
      --padding-h-add-right: 15px;
      svg {
        right: 2px;
      }

      ${CSS_MQ.atLeastMd} {
        --padding-h-add-right: 12px;
        svg {
          right: 4px;
        }
      }
    }
  }

  letter-spacing: 1px;
  text-transform: uppercase;

  text-decoration: none;
  border-radius: 8px;
  display: inline-block;
`;

type SwarovskiButtonSharedT = {
  variant:
    | "green"
    | "normal"
    | "normal-underline"
    | "outline"
    | "outline-inherit"
    | "plain-underline"
    | "ghost-underline"
    | "white"
    | "unstyled";
  scrollToTop?: boolean;
  disabled?: boolean;
  Icon?: React.JSX.Element;
  iconPosition?: "left" | "right";
  className?: string;
  tabIndex?: number;
};

type ButtonSize = "tiny" | "small" | "normal" | "big";

type SwarovskiButtonAnchorT = {
  as: "a";
  link: string | SwarovskiLinkData;
  isInternal: boolean;
  target?: string;
  rel?: string;
  size?: ButtonSize;
  onClick?: () => void; // for analytics/tracking
};

type SwarovskiButtonButtonT = {
  as: "button";
  onClick?: () => void;
  onClickWithPromise?: () => Promise<void>;
  type?: ButtonHTMLAttributes<unknown>["type"];
  size?: ButtonSize;
};

export type SOButtonProps = SwarovskiButtonSharedT &
  (SwarovskiButtonAnchorT | SwarovskiButtonButtonT) & {
    css?: SerializedStyles | Array<SerializedStyles>;
    children?: React.ReactNode;
  };

function extractHashSuffix(str: string) {
  const idx = str.lastIndexOf("#");

  return idx === -1 ? { pathname: str, hash: undefined } : { pathname: str.slice(0, idx), hash: str.slice(idx) };
}

function getTo(link: string | SwarovskiLinkData, shouldScrollToTop: boolean): { to: LinkProps["to"]; state: object } {
  if (typeof link === "string") {
    const { pathname, hash } = extractHashSuffix(link);

    return { to: { pathname, hash }, state: { scrollToTop: shouldScrollToTop } };
  } else {
    const { pathname, hash } = extractHashSuffix(link.pathname);

    return {
      to: {
        pathname: pathname,
        search: link.search,
        hash,
      },
      state: { ...link.state, scrollToTop: shouldScrollToTop },
    };
  }
}

export const SwarovskiButton = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, SOButtonProps>(
  ({ children, css, ...props }, ref) => {
    const classNames = cn(props.className, `variant-${props.variant}`, `size-${props.size || "normal"}`, {
      disabled: props.disabled,
      "with-icon": !!props.Icon,
      "icon-right": !!props.Icon && (props.iconPosition === "right" || typeof props.iconPosition === "undefined"),
      "icon-left": !!props.Icon && props.iconPosition === "left",
    });
    const shouldScrollToTop = props.scrollToTop || true;

    if (props.as === "a") {
      if (props.isInternal) {
        const { to, state } = getTo(props.link, shouldScrollToTop);

        return (
          <Link
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            to={to}
            state={state}
            css={BtnCss}
            className={cn(classNames)}
            onClick={props.onClick}
            tabIndex={props.tabIndex}
          >
            {children}
          </Link>
        );
      } else {
        return (
          <a
            ref={ref as ForwardedRef<HTMLAnchorElement>}
            href={typeof props.link === "string" ? props.link : asStringLink(props.link)}
            css={BtnCss}
            className={cn(classNames)}
            rel={props.rel}
            target={props.target}
            onClick={props.onClick}
            tabIndex={props.tabIndex}
          >
            {children}
          </a>
        );
      }
    } else {
      return (
        <SwarovskiButtonButton {...props} classNameBase={classNames} ref={ref as ForwardedRef<HTMLButtonElement>}>
          {children}
        </SwarovskiButtonButton>
      );
    }
  },
);

const SwarovskiButtonButton = React.forwardRef<
  HTMLButtonElement,
  SwarovskiButtonSharedT & SwarovskiButtonButtonT & { classNameBase: string } & { children?: React.ReactNode }
>((props, ref) => {
  const [isClickActionPending, setClickActionPending] = useState(false);
  const disabled = props.disabled || isClickActionPending;

  const isMounted = useMountedRef();

  const onClickCb = () => {
    if (disabled) {
      return undefined;
    } else {
      if (typeof props.onClickWithPromise !== "undefined") {
        setClickActionPending(true);

        void props.onClickWithPromise().finally(() => {
          if (isMounted.current) {
            setClickActionPending(false);
          }
        });
      } else if (typeof props.onClick !== "undefined") {
        props.onClick();
      }
    }
  };

  return (
    <button
      ref={ref}
      css={BtnCss}
      className={cn(props.classNameBase, { "click-action-pending": isClickActionPending })}
      onClick={onClickCb}
      disabled={disabled}
      type={props.type}
      tabIndex={props.tabIndex}
      title={typeof props.children === "string" ? props.children : undefined}
    >
      {props.iconPosition === "left" && props.Icon}
      {props.children}
      {props.iconPosition !== "left" && props.Icon}
    </button>
  );
});
