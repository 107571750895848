import { usePageContext } from "../../../../../global/page-context";
import { PageContextType } from "../../../../../global/PageContextType";
import { useCartContext } from "../../../../../global/cart-context/cart-context";
import { zip } from "fp-ts/Array";
import { pipe } from "fp-ts/lib/function";
import { SwarovskiOptikClientCart } from "@swo/storefront";

export function computeCartData(cart: SwarovskiOptikClientCart | null, page: PageContextType) {
  const nonEmptyCartData = (() => {
    if (cart == null) {
      return undefined;
    }

    const {
      computed: { lineItems: lineItemsExtensions, cartPrice },
      lineItems,
    } = cart;

    if (lineItems.length > 0) {
      return {
        cartPrice: cartPrice,
        lineItems: pipe(lineItems, zip(lineItemsExtensions)).map(([a, b]) => ({ ...a, ...b })),
      };
    } else {
      return undefined;
    }
  })();

  const isUserOnCartPage = page.pageBase.__typename === "CartPage";
  const shouldHaveDropdownNonEmptyCartData = !isUserOnCartPage && nonEmptyCartData;

  return { isUserOnCartPage, shouldHaveDropdownNonEmptyCartData };
}
