import { NewHeaderData, type TranslatedValue } from "@swo/storefront";
import { HeaderT } from "../HeaderT";
import { Segments } from "./props";

type Width = "standard" | "wide" | "widest";
export type Theme = "white" | "light" | "dark";

type SharedStateProps = {
  title: TranslatedValue;
  width: Width;
  theme: Theme;
};

export type PickersPaneContentState = (
  | {
      tag: "segment-picker";
      segments: Segments;
    }
  | { tag: "cart"; cartLink: string; cartData: NewHeaderData["cart"] }
  | { tag: "dealer-locator" }
  | { tag: "account" }
  | { tag: "search" }
  | { tag: "language-picker" }
) &
  SharedStateProps;

export type ContentPaneContent = {
  tag: "shop" | "content";
  data: {
    label: TranslatedValue | string;
  } | null;
} & SharedStateProps;

export type PaneContentState__Content = {
  tag: "content-pane";
  content: ContentPaneContent | null;
};

export type PaneContentState__ContentSelected = {
  tag: "content-pane";
  content: ContentPaneContent;
};

export type PaneContentState__Pickers = {
  tag: "pickers";
  content: PickersPaneContentState;
};

export type PaneContentState = PaneContentState__Content | PaneContentState__Pickers | null;

export function contentPaneContent(
  content: Pick<ContentPaneContent, "tag">,
  data: { label: TranslatedValue | string } | null,
  t: HeaderT,
): PaneContentState__ContentSelected {
  return {
    tag: "content-pane",
    content: {
      ...content,
      theme: content.tag === "shop" ? "light" : "white",
      title: content.tag === "shop" ? t("navigation.shopLabel") : t("navigation.contentLabel"),
      width: "widest",
      data: data,
    },
  };
}

export function pickersPaneContent(content: PickersPaneContentState): PaneContentState__Pickers {
  return { tag: "pickers", content };
}

export const STANDARD_PROPS: { width: Width; theme: Theme } = { width: "standard", theme: "light" };
