import { Option } from "fp-ts/es6/Option";
import { LoadingStateWithIdle, LoadingStateWithIdleOps } from "../../domain/data-layer/loading-state-with-idle";
import { useResourceRelatedInfo } from "../use-resource-related-info";
import { either } from "fp-ts";

export function useExternalState<
  Resource extends Array<unknown> | string | number | Record<string, string | undefined>,
  Result,
>(
  resource: Resource,
  eff: () => Promise<Option<Result>>,
): LoadingStateWithIdle<{ result: Option<Result>; version: string }> {
  return useResourceRelatedInfo(
    LoadingStateWithIdleOps.asLoaded(resource),
    resource => (Array.isArray(resource) ? resource.join("@") : JSON.stringify(resource)),
    resource => either.right(resource),
    eff,
  );
}
