import React, { useCallback, useEffect, useRef } from "react";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import { css } from "@emotion/react";
import { headlineStyles, paddingStyles, elementStyles } from "./HeaderNavigationData";
import { Props } from "./HeaderNavigationLeftPaneFirstColumn";
import { MenuAimOnMouse } from "./useMenuAim";
import { T } from "../../T";

export const PrimaryNavigation: React.FC<{
  data: Props["data"]["primary"];
  state: Props["state"];
  selectItem: Props["selectItem"];
  onMouse: MenuAimOnMouse;
}> = ({ data, state, selectItem, onMouse }) => {
  return (
    <div
      className="u-relative"
      css={css`
        z-index: 2;
      `}
    >
      <ul
        className="u-flex u-flex-col"
        css={[
          css`
            gap: 40px;

            margin-top: var(--margin-top);
          `,
          paddingStyles,
        ]}
      >
        {data.map((item, idx) => {
          const isSelected = state?.label === item.label;
          const selectedClassName = isSelected ? "selected" : "";

          return (
            <PrimaryNavigationNode
              key={JSON.stringify(item)}
              className={selectedClassName}
              item={item}
              selectItem={selectItem}
              shouldUseRef={idx === 0}
              onMouse={onMouse}
            />
          );
        })}
      </ul>
    </div>
  );
};

const PrimaryNavigationNode: React.FC<{
  item: HeaderPrimaryNavigationElemT;
  className: string;
  shouldUseRef: boolean;
  selectItem: Props["selectItem"];
  onMouse: MenuAimOnMouse;
}> = ({ item, className, shouldUseRef, selectItem, onMouse }) => {
  const ref = useRef<HTMLButtonElement>(null);

  const onMouse_ = useCallback(onMouse(item), [item.label]);

  useEffect(() => {
    if (shouldUseRef) {
      ref.current?.focus();
    }
  }, [shouldUseRef]);

  return (
    <li
      key={JSON.stringify(item)}
      onMouseOver={e => {
        // selectItem(item);

        onMouse_("over", e, "wrapper");
      }}
      onMouseLeave={e => {
        onMouse_("leave", e, "wrapper");
      }}
      onMouseMove={e => {
        onMouse_("move", e, "wrapper");
      }}
    >
      <button
        ref={ref}
        className={className}
        css={elementStyles}
        onClick={e => {
          e.stopPropagation();

          onMouse_("over", e, "text");
        }}
        onMouseOver={e => {
          // selectItem(item);
          e.stopPropagation();

          onMouse_("over", e, "text");
        }}
        onMouseLeave={e => {
          e.stopPropagation();

          onMouse_("leave", e, "text");
        }}
        onMouseMove={e => {
          e.stopPropagation();

          onMouse_("move", e, "text");
        }}
      >
        <span css={headlineStyles}>
          <T>{item.label}</T>
        </span>
      </button>
    </li>
  );
};
