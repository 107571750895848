import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import React from "react";
import { useGeneralTranslationsContext } from "../../../global/hooks/use-general-translations-context";
import cn from "classnames";
import { T } from "../../T";

export const labelWithNewAttributeClassName = "u-relative";

export const newStylesInline = css`
  display: inline-flex;
`;

export const NewLabel = (props: { className?: string; inline?: boolean }) => {
  const label = useGeneralTranslationsContext()("newLabel");

  return (
    <span
      className={cn(props.className, "text-aa", { inline: !!props.inline })}
      css={css`
        --m: 0.25em;

        &:not(.inline) {
          position: absolute;
          left: calc(100% + var(--m));
          top: 0;
          width: max-content;
        }

        &.inline {
          justify-content: flex-start;
        }

        text-transform: uppercase;
        letter-spacing: initial;

        font-weight: 500;

        color: ${CSS_COLORS.Green};
      `}
    >
      <T>{label}</T>
    </span>
  );
};
