import { css } from "@emotion/react";
import { CSS_MQ } from "../../../styles/breakpoints";

// Externalizing and injecting them into global styles for performance reasons
export const swarovskiLogoStyles = css`
  .swarovski-logo-element {
    grid-column: 2 / span 1;
    ${CSS_MQ.atLeastMd} {
      grid-column: 2 / span 2;
    }
    grid-row: 1;

    justify-self: center;
    align-self: center;

    color: inherit;

    .headroom--scrolled & {
      .logo {
        transform: scale(1.4) translate(-54px, 6px);

        ${CSS_MQ.atLeastMd} {
          transform: scale(1.05) translate(-42px, 17px);
        }
      }

      .text {
        opacity: 0;

        transform: translate(5px, 20px) scale(0.2);
        transform-origin: bottom left;
      }
    }

    .headroom--pinned & {
      .text {
        // transition: all 0.3s ease-out;
      }

      .logo {
        transition: color 0.3s ease-out;
      }
    }

    .headroom--unfixed.headroom-disable-animation & {
      @keyframes header-text-animation {
        0% {
          transform: scale(0.8) translate(-20px, 0);
          opacity: 0;
        }

        35% {
          transform: scale(0.8) translate(-20px, 0);
          opacity: 0;
        }

        100% {
          transform: scale(1) translate(0, 0);
          opacity: 1;
        }
      }

      @keyframes header-text-animation-mobile {
        0% {
          transform: scale(0.9) translate(-10px, 0);
          opacity: 0;
        }

        35% {
          transform: scale(0.9) translate(-10px, 0);
          opacity: 0;
        }

        100% {
          transform: scale(1) translate(0, 0);
          opacity: 1;
        }
      }

      .text {
        animation: header-text-animation-mobile 0.3s linear;
        animation-fill-mode: forwards;

        ${CSS_MQ.atLeastMd} {
          animation: header-text-animation 0.3s linear;
        }

        transform-origin: left;
      }

      .logo {
        transition: all 0.3s ease-out;
        transform: translate(0, 0);
      }
    }

    :not(.headroom--scrolled) & {
      margin-top: 24px;

      .text {
        // opacity: 1;
      }
    }

    height: 60px;
    ${CSS_MQ.atLeastMd} {
      height: 70px;
      width: 300px; // space for animation
    }
  }
`;
