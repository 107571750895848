import { css } from "@emotion/react";
import type React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { FONTS } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";

export const SearchResultSummary: React.FC<{ title: string; description: string | undefined; t: SearchT }> = ({
  title,
  description,
  t,
}) => {
  return (
    <div
      className="u-mt-2 u-grow"
      css={css`
        color: ${CSS_COLORS.Black};

        min-height: 5em;
      `}
    >
      <span css={FONTS.SwarovskiFutura500} className="_text">
        {title}
      </span>

      {description && (
        <p
          css={[
            FONTS.SwarovskiFutura300,
            css`
              margin: 0;
              margin-top: 4px;

              display: -webkit-box;

              -webkit-line-clamp: 2;

              -webkit-box-orient: vertical;
              overflow: hidden;
            `,
          ]}
        >
          {description}
        </p>
      )}
    </div>
  );
};
