import cn from "classnames";
import React, { useCallback, useRef } from "react";
import { CSS_MQ, CSS_MQ_ONLY } from "../../../styles/breakpoints";

import { css } from "@emotion/react";

import { useCartContext } from "../../../global/cart-context/cart-context";
import { useBodyOverflowStyles } from "../../../hooks/use-body-overflow-styles-for";
import { useStore } from "../../../store/hooks/use-store";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { getBackgroundClassName } from "./getBackgroundClassName";
import { getContentPaneContent } from "./getContentPaneContent";
import { HeaderNavigationMobilePane } from "./HeaderNavigationMobilePane";
import { HeaderNavigationPane } from "./HeaderNavigationRightPane";
import { HeaderWrapper } from "./HeaderWrapper";
import { LeftSideNavigation } from "./LeftSideNavigation";
import { contentPaneContent, type PaneContentState } from "./PaneContentState";
import { PickersPaneContent } from "./PickersPaneContent";
import { MainHeaderProps } from "./props";
import { HeaderNavigationPaneTitle } from "./RightPaneTitle";
import { RightSideNavigation } from "./SecondaryNavigationElements";
import { useActions } from "./useActions";
import { usePageContext } from "../../../global/page-context";
import { getInitialState } from "../../DealerLocatorComponent/state/get-initial-state";
import { usePaneContent } from "./usePaneContent";
import type { SwarovskiOptikSegmentKey } from "@swo/shared";

export const headerClassName = "js-so-header";

export const headerHeadroomStyles = css`
  &.variant-white {
    .headroom {
      --header-variant-theme-background-color: ${CSS_COLORS.White};
      --header-variant-theme-text-color: black;
    }
  }

  &.variant-transparent-black {
    .headroom {
      --header-variant-theme-background-color: transparent;
      --header-variant-theme-text-color: black;
    }
  }

  &.variant-transparent-white {
    .headroom {
      --header-variant-theme-background-color: transparent;
      --header-variant-theme-text-color: ${CSS_COLORS.White};
    }
  }

  &.custom-mode {
    .headroom {
      --header-variant-theme-background-color: red !important;
      --header-variant-theme-text-color: blue !important;
    }
  }

  &.has-opened-pane {
    ${CSS_MQ.atMostSm} {
      .headroom {
        --header-variant-theme-background-color: ${CSS_COLORS.White} !important;
        --header-variant-theme-text-color: black !important;
      }
    }
  }

  &:not(.has-opened-pane) {
    .headroom--scrolled {
      backdrop-filter: blur(5px) !important;
    }
  }

  // --navigation-diff: calc((100vw - var(--max-content-width-new)) / 2);
  --navigation-diff: 0;

  .headroom--scrolled {
    background-color: rgba(255, 255, 255, 0.9) !important;
    --header-variant-theme-background-color: ${CSS_COLORS.White} !important;
    --header-variant-theme-text-color: black !important;
  }
`;

export const MainHeaderComponent: React.FC<MainHeaderProps> = ({
  segments,
  t,
  locale,
  header,
  contactData,
  userPreferredSegmentKey,
  showSegmentSelector,
  hasAnythingInCart,
  isOnCartPage,
  quickLinks,
  ...props
}) => {
  const { paneContent, setPaneContent } = usePaneContent({
    userPreferredSegmentKey,
    showSegmentSelector,
    segments,
    t,
  });

  const maybeStore = useStore();
  const maybeDispatch = maybeStore?.dispatch;

  const { actions, states: pickersContents } = useActions(
    setPaneContent,
    segments,
    props.links.cartPage && header?.cart ? { link: props.links.cartPage, data: header.cart } : null,
    t,
  );

  useBodyOverflowStyles(!!paneContent, true);

  const className = cn(
    !paneContent
      ? ""
      : paneContent.tag === "content-pane"
        ? ["opened-content-pane", paneContent.content ? "with-content" : undefined]
        : ["opened-pickers", paneContent.content.theme === "dark" ? "dark" : "light"],
  );

  if (!locale || !header || !contactData || !t) {
    return (
      <HeaderWrapper
        className={className}
        hasPaneContent={!!paneContent}
        isCustomShopMode={false}
        links={props.links}
        navigationClassName={cn(
          paneContent?.tag == "pickers" && paneContent.content
            ? getBackgroundClassName(paneContent.content.theme)
            : null,
        )}
        theme={"white"}
        headerHooks={props.headerHooks}
        floatingDisabled={props.floatingDisabled}
        outletNavigation={null}
        outletNearLogo={null}
        outletRightPane={null}
      />
    );
  }

  const {
    chatbotEnabled,
    shopMode: { cart: cartMode },
  } = locale;
  const isCustomShopMode = locale.shopMode.tag === "custom";

  function onMobilePaneOpened() {
    setPaneContent(s => (s ? null : { tag: "content-pane", content: null }));
  }

  const onClose = useCallback(() => {
    const currentSegmentKey = segments?.current.key;

    if (
      paneContent?.tag === "pickers" &&
      paneContent.content.tag === "segment-picker" &&
      !userPreferredSegmentKey &&
      currentSegmentKey
    ) {
      maybeDispatch({ type: "user-picked-preferred-segment", value: currentSegmentKey });
    }

    setPaneContent(null);
  }, [paneContent, segments, userPreferredSegmentKey, maybeStore, maybeDispatch]);

  return (
    <HeaderWrapper
      className={className}
      hasPaneContent={!!paneContent}
      isCustomShopMode={isCustomShopMode}
      links={props.links}
      navigationClassName={cn(
        paneContent?.tag == "pickers" && paneContent.content ? getBackgroundClassName(paneContent.content.theme) : null,
        paneContent && paneContent.tag === "content-pane" && paneContent.content
          ? paneContent.content.tag === "shop"
            ? "tag-shop"
            : "tag-content"
          : undefined,
      )}
      theme={header.theme}
      headerHooks={props.headerHooks}
      floatingDisabled={props.floatingDisabled}
      outletNearLogo={
        <LeftSideNavigation
          mainNavigationElements={props.mainNavigationElements}
          activeTag={paneContent?.tag === "content-pane" && paneContent.content ? paneContent.content.tag : null}
          onClick={tag =>
            setPaneContent(c =>
              c?.tag === "content-pane" && c.content?.tag === tag ? null : contentPaneContent({ tag }, null, t),
            )
          }
        />
      }
      outletNavigation={
        <>
          <HeaderNavigationPane
            viewport="only-md"
            pane={
              paneContent?.tag === "content-pane" && paneContent.content
                ? {
                    theme: paneContent.content.tag === "shop" ? "light" : "white",
                    width: paneContent.content.width,
                  }
                : null
            }
            onClose={onClose}
            side="left"
          >
            {paneContent?.tag === "content-pane" && paneContent.content
              ? getContentPaneContent(paneContent.content, contactData, header, onClose)
              : null}
          </HeaderNavigationPane>

          <HeaderNavigationPane
            viewport="only-md"
            pane={paneContent?.tag === "pickers" ? paneContent.content : null}
            onClose={onClose}
            side="right"
            outlet={
              paneContent && paneContent.content ? (
                <HeaderNavigationPaneTitle align="left" label={paneContent.content.title} />
              ) : null
            }
          >
            <div className="js-header-navigation-pane--pickers u-h-full">
              {paneContent?.tag === "pickers" ? (
                <PickersPaneContent
                  quickLinks={quickLinks}
                  paneContent={paneContent.content}
                  contactData={contactData}
                  languagePickerData={header.languagePicker}
                  links={props.links}
                  locale={locale}
                  segment={segments?.current ?? null}
                  t={t}
                  canUseSearchInputFocus={true}
                />
              ) : null}
            </div>
          </HeaderNavigationPane>

          <div
            css={css`
              display: none;

              ${CSS_MQ.atMostSm} {
                .has-opened-pane & {
                  display: flex;
                  flex-direction: column;
                }
              }

              position: fixed;
              left: 0;
              top: 0;
              width: 100%;
              height: 100vh;
              height: 100dvh;

              background-color: white;

              padding-top: 90px;
              padding-left: 0;
              padding-right: 0;

              overflow: hidden;
            `}
          >
            <HeaderNavigationMobilePane
              contents={[
                contentPaneContent({ tag: "shop" }, null, t).content,
                contentPaneContent({ tag: "content" }, null, t).content,
              ]}
              pickersContents={pickersContents}
              data={header}
              contactData={contactData}
              state={paneContent}
              setState={setPaneContent}
              currentSegmentKey={segments?.current.key}
              actions={actions}
              languagePickerData={header.languagePicker}
              links={props.links}
              locale={locale}
              segment={segments?.current ?? null}
              t={t}
              quickLinks={quickLinks}
            />
          </div>
        </>
      }
      outletRightPane={
        <RightSideNavigation
          mainNavigationElements={props.mainNavigationElements}
          t={t}
          links={props.links}
          contactData={contactData}
          chatbotEnabled={chatbotEnabled}
          cartMode={cartMode}
          hideCartElement={cartMode === "hidden" || isOnCartPage}
          segments={segments}
          actions={actions}
          hasAnythingInCart={hasAnythingInCart}
          locale={locale}
          onMobilePaneOpened={onMobilePaneOpened}
          hasMobilePaneOpened={!!paneContent}
          active={paneContent?.tag === "pickers" ? paneContent.content.tag : undefined}
        />
      }
    />
  );
};

const focusStyles = css`
  --focus-outline: currentColor;
`;

const xStyles = css`
  .headroom--pinned & {
    grid-template-rows: var(--header-pinned-height);
  }

  .headroom--unfixed & {
    grid-template-rows: var(--header-unfixed-height);
  }
`;

export const leftPaneWrapperStyles = [
  xStyles,
  focusStyles,
  css`
    position: relative;
    left: var(--navigation-diff);
    top: 0;

    width: 100%;

    padding-left: var(--padding);
    padding-right: var(--padding);
    ${CSS_MQ.atLeastMd} {
      width: 30em;
      max-width: 50%;

      padding-left: var(--padding);
      padding-right: 0;
    }

    transition: color 0.2s;
    color: var(--text-color);

    display: grid;

    grid-template-columns: 60px auto;
    gap: 20px;
    ${CSS_MQ.atLeastMd} {
      grid-template-columns: 72px auto;
      gap: 12px;
    }

    ${CSS_MQ.atLeastLg} {
      gap: 32px;
    }

    align-items: center;

    z-index: 1;

    --text-color: var(--header-variant-theme-text-color);
    --text-emphasis-color: var(--header-variant-theme-text-color);

    &.opened-content-pane {
      &.with-content {
        --text-color: ${CSS_COLORS.Black};
        --text-emphasis-color: ${CSS_COLORS.Black};
      }

      ${CSS_MQ.atMostSm} {
        --text-color: ${CSS_COLORS.Black};
        --text-emphasis-color: ${CSS_COLORS.Black};
      }
    }

    &.opened-pickers {
      ${CSS_MQ_ONLY.md_and_lg} {
        display: none;
      }
    }
  `,
];

export const rightPaneWrapperStyles = [
  xStyles,
  focusStyles,
  css`
    position: absolute;
    right: var(--navigation-diff);
    top: 0;

    transition: color 0.2s;
    color: var(--header-variant-theme-text-color);

    display: grid;

    ${CSS_MQ.atLeastMd} {
      padding-right: var(--padding);
    }

    align-items: center;

    z-index: 1;

    &.opened-pickers {
      &.light {
        color: ${CSS_COLORS.Black};
      }
      &.dark {
        color: ${CSS_COLORS.White};

        --icon-color: ${CSS_COLORS.Black};
        ${CSS_MQ.atLeastMd} {
          --icon-color: ${CSS_COLORS.White};
        }
      }
    }

    &.opened-content-pane {
      ${CSS_MQ_ONLY.md_and_lg} {
        display: none;
      }
    }
  `,
];
