import React from "react";
import cn from "classnames";

import { css } from "@emotion/react";
import { LanguagePickerCountryFlag } from "./LanguagePickerCountryFlag";
import { Link } from "react-router";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import { useStore } from "../../store/hooks/use-store";

import { CSS_MQ } from "../../styles/breakpoints";
import { NewHeaderData, type HeaderLanguagePicker } from "@swo/storefront";
import { FONTS, fontSize } from "../../styles/fonts";
import { getLocalisedLanguageLabel } from "./getLocalisedLabel";

const headlineStyles = [
  fontSize.x2,
  FONTS.SwarovskiFutura700,
  css`
    margin: 0;

    display: flex;
    align-items: center;
    text-transform: uppercase;
  `,
];

type LanguagePickerCountryOptionDisplayT = HeaderLanguagePicker["internationalData"]["languages"];

type LocalePickerLocaleProps = {
  languages: LanguagePickerCountryOptionDisplayT;
  label: React.ReactNode;
};

export const LanguagePickerLocale: React.FC<React.PropsWithChildren<LocalePickerLocaleProps>> = props => {
  const store = useStore();
  const { languages, children, label } = props;

  const styles = css`
    grid-column: 2/2;

    align-items: baseline;
    ${CSS_MQ.atLeastMd} {
      align-content: flex-start;
    }

    display: flex;
    flex-wrap: wrap;

    row-gap: 9px;
    column-gap: 20px;

    ${CSS_MQ.atLeastMd} {
      row-gap: 6px;
      column-gap: 9px;
    }
  `;

  const sortedLanguages = languages
    .map(lang => {
      const localizedLabel = getLocalisedLanguageLabel({ languageIsoCode: lang.languageIsoCode }) ?? lang.label;

      return {
        ...lang,
        localizedLabel,
      };
    })
    .sort((a, b) => a.localizedLabel.localeCompare(b.localizedLabel));

  return (
    <>
      {children}

      <span css={[headlineStyles]}>{label}</span>

      <div css={styles}>
        {sortedLanguages.map(lang => {
          return (
            <Link
              key={lang.languageIsoCode}
              to={{ pathname: lang.link }}
              state={{ scrollToTop: true }}
              onClick={() => {
                store.dispatch({ type: "set-user-preferred-locale", value: lang.localeCode });
              }}
              className={cn({
                selected: lang.isSelected,
              })}
              css={css`
                text-decoration: none;
                color: inherit;

                &:first-letter {
                  text-transform: uppercase;
                }

                &.selected,
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {lang.localizedLabel}
            </Link>
          );
        })}
      </div>
    </>
  );
};
