import React, { useCallback, useEffect, useRef, useState } from "react";

import { css } from "@emotion/react";

import type { Link, SwarovskiOptikSegmentShort } from "@swo/shared";
import type {
  OthersSearchRequestQuery,
  OthersSearchResponse,
  ProductsSearchRequestQuery,
  ProductsSearchResponse,
  SwarovskiOptikLocaleFrontend,
  TranslatedValue,
} from "@swo/storefront";
import { either, state } from "fp-ts";
import { CustomerSupportSvgIcon } from "../../assets";
import { ReactComponent as SearchIcon } from "../../assets/icons/Icon/DKT/Search black.svg";
import { LoadingStateOps, type LoadingState } from "../../domain/data-layer/loading-state";
import { storefrontClient } from "../../domain/storefront";
import { useOnRouteChange } from "../../hooks/use-on-route-change";
import { useResourceRelatedInfo } from "../../hooks/use-resource-related-info";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { CSS_MQ } from "../../styles/breakpoints";
import { FONTS, fontSize } from "../../styles/fonts";
import { CSS_COLORS } from "../../styles/styles-css-variables";
import type { CustomerServiceDropdownElement } from "../HeaderComponent/subcomponents/MenuMetaComponent/CustomerServiceDropdown";
import { CustomerServiceElement } from "../HeaderComponent/subcomponents/MenuMetaComponent/CustomerServiceElement";
import { SearchResultsComponent } from "./SearchResultsComponent";
import { QuickLinks } from "./QuickLinks";
import { LoadingStateWithIdleOps } from "../../domain/data-layer/loading-state-with-idle";
import { useDebounce } from "react-use";

export type SearchState = {
  query: string;
  response: {
    products: ProductsSearchResponse | null;
    others: OthersSearchResponse | null;
  };
};

export type SearchMetadata = {
  // We're merging search response as-if it was done in a single query
  // but to reliably show loading spinners, we have it unmerged below
  isLoading: {
    products: boolean;
    others: boolean;
  };
};

const SIZE = 6;

export const MAX_SIZE = 24;

type State = {
  currentPageNumber: number;
  products: { pageSize: number };
  others: { pageSize: number };
};

const INITIAL_STATE: State = {
  currentPageNumber: 1,
  products: { pageSize: SIZE },
  others: { pageSize: SIZE },
};

export const MainNavigationSearchComponent: React.FC<{
  searchText: TranslatedValue;
  searchPageUrl: string;
  locale: SwarovskiOptikLocaleFrontend;
  segment: SwarovskiOptikSegmentShort;
  customerServiceElement: CustomerServiceDropdownElement;
  t: SearchT;
  quickLinks: Array<Link>;
  canUseSearchInputFocus: boolean;
}> = props => {
  const [value, setValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const [state, setState] = useState<State>(INITIAL_STATE);
  const ref = useRef<HTMLInputElement | null>(null);
  const [activeTag, setActiveTag] = useState<OthersSearchRequestQuery["type"]>(null);

  const debounced = () => {
    setDebouncedValue(value);
  };

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setValue(e.target.value);
    },
    [INITIAL_STATE],
  );

  const [isReady, cancel] = useDebounce(debounced, 300, [value]);

  const onLoadMore = (type: "products" | "others") => {
    setState(currentState => ({
      ...currentState,
      [type]: {
        pageSize: Math.min(MAX_SIZE, currentState[type].pageSize + SIZE),
      },
    }));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const RETRY_POLICY = {
    maxRetries: 0,
    delay: 1,
  };

  // TODO: Implement request aborting/cancelling
  const productResultsLs = useResourceRelatedInfo<
    { debouncedValue: string },
    string,
    { debouncedValue: string },
    {
      query: string;
      response: ProductsSearchResponse;
    } | null
  >(
    LoadingStateOps.asLoaded({
      debouncedValue,
    }),
    r => JSON.stringify(r),
    ({ debouncedValue }) => either.right({ debouncedValue }),
    async ({ debouncedValue }) => {
      if (debouncedValue.length === 0) {
        return null;
      }

      const productParams: ProductsSearchRequestQuery = {
        localeCode: props.locale.code,
        segmentKey: props.segment.key,
        text: debouncedValue,
        limit: MAX_SIZE.toString(),
        offset: undefined,
      };

      const products = await storefrontClient.searchProducts(productParams);

      return { query: debouncedValue, response: products };
    },
    RETRY_POLICY,
  );

  const othersResultsLs = useResourceRelatedInfo<
    { debouncedValue: string },
    string,
    { debouncedValue: string },
    {
      query: string;
      response: OthersSearchResponse;
    } | null
  >(
    LoadingStateOps.asLoaded({
      debouncedValue,
      activeTag,
    }),
    r => JSON.stringify(r),
    ({ debouncedValue }) => either.right({ debouncedValue }),
    async ({ debouncedValue }) => {
      if (debouncedValue.length === 0) {
        return null;
      }

      const othersParams: OthersSearchRequestQuery = {
        localeCode: props.locale.code,
        segmentKey: props.segment.key,
        text: debouncedValue,
        limit: MAX_SIZE.toString(),
        offset: undefined,
        type: activeTag,
      };

      const others = await storefrontClient.searchOthers(othersParams);

      return { query: debouncedValue, response: others };
    },
    RETRY_POLICY,
  );

  useOnRouteChange(() => {
    setState(INITIAL_STATE);
  }, []);

  useEffect(() => {
    if (!props.canUseSearchInputFocus) {
      return;
    }

    const id = setTimeout(() => {
      ref.current?.focus();
    }, 200);

    return () => clearTimeout(id);
  }, []);

  const isLoading = {
    products: productResultsLs.__tag === "loading",
    others: othersResultsLs.__tag === "loading",
  };

  const resultsLs: LoadingState<SearchState> = LoadingStateOps.map(
    LoadingStateOps.both(
      LoadingStateWithIdleOps.toLoadingState(productResultsLs),
      LoadingStateWithIdleOps.toLoadingState(othersResultsLs),
    ),
    ([{ result: productsResult }, { result: othersResult }]) => {
      return {
        query: debouncedValue,

        response: {
          products: productsResult
            ? {
                ...productsResult.response,
                results: productsResult.response.results.slice(0, state.products.pageSize),
              }
            : null,
          others: othersResult
            ? {
                ...othersResult.response,
                results: othersResult.response.results.slice(0, state.others.pageSize),
              }
            : null,
        },
      };
    },
  );

  return (
    <div
      className="u-h-full"
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: 40px;
      `}
    >
      <form onSubmit={onSubmit} css={formStyles}>
        <button
          type={"submit"}
          css={css`
            height: 52px;
            width: 52px;

            order: 1;
          `}
        >
          <SearchIcon css={searchIconsStyles} />
        </button>

        <input
          value={value}
          onChange={onChangeHandler}
          placeholder={props.searchText.value}
          css={inputStyles}
          ref={ref}
        />
      </form>

      <div className="u-w-full u-grow u-relative">
        <div
          className="u-absolute u-w-full u-h-full"
          css={[
            css`
              --w: 4px;
              padding-right: 16px; // to have some spacing from the scrollbar

              --bg: transparent;

              overflow-y: scroll;
              overflow-x: hidden;

              &::-webkit-scrollbar {
                width: var(--w);
              }

              &::-webkit-scrollbar-track {
                background: var(--bg);
              }

              &::-webkit-scrollbar-thumb {
                background: ${CSS_COLORS.Green};
              }
            `,
          ]}
        >
          <SearchResultsComponent
            resultsLs={resultsLs}
            onLoadMore={onLoadMore}
            t={props.t}
            quickLinks={
              <QuickLinks quickLinks={props.quickLinks} headline={props.t("search.results.quicklinks.headline")} />
            }
            activeTag={activeTag}
            setActiveTag={setActiveTag}
            searchMetadata={{ isLoading }}
          >
            <CustomerServiceElement Icon={CustomerSupportSvgIcon} {...props.customerServiceElement} />
          </SearchResultsComponent>
        </div>
      </div>
    </div>
  );
};

const searchIconsStyles = css`
  width: 20px;
  height: 20px;
  color: ${CSS_COLORS.Black};
`;

const formStyles = css`
  display: flex;
  background: white;

  width: 100%;
  border-bottom: 1px solid transparent;
  background: transparent;

  ${CSS_MQ.atLeastMd} {
    border-bottom: 1px solid ${CSS_COLORS.Black};
  }

  height: 52px;
  align-items: center;

  border-bottom: 1px solid ${CSS_COLORS.Black};
  padding-bottom: 1px;

  &:focus-within {
    border-bottom: 2px solid ${CSS_COLORS.Green};
    padding-bottom: 0;
  }
`;

const inputStyles = [
  fontSize.x2,
  FONTS.MinervaModernItalic500,
  css`
    border: none;

    height: 52px;
    width: 100%;

    background: transparent;
    color: ${CSS_COLORS.Black};

    padding-left: 18px;

    order: 2;

    &::placeholder {
      color: ${CSS_COLORS.Black};

      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    &:focus {
      outline: none !important;
    }
  `,
];
