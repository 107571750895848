import type React from "react";
import { FONTS, fontSize, fontStyles } from "../../styles/fonts";
import cn from "classnames";
import { SmallLoadingSpinner, SmallUnstyledLoadingSpinner } from "../../routes/modal-loading-spinner";
import { css } from "@emotion/react";

const searchHeadline = [fontSize.x4, FONTS.SwarovskiFutura300];

export const SearchHeadline: React.FC<{ children: React.ReactNode; className?: string; isLoading: boolean }> = ({
  children,
  className,
  isLoading,
}) => {
  return (
    <span css={searchHeadline} className={cn("u-uppercase u-color-black u-flex u-gap-2 u-ai-center", className)}>
      <span>{children}</span>{" "}
      {isLoading && (
        <span
          css={css`
            margin-top: -6px;
            display: block;
          `}
        >
          <SmallUnstyledLoadingSpinner
            color="green"
            noMargin
            css={css`
              position: relative;

              width: 24px;
              height: 24px;
            `}
          />
        </span>
      )}
    </span>
  );
};
