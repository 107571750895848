export function mathRound(n: number, precision: number) {
  if (precision <= 0) {
    return n;
  } else {
    return Math.floor(n * 10 ** precision) / 10 ** precision;
  }
}

export function splitArrayIntoSubArraysOfSize<T>(
  array: Array<T>,
  subArraySize: number,
  acc: Array<Array<T>> = [],
): Array<Array<T>> {
  if (array.length === 0) {
    return acc;
  }

  const [ret, rest] = [array.slice(0, subArraySize), array.slice(subArraySize)];

  return splitArrayIntoSubArraysOfSize(rest, subArraySize, [...acc, ret]);
}

export function objectFilterUndefined<T>(obj: { [key: string]: Array<T> }): { [key: string]: T } {
  let ret: { [key: string]: T } = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value.length > 0) {
      ret[key] = value[0]!;
    }
  });

  return ret;
}

export function removeLastElem<T>(array: Array<T>): Array<T> {
  return array.slice(0, -1);
}
