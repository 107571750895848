import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CSS_MQ } from "../../../styles/breakpoints";
import { fontSize } from "../../../styles/fonts";
import type { TranslatedValue } from "@swo/storefront";
import { T } from "../../T";

const StyledDiv = styled.div`
  display: flex;

  .headroom--scrolled & {
    margin-top: 0px;
  }

  .headroom--unfixed & {
    ${CSS_MQ.atLeastMd} {
      // margin-top: 65px; // 70px in design but minus icon outline
      align-items: center;
    }
  }

  transition: color 0.2s linear;
`;

type HeaderNavigationButtonProps = {
  buttonRef: React.RefObject<HTMLButtonElement | null>;
  buttonLabel: TranslatedValue;
  buttonClassName: string;
  onClick: () => void;
  className?: string;
};

export const HeaderNavigationButton: React.FC<HeaderNavigationButtonProps> = props => {
  return (
    <StyledDiv className={props.className}>
      <button
        className={props.buttonClassName}
        css={css`
          padding: 12px;

          display: flex;
          flex-direction: column;
          align-items: center;

          color: var(--text-color);

          position: relative;

          span {
            border-bottom: 2px solid transparent;
          }

          &:hover,
          &.active {
            color: var(--text-emphasis-color);
            span {
              border-bottom-color: var(--text-emphasis-color);
            }
          }
        `}
        onClick={props.onClick}
        ref={props.buttonRef}
        title={props.buttonLabel.value}
      >
        <span
          className="u-uppercase"
          css={[
            fontSize.x2,
            css`
              letter-spacing: 2px;
            `,
          ]}
        >
          <T>{props.buttonLabel}</T>
        </span>
      </button>
    </StyledDiv>
  );
};
