import { Link } from "@swo/shared";

type LinkParams = {
  label: string;
  pathname: string;
  search?: Link["data"]["search"];
};

export function internalLinkOf({ label, pathname, search }: LinkParams): Link {
  return { ...unlabeledInternalLinkOf({ pathname, search }), label };
}

export function unlabeledInternalLinkOf({ pathname, search }: Omit<LinkParams, "label">): Omit<Link, "label"> {
  return {
    type: "Internal Link",
    link: pathname,
    data: {
      pathname,
      search,
    },
  };
}
