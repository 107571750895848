import type React from "react";
import { SwarovskiButton } from "../_shared-components/ui-elements/SwarovskiButton";
import { useGeneralTranslationsContext } from "../../global/hooks/use-general-translations-context";
import { T } from "../T";

export const LoadMoreButton: React.FC<{
  // found: number;
  // currentPageNumber: number;
  // pageSize: number;
  onClick: () => void;
}> = ({
  // found, currentPageNumber, pageSize,
  onClick,
}) => {
  const loadMoreLabel = useGeneralTranslationsContext()("loadMoreLabel");

  // if (currentPageNumber * pageSize >= found) {
  //   return null;
  // }

  return (
    <div className="u-mt-8 u-flex u-jc-center">
      <SwarovskiButton as={"button"} onClick={onClick} variant={"green"} size={"small"}>
        <T>{loadMoreLabel}</T>
      </SwarovskiButton>
    </div>
  );
};
