import { StorefrontClientI } from "./storefront-client-i";
import { fetchApi } from "../../../data-layer/fetch-api";
import {
  NewsletterSignUpPayloadT,
  SwarovskiOptikLocale,
  SwarovskiOptikSegmentKey,
  type SwarovskiOptikSegmentShort,
} from "@swo/shared";
import {
  EndpointGuidedSellingEnrichedResultReturnType,
  EndpointGuidedSellingRawResultsReturnType,
  FullTextSearch,
  SwarovskiPageDataFrontend,
  type CombinedSearchResponse,
  type ProductsSearchRequestQuery,
  type ProductsSearchResponse,
  type OthersSearchRequestQuery,
  type OthersSearchResponse,
} from "@swo/storefront";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { nonEmptyArray } from "fp-ts";
import { pipe } from "fp-ts/lib/function";
import { optionsGetJson } from "../../options";
import { STOREFRONT_PATH_PREFIX } from "../../../../const";

function getLocales(): Promise<{ locales: Array<SwarovskiOptikLocale>; fallbackLocaleCode: string }> {
  return fetchApi(`/storefront/locales`, optionsGetJson);
}

function getSegments(
  localeCode: string,
): Promise<{ segments: Array<SwarovskiOptikSegmentShort>; fallbackSegmentKey: SwarovskiOptikSegmentKey }> {
  return fetchApi(`/storefront/segments/for-locale/${localeCode}`, optionsGetJson);
}

function getPage(path: string): Promise<SwarovskiPageDataFrontend> {
  return fetchApi(`${STOREFRONT_PATH_PREFIX}${path}`, optionsGetJson);
}

function fullTextSearch(localeCode: string, segmentKey: string, text: string): Promise<FullTextSearch> {
  return fetchApi<FullTextSearch>(
    `/storefront/search?localeCode=${localeCode}&segmentKey=${segmentKey}&text=${text}`,
    optionsGetJson,
  );
}

function searchProducts(params: ProductsSearchRequestQuery): Promise<ProductsSearchResponse> {
  let paramsStr = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== "undefined" && value !== null) {
      paramsStr.set(key, value);
    }
  });

  return fetchApi<ProductsSearchResponse>(`/storefront/search/products?${paramsStr.toString()}`, optionsGetJson);
}

function searchOthers(params: OthersSearchRequestQuery): Promise<OthersSearchResponse> {
  let paramsStr = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== "undefined" && value !== null) {
      paramsStr.set(key, value);
    }
  });

  return fetchApi<OthersSearchResponse>(`/storefront/search/others?${paramsStr.toString()}`, optionsGetJson);
}

function searchCombined(params: ProductsSearchRequestQuery): Promise<CombinedSearchResponse> {
  let paramsStr = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (typeof value !== "undefined" && value !== null) {
      paramsStr.set(key, value);
    }
  });

  return fetchApi<CombinedSearchResponse>(`/storefront/search/combined?${paramsStr.toString()}`, optionsGetJson);
}

function guidedSellingResult(
  ctx: { localeCode: string; segmentKey: string },
  tagsIds: NonEmptyArray<string>,
): Promise<EndpointGuidedSellingEnrichedResultReturnType> {
  const params = new URLSearchParams({
    tagsIds: tagsIds.join(","),
  });

  return fetchApi<EndpointGuidedSellingEnrichedResultReturnType>(
    `/storefront/guided-selling/${ctx.localeCode}/${ctx.segmentKey}?${params.toString()}`,
    optionsGetJson,
  );
}

function guidedSellingResults(
  ctx: { localeCode: string; segmentKey: string },
  paths: NonEmptyArray<NonEmptyArray<string>>,
): Promise<EndpointGuidedSellingRawResultsReturnType> {
  return fetchApi<EndpointGuidedSellingRawResultsReturnType>(
    `/storefront/guided-selling/${ctx.localeCode}/${ctx.segmentKey}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        paths: pipe(
          paths,
          nonEmptyArray.map(path => ({ tagsIds: path })),
        ),
      }),
    },
  );
}

function newsletterSignUp(data: NewsletterSignUpPayloadT): Promise<{ accepted: boolean }> {
  return fetch(`/storefront/newsletter/sign-up`, {
    method: "POST",
    headers: new Headers({
      "Content-type": "application/json",
    }),
    body: JSON.stringify(data),
  }).then(res => res.json());
}

export const storefrontClient: StorefrontClientI = {
  getLocales,
  getSegments,
  getPage,
  fullTextSearch,
  searchProducts,
  searchOthers,
  searchCombined,
  guidedSellingResult,
  guidedSellingResults,
  newsletterSignUp,
};
