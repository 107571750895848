import { PublicConfigurationT, retry } from "@swo/shared";
import { fetchConfiguration } from "../../components/cms/fetch-configuration";

export function fetchPublicConfig(): Promise<PublicConfigurationT | null> {
  console.log(`Using local environment configuration (process.env)`);

  return retry(fetchConfiguration, {
    count: process.env.NODE_ENV === "development" ? Number.MAX_SAFE_INTEGER : 3,
    delayMs: 2000,
  })().catch(() => {
    return null;
  });
}

export function readPublicConfig(): PublicConfigurationT | null {
  if ("reactAppConfig" in window) {
    return window.reactAppConfig as PublicConfigurationT;
  } else {
    return null;
  }
}
