import {
  LoadingStateIdle,
  LoadingStateWithIdle,
  LoadingStateWithIdleOps,
} from "../domain/data-layer/loading-state-with-idle";
import { Either } from "fp-ts/es6/Either";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import { pipe } from "fp-ts/lib/function";
import { either as E } from "fp-ts";
import { FailureLoadedState, LoadingStateLoading, LoadingStateOps } from "../domain/data-layer/loading-state";
import { retry } from "@swo/shared";
import { useMountedRef } from "./use-mounted";
import { useQueueWithProcessor } from "./use-queue";

const MAX_RETRY_COUNT = 3;

export function useResourceRelatedInfo2<Resource, Version, Data, Result = Resource, Error = unknown>(
  resource: LoadingStateWithIdle<Resource>,
  versionBits: (resource: Resource) => Version,
  extract: (resource: Resource) => Either<Error, Data>,
  eff: (data: Data) => Promise<Result>,
  failureCase?: (u: Error) => LoadingStateIdle | LoadingStateLoading | FailureLoadedState,
  retryPolicy?: {
    maxRetries: number;
    delay: number;
  },
): {
  state: LoadingStateWithIdle<{ result: Result; version: Version }>;
  settleExternally: (data: { result: Result; version: Version }) => void;
} {
  type State = LoadingStateWithIdle<
    {
      result: Result;
      version: Version;
    },
    {
      reason: unknown;
      version: Version;
    }
  >;

  const retryPolicyWithDefault = {
    count: retryPolicy?.maxRetries ?? MAX_RETRY_COUNT,
    delayMs: retryPolicy?.delay ?? 500,
  };

  const [state, setState] = useState<State>(LoadingStateWithIdleOps.idleS);

  const equals = useCallback(
    (currentVersion: Version, latestResource: Resource) => {
      return isEqual(currentVersion, versionBits(latestResource));
    },
    [versionBits],
  );

  const settleExternally = useCallback((data: { result: Result; version: Version }) => {
    setState(LoadingStateWithIdleOps.asLoaded(data));
  }, []);

  const pendingRequestRef = useRef<null | string>(null);
  const mounted = useMountedRef();

  const queue = useQueueWithProcessor(({ extracted, resource }: { extracted: Data; resource: Resource }) => {
    const nextPendingRequestKey = JSON.stringify(extracted);

    if (pendingRequestRef.current === nextPendingRequestKey) {
      return Promise.resolve();
    } else {
      pendingRequestRef.current = nextPendingRequestKey;

      setState(LoadingStateOps.loadingS);

      return retry(() => eff(extracted), retryPolicyWithDefault)()
        .then(data => {
          if (pendingRequestRef.current === nextPendingRequestKey && mounted.current) {
            setState(LoadingStateWithIdleOps.asLoaded({ result: data, version: versionBits(resource) }));
          }
        })
        .catch(e => {
          if (pendingRequestRef.current === nextPendingRequestKey && mounted.current) {
            setState(LoadingStateWithIdleOps.asFailed({ reason: e, version: versionBits(resource) }));
          }
        })
        .finally(() => {
          if (pendingRequestRef.current === nextPendingRequestKey) {
            pendingRequestRef.current = null;
          }
        });
    }
  });

  useEffect(() => {
    if (
      resource.__tag === "loaded" &&
      (state.__tag === "idle" ||
        state.__tag === "loading" ||
        (state.__tag === "loaded" && !equals(state.data.version, resource.data)) ||
        (state.__tag === "error" && !equals(state.error.version, resource.data)))
    ) {
      pipe(
        extract(resource.data),
        E.fold(
          err => {
            const maybeFailureCaseState = failureCase ? failureCase(err) : undefined;
            const version = versionBits(resource.data);

            setState(
              maybeFailureCaseState?.__tag === "error"
                ? { ...maybeFailureCaseState, error: { reason: maybeFailureCaseState.error, version } }
                : LoadingStateOps.asFailed({ reason: "Error", version }),
            );
          },
          extracted => {
            queue.append({
              extracted,
              resource: resource.data,
            });
          },
        ),
      );
    }
  }, [resource, state]);

  return useMemo(() => ({ state, settleExternally }), [state]);
}
