import { css } from "@emotion/react";

export const globalFonts = css`
  @font-face {
    font-family: SwarovskiFutura;
    src: url("/assets/fonts/SwarovskiFutura-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: SwarovskiFutura;
    src: url("/assets/fonts/SwarovskiFutura-Med.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: SwarovskiFutura;
    src: url("/assets/fonts/SwarovskiFutura-Light.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: MinervaModern;
    src: url("/assets/fonts/MINERVAMODERNRegular.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
`;
