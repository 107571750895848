import { DealerLocatorAppState } from "./url";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { serializeStateToUrl } from "./url/serialize-state-to-url";

export function useStateSyncToUrl(appState: DealerLocatorAppState): void {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = serializeStateToUrl(appState);

    if (removeLeadingQuestionMark(location.search) !== removeLeadingQuestionMark(params)) {
      void navigate({ ...location, search: params });
    }
  }, [appState]);
}

export function removeLeadingQuestionMark(str: string): string {
  if (str[0] === "?") {
    return str.slice(1);
  } else {
    return str;
  }
}
