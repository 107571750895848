const DEFAULT_QUALITY = 80;

export function createJpgAndWebpLinks(
  plainContentfulImageUrl: string,
  requestedWidth?: number,
): { jpgLink: string; webpLink: string } {
  const jpgLink = createJpgLink(plainContentfulImageUrl, requestedWidth);
  const webpLink = createWebpLink(plainContentfulImageUrl, requestedWidth);

  return { jpgLink, webpLink };
}

export function createPngAndWebpLinks(
  plainContentfulImageUrl: string,
  requestedWidth?: number,
): { pngLink: string; webpLink: string } {
  const pngLink = createPngLink(plainContentfulImageUrl, requestedWidth);
  const webpLink = createWebpLink(plainContentfulImageUrl, requestedWidth);

  return { pngLink, webpLink };
}

// Looks like Contentful Images API supports image scaling up to 4000px
const CONTENTFUL_IMAGE_API_WIDTH_LIMIT = 4000;

export function createJpgLink(plainContentfulImageUrl: string, requestedWidth?: number, quality?: number) {
  let jpgParams = new URLSearchParams({
    fm: "jpg",
    fl: "progressive",
    q: getQuality(quality),
  });

  if (requestedWidth) {
    jpgParams.set("w", Math.min(CONTENTFUL_IMAGE_API_WIDTH_LIMIT, requestedWidth).toString());
  }

  return `${plainContentfulImageUrl}?${jpgParams.toString()}`;
}

export function createWebpLink(plainContentfulImageUrl: string, requestedWidth?: number, quality?: number) {
  let webpParams = new URLSearchParams({
    fm: "webp",
    q: getQuality(quality),
  });

  if (requestedWidth) {
    webpParams.set("w", Math.min(CONTENTFUL_IMAGE_API_WIDTH_LIMIT, requestedWidth).toString());
  }

  return `${plainContentfulImageUrl}?${webpParams.toString()}`;
}

export function createPngLink(plainContentfulImageUrl: string, requestedWidth?: number, quality?: number) {
  let pngParams = new URLSearchParams({
    fm: "png",
    q: getQuality(quality),
  });

  if (requestedWidth) {
    pngParams.set("w", Math.min(CONTENTFUL_IMAGE_API_WIDTH_LIMIT, requestedWidth).toString());
  }

  return `${plainContentfulImageUrl}?${pngParams.toString()}`;
}

export function createFallbackImage(plainContentfulImageUrl: string, requestedWidth?: number, quality?: number) {
  let imgParams = new URLSearchParams({
    q: getQuality(quality),
  });

  if (requestedWidth) {
    imgParams.set("w", Math.min(CONTENTFUL_IMAGE_API_WIDTH_LIMIT, requestedWidth).toString());
  }

  return `${plainContentfulImageUrl}?${imgParams.toString()}`;
}

function getQuality(quality: number | undefined) {
  return (typeof quality !== "undefined" ? quality : DEFAULT_QUALITY).toString();
}
