export function translateSize(sizeKey: string, countryIsoCode: string): string {
  if (sizeKey.startsWith("EU-")) {
    const sizesProgression = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "XXXXL"];

    function shiftSizeToUs(euSize: string) {
      const idx = sizesProgression.findIndex(v => v === euSize);

      const maybeSize: string | undefined = sizesProgression[idx - 1];

      return maybeSize;
    }

    const euSizeStr = sizeKey.slice("EU-".length);

    if (countryIsoCode.toUpperCase() === "US") {
      return shiftSizeToUs(euSizeStr) || sizeKey;
    } else {
      return euSizeStr;
    }
  } else {
    return sizeKey;
  }
}
