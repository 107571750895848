import React, { useContext } from "react";
import { CartContextValue } from "../../domain/cart/types";
import { Either } from "fp-ts/lib/Either";

export type SOCartCtx = {
  cart: Either<"shopping-currently-not-possible" | "shopping-not-possible" | "loading-cart", CartContextValue>;
};

export const CartContext = React.createContext<SOCartCtx>(null as any);

export function useCartContext(): SOCartCtx {
  return useContext(CartContext);
}
