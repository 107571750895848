import React from "react";
import { css } from "@emotion/react";
import { HeaderNavigationPaneTitleLabel } from "./HeaderNavigationPaneTitleLabel";
import type { TranslatedValue } from "@swo/storefront";
import { T } from "../../T";

export const HeaderNavigationPaneTitle: React.FC<{ label: TranslatedValue; align: "left" | "right" }> = ({
  label,
  align,
}) => {
  return (
    <div css={wrapperStyles} className={`align-${align}`}>
      <HeaderNavigationPaneTitleLabel
        stickingTo={align === "left" ? "left" : "right"}
        attachStyles={css`
          padding-right: 20px;
          padding-top: 40px;
        `}
      >
        <T>{label}</T>
      </HeaderNavigationPaneTitleLabel>
    </div>
  );
};

const wrapperStyles = css`
  position: absolute;

  bottom: 0;

  &.align-left {
    left: 0;
  }

  &.align-right {
    right: 0;
    justify-content: flex-end;
  }

  overflow: hidden;

  width: 100%;
  height: 100%;

  display: flex;

  pointer-events: none;

  z-index: 0;
`;
