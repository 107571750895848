import { LoadingState, LoadingStateOps } from "./loading-state";

export type LoadingStateIdle = { __tag: "idle" };
export type LoadingStateWithIdle<T, E = unknown> = LoadingState<T, E> | LoadingStateIdle;

export const LoadingStateWithIdleOps = {
  ...LoadingStateOps,
  idleS: { __tag: "idle" as const },
  loadingS: LoadingStateOps.loadingS,

  toLoadingState<T>(loadingStateWithIdle: LoadingStateWithIdle<T>): LoadingState<T> {
    if (loadingStateWithIdle.__tag === "idle") {
      return LoadingStateOps.loadingS;
    } else {
      return loadingStateWithIdle;
    }
  },

  mapI<A, B>(loadingStateWithIdle: LoadingStateWithIdle<A>, m: (a: A) => B): LoadingStateWithIdle<B> {
    if (loadingStateWithIdle.__tag === "idle") {
      return LoadingStateWithIdleOps.idleS;
    } else {
      return LoadingStateOps.map(loadingStateWithIdle, m);
    }
  },

  flatMapI<A, B>(
    loadingStateWithIdle: LoadingStateWithIdle<A>,
    m: (a: A) => LoadingStateWithIdle<B>,
  ): LoadingStateWithIdle<B> {
    if (loadingStateWithIdle.__tag === "loaded") {
      return m(loadingStateWithIdle.data);
    } else {
      return loadingStateWithIdle;
    }
  },

  toNullable<A>(ls: LoadingStateWithIdle<A>): A | undefined {
    if (ls.__tag === "loaded") {
      return ls.data;
    } else {
      return undefined;
    }
  },
};
