import { fetchApi } from "../../../data-layer/fetch-api";
import { ProductAvailabilityNotificationClientI } from "./interface";
import { useMemo } from "react";

type ProductAvailabilityNotificationClientParams = {
  localeCode: string;
  segmentKey: string;
};

export const productAvailabilityNotificationClient: (
  context: ProductAvailabilityNotificationClientParams,
) => ProductAvailabilityNotificationClientI = (context: ProductAvailabilityNotificationClientParams) => ({
  subscribe: (subscriptionDetails: { variantSku: string; email: string }): Promise<void> => {
    const urlParams = new URLSearchParams({
      localeCode: context.localeCode,
      segmentKey: context.segmentKey,
    });

    return fetchApi<any>(
      `/storefront/line-items/${subscriptionDetails.variantSku}/availability/notification?${urlParams.toString()}`,
      {
        method: "POST",
        headers: new Headers({
          "Content-type": "application/json",
        }),
        body: JSON.stringify({
          email: subscriptionDetails.email,
        }),
      },
    );
  },
});

export function useProductAvailabilityNotificationClient({
  localeCode,
  segmentKey,
}: ProductAvailabilityNotificationClientParams) {
  return useMemo(() => productAvailabilityNotificationClient({ localeCode, segmentKey }), [localeCode, segmentKey]);
}
