import { SwarovskiOptikSegmentKey } from "@swo/shared";
import { StoreWithDispatch } from "../../store/hooks/use-store";

export function actionCreators(dispatch: StoreWithDispatch["dispatch"]) {
  const onSegmentSelected = (segmentKey: SwarovskiOptikSegmentKey) => {
    dispatch({ type: "user-picked-preferred-segment", value: segmentKey });
  };

  const dismissSegmentSelection = (segmentKey: SwarovskiOptikSegmentKey) => {
    dispatch({ type: "user-dismissed-segment-selection", pageSegmentKey: segmentKey });
  };

  return { onSegmentSelected, dismissSegmentSelection };
}
