import React from "react";
import { SerializedStyles } from "@emotion/react";
import { SwarovskiLink } from "../../_shared-components/ui-elements/SwarovskiLink";
import { SwarovskiHomepageLink } from "./SwarovskiLogoComponent";

export function SwarovskiLogoComponentWithChildren(props: {
  homepageLink: SwarovskiHomepageLink;
  className?: string;
  attachStyles?: SerializedStyles;
  children: React.ReactNode;
}) {
  const homepage = props.homepageLink.isInternal
    ? ({ isInternal: true, link: props.homepageLink.localizedHomepage, scrollToTop: true } as const)
    : ({ isInternal: false, link: props.homepageLink.rootHomepage } as const);

  return (
    <SwarovskiLink
      {...homepage}
      className={props.className}
      styled={"no-decoration"}
      title={"Swarovski Optik"}
      attachStyles={props.attachStyles}
    >
      {props.children}
    </SwarovskiLink>
  );
}
