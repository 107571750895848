import React from "react";
import { PaneNavigationLabel } from "./PaneNavigationLabel";
import { State } from "./HeaderNavigationMobilePane";
import { HeaderT } from "../HeaderT";
import { T } from "../../T";

export const PaneNavigation: React.FC<{
  state: State;
  setState: React.Dispatch<React.SetStateAction<State>>;
  t: HeaderT;
}> = ({ state, setState, t }) => {
  if (state == null) {
    return null;
  }

  switch (state.tag) {
    case "content-pane": {
      if (state.content == null) {
        return null;
      }

      return (
        <PaneNavigationLabel onClick={() => setState({ tag: "content-pane", content: null })}>
          <T>{state.content.tag === "shop" ? t("navigation.shopLabel") : t("navigation.contentLabel")}</T>
        </PaneNavigationLabel>
      );
    }
    case "pickers": {
      return (
        <PaneNavigationLabel onClick={() => setState({ tag: "content-pane", content: null })}>
          <T>{t("navigation.menuLabel")}</T>
        </PaneNavigationLabel>
      );
    }
    default: {
      const _: never = state;

      return _;
    }
  }
};
