import type { PageDocument } from "@swo/storefront";
import type { SearchT } from "../../../layouts/search-page-layout/components/search-component";
import { Link } from "react-router";
import { css } from "@emotion/react";
import { CSS_COLORS } from "../../../styles/styles-css-variables";
import { FallbackImage } from "../FallbackImage";
import { SearchTag } from "../SearchTag";
import { T } from "../../T";
import { SearchResultSummary } from "../SearchResultSummary";
import { transformImageIfFromContentful_jpg } from "../../../domain/contentful/transform-image-if-from-contentful";

export const PageResult: React.FC<{ page: PageDocument; t: SearchT }> = ({ page, t }) => {
  const imageSrc = page.thumbnail?.url ?? undefined;
  const label = t("search.results.others.tag.page");

  return (
    <Link
      to={page.path}
      className="u-d-block u-no-decoration"
      css={css`
        &:hover {
          ._text {
            text-decoration: underline;
          }
        }
      `}
    >
      <div
        css={css`
          aspect-ratio: 16/10;
          background-color: ${CSS_COLORS.White};
          color: ${CSS_COLORS.Green};

          position: relative;
        `}
      >
        {imageSrc ? (
          <img
            src={transformImageIfFromContentful_jpg(imageSrc, 300)}
            alt={page.title}
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              object-fit: cover;
            `}
          />
        ) : (
          <div className="u-d-flex u-jc-center u-ai-center u-h-full">
            <FallbackImage />
          </div>
        )}

        <SearchTag>
          <T>{label}</T>
        </SearchTag>
      </div>

      <SearchResultSummary title={page.title} description={page.description} t={t} />
    </Link>
  );
};

export const StoryResult: React.FC<{ story: PageDocument; t: SearchT }> = ({ story, t }) => {
  const imageSrc = story.thumbnail?.url ?? undefined;
  const label = t("search.results.others.tag.story");

  return (
    <Link
      to={story.path}
      className="u-d-block u-no-decoration"
      css={css`
        &:hover {
          ._text {
            text-decoration: underline;
          }
        }
      `}
    >
      <div
        css={css`
          aspect-ratio: 16/10;
          background-color: ${CSS_COLORS.White};
          color: ${CSS_COLORS.Green};

          position: relative;
        `}
      >
        {imageSrc ? (
          <img
            src={transformImageIfFromContentful_jpg(imageSrc, 300)}
            alt={story.title}
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              object-fit: cover;
            `}
          />
        ) : (
          <div className="u-d-flex u-jc-center u-ai-center u-h-full">
            <FallbackImage />
          </div>
        )}

        <SearchTag>
          <T>{label}</T>
        </SearchTag>
      </div>

      <SearchResultSummary title={story.title} description={story.description} t={t} />
    </Link>
  );
};
