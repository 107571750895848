import { ReactTFC } from "../../../../utils/fp/missing-ts-parts";
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import { SwarovskiButton } from "../../../_shared-components/ui-elements/SwarovskiButton";
import { SwarovskiLink } from "../../../_shared-components/ui-elements/SwarovskiLink";
import { inArray } from "../../../../utils/fp/in-array";
import {
  CustomerServiceDropdownElement,
  CustomerServiceDropdownElementButton,
  containerStyles,
  iconStyles,
  headlineStyles,
  lightFontStyles,
  linkStyles,
} from "./CustomerServiceDropdown";
import { T } from "../../../T";

export const CustomerServiceElement: ReactTFC<
  CustomerServiceDropdownElement & { Icon: React.FC<React.SVGProps<SVGSVGElement>> }
> = props => {
  const getButton = (btn: CustomerServiceDropdownElementButton, css: SerializedStyles | Array<SerializedStyles>) =>
    btn.as === "a" ? (
      <SwarovskiLink
        link={btn.link}
        isInternal={btn.isInternal}
        scrollToTop={true}
        upperCased={btn.upperCased}
        attachStyles={css}
      >
        {btn.label && <T>{btn.label}</T>}
      </SwarovskiLink>
    ) : (
      <SwarovskiButton as="button" variant="plain-underline" onClick={btn.onClick} css={css}>
        <T>{btn.label}</T>
      </SwarovskiButton>
    );

  return (
    <div css={containerStyles}>
      <props.Icon css={iconStyles} />
      {props.headline && (
        <span css={headlineStyles}>
          <T>{props.headline}</T>
        </span>
      )}

      <span css={lightFontStyles}>
        {props.line && (
          <span>
            <T>{props.line}</T>
          </span>
        )}
        {props.secondLine && (
          <span css={lightFontStyles}>
            <T>{props.secondLine}</T>
          </span>
        )}
      </span>

      {getButton(props.firstBtn, linkStyles)}
      {props.secondBtn &&
        getButton(
          props.secondBtn,
          css`
            margin-top: 10px;
          `,
        )}
    </div>
  );
};
