import { css } from "@emotion/react";

import { FONTS, fontSize } from "../../../../styles/fonts";
import type { TranslatedValue } from "@swo/storefront";

export const headlineStyles = [
  FONTS.SwarovskiFutura500,
  fontSize.x2,
  css`
    text-transform: uppercase;

    margin-top: 20px;
    margin-bottom: 10px;
  `,
];

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const iconStyles = css`
  width: 50px;
  height: 50px;

  color: var(--icon-color, white);
`;

export type CustomerServiceDropdownElementButton =
  | { as: "button"; label: string | TranslatedValue; onClick: () => void }
  | { as: "a"; label: null | string | TranslatedValue; link: string; isInternal: boolean; upperCased: boolean };

export type CustomerServiceDropdownElement = {
  headline: string | TranslatedValue | null;
  line: string | TranslatedValue | null;
  secondLine: string | TranslatedValue | null;
  firstBtn: CustomerServiceDropdownElementButton;
  secondBtn?: CustomerServiceDropdownElementButton;
};

export const lightFontStyles = [
  FONTS.SwarovskiFutura300,
  fontSize.x2,
  css`
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    gap: 4px;

    text-align: center;

    margin-bottom: 12px;
  `,
];

export const linkStyles = [
  FONTS.SwarovskiFutura500,
  fontSize.x2,
  css`
    color: currentColor;

    text-align: center;
  `,
];
