import React from "react";
import { fontSize } from "../../../styles/fonts";
import { NonEmptyArray } from "fp-ts/lib/NonEmptyArray";
import { LinkNode } from "./LinkNode";
import { LinksChildren } from "./LinksChildren";
import { Node } from "./Links";
import cn from "classnames";
import { css } from "@emotion/react";
import { ChevronDownSvg } from "./ChevronDownSvg";

export const LinkElement: React.FC<Node & { children: NonEmptyArray<Node> | null }> = ({ link, isNew, children }) => {
  const [isOpened, setIsOpened] = React.useState(false);

  const onClick = () => {
    setIsOpened(_ => !_);
  };

  return (
    <li
      key={link.label}
      className="u-relative"
      css={css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;
      `}
    >
      <span css={[fontSize.x25]} className="u-block">
        <LinkNode isNew={isNew} link={link} />
      </span>

      {children && (
        <button
          onClick={onClick}
          className={cn("u-grow u-flex u-ai-center u-jc-center", { opened: isOpened })}
          css={css`
            svg {
              margin-left: auto;
              width: 32px;
              height: 32px;

              margin-top: -3px;
            }

            svg {
              transition: transform 0.15s ease-out;
            }

            &.opened {
              svg {
                transform: rotate(180deg);
              }
            }
          `}
        >
          <ChevronDownSvg />
        </button>
      )}

      {children && isOpened ? (
        <div
          css={css`
            grid-column: 1 / 3;
          `}
        >
          <LinksChildren nodes={children} />
        </div>
      ) : null}
    </li>
  );
};
