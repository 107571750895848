import React from "react";
import { HeaderT } from "../HeaderT";
import { css } from "@emotion/react";
import { FONTS, fontSize } from "../../../styles/fonts";
import { T } from "../../T";

export const EmptyCart: React.FC<React.PropsWithChildren<{ t: HeaderT }>> = ({ t, children }) => {
  return (
    <div
      css={[
        FONTS.SwarovskiFutura300,
        css`
          margin-top: 80px;
          text-align: center;
        `,
      ]}
    >
      <span
        css={[
          fontSize.x5d,
          css`
            text-transform: uppercase;

            color: black;
            opacity: 0.3;
          `,
        ]}
      >
        <T>{t("cart.cartEmptyHeadline")}</T>
      </span>

      <p>
        <T>{t("cart.cartEmptyDescription")}</T>
      </p>

      {children}
    </div>
  );
};
