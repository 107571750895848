import React from "react";

import { css } from "@emotion/react";

import { CSS_COLORS } from "../../../../styles/styles-css-variables";
import { ReactTFC } from "../../../../utils/fp/missing-ts-parts";
import { fontColor, FONTS, fontSize } from "../../../../styles/fonts";
import cn from "classnames";

export const AttributesTag: ReactTFC<{ label: string; size?: "small" | "regular" }> = props => {
  const isLong = props.label.length > 40;

  return (
    <span css={styles} className={cn({ long: isLong, small: props.size === "small" })} title={props.label}>
      {props.label}
    </span>
  );
};

const styles = [
  FONTS.SwarovskiFutura300,
  fontColor.black,
  fontSize.x2s,

  css`
    &:not(.small) {
      padding: 8px 10px 6px;
      border-radius: 8px;
    }

    &.small {
      padding: 3px 6px 2px;
      border-radius: 4px;
    }

    max-width: 12em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    text-transform: uppercase;

    border: 1px solid ${CSS_COLORS.Grey};

    display: inline-block;

    &:not(.long) {
      text-align: center;
    }
  `,
];
