import { LoadingState, LoadingStateOps } from "../../domain/data-layer/loading-state";
import { SetStateAction, useCallback, useState } from "react";

export function useLoadingSetState<S>(
  initialState: LoadingState<S>,
): [LoadingState<S>, (s: S) => void, (e: unknown) => void, React.Dispatch<SetStateAction<LoadingState<S>>>] {
  const [state, setState] = useState<LoadingState<S>>(initialState);

  const setLoaded = useCallback(
    (s: S) => {
      setState(LoadingStateOps.asLoaded(s));
    },
    [setState],
  );

  const setFailed = useCallback(
    (e: unknown) => {
      setState(LoadingStateOps.asFailed(e));
    },
    [setState],
  );

  return [state, setLoaded, setFailed, setState];
}
