import { CSS_COLORS } from "../../../../styles/styles-css-variables";
import styled from "@emotion/styled";

export const Hr = styled.hr`
  margin: 30px 0;
  width: 100%;

  height: 1px;

  border: none;
  background-color: ${CSS_COLORS.Grey2};
`;
