import React from "react";
import { css } from "@emotion/react";

import { CSS_MQ } from "../../../../styles/breakpoints";
import { ContactDataType, SwarovskiOptikShopMode } from "@swo/shared";
import { HeaderLinks } from "../../ContextLessHeaderComponent";
import { HeaderT } from "../../HeaderT";

export type MenuMetaProps = {
  t: HeaderT;
  links: HeaderLinks;
  contactData: ContactDataType;
  chatbotEnabled: boolean;
  cartMode: SwarovskiOptikShopMode["cart"];
};

export const MenuMetaComponent: React.FC<React.PropsWithChildren<MenuMetaProps>> = props => {
  return <div css={serializedStyles}>{props.children}</div>;
};

const serializedStyles = css`
  display: flex;

  justify-content: flex-end;
  align-items: center;
`;
