import type { CombinedSearchResponse, OthersSearchRequestQuery } from "@swo/storefront";
import React from "react";
import type { SearchT } from "../../layouts/search-page-layout/components/search-component";
import { mapTranslatedValue, T } from "../T";
import { ProductsSearchResultsGrid } from "./ProductsSearchResultsGrid";
import { SearchHeadline } from "./SearchHeadline";
import { LoadMoreButton } from "./LoadMoreButton";
import { Spacer } from "../_shared-components/arrangement/Space";
import { css } from "@emotion/react";
import { OthersSearchTags } from "./OthersSearchTags";
import { OthersSearchResultsGrid } from "./OthersSearchResultsGrid";
import { MAX_SIZE, type SearchMetadata, type SearchState } from "./MainNavigationSearchComponent";
import { CSS_MQ } from "../../styles/breakpoints";
import { NoSearchResultsInSection } from "./NoSearchResultsInSection";

export const ResponseView: React.FC<{
  result: SearchState | null;
  children: React.ReactNode;
  onLoadMore: (type: "products" | "others") => void;
  t: SearchT;
  quickLinks: React.ReactNode;
  activeTag: OthersSearchRequestQuery["type"];
  setActiveTag: (tag: OthersSearchRequestQuery["type"]) => void;
  searchMetadata: SearchMetadata;
}> = ({ result, children, onLoadMore, t, quickLinks, activeTag, setActiveTag, searchMetadata }) => {
  if (!result || result.query === "") {
    return quickLinks;
  }

  const hits = result.response;

  // const NO_RESULTS_AT_ALL = <SearchNoResultsAtAll t={t} query={result.query} />;
  const NO_RESULTS_IN_SECTION = <NoSearchResultsInSection t={t} query={result.query} />;

  const { products, others } = hits;

  if (!products && !others) {
    return quickLinks;
  }

  // Cannot hide it completely because "others" has an additional search state/param
  // to it - that becomes hidden as well
  // if ((!products || products.results.length === 0) && (!others || others.results.length === 0)) {
  //   return NO_RESULTS_AT_ALL;
  // }

  return (
    <>
      <SearchHeadline className="u-mb-2" isLoading={searchMetadata.isLoading.products}>
        <T>{t("search.results.products.headline")}</T>
      </SearchHeadline>

      {products && products.results.length > 0 ? (
        <>
          <ProductsSearchResultsGrid products={products} />

          {products.total > products.results.length && products.results.length < MAX_SIZE ? (
            <LoadMoreButton onClick={() => onLoadMore("products")} />
          ) : (
            <Spacer size="md" />
          )}
        </>
      ) : (
        NO_RESULTS_IN_SECTION
      )}

      <Spacer size="md" />

      <div
        css={css`
          display: grid;
          ${CSS_MQ.atLeastSm} {
            grid-template-columns: auto 1fr;
          }
          gap: 16px;

          margin-bottom: 8px;
        `}
      >
        <SearchHeadline className="u-mb-2" isLoading={searchMetadata.isLoading.others}>
          <T>{t("search.results.others.headline")}</T>
        </SearchHeadline>

        <div
          css={css`
            justify-self: flex-end;
          `}
        >
          <OthersSearchTags t={t} activeTag={activeTag} setActiveTag={setActiveTag} />
        </div>
      </div>

      {others && others.results.length > 0 ? (
        <>
          <OthersSearchResultsGrid others={others} t={t} />

          {others.total > others.results.length && others.results.length < MAX_SIZE ? (
            <LoadMoreButton onClick={() => onLoadMore("others")} />
          ) : (
            <Spacer size="md" />
          )}
        </>
      ) : (
        NO_RESULTS_IN_SECTION
      )}

      <div className="u-mt-8">{children}</div>
    </>
  );
};
