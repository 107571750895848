import React from "react";
import { HeaderPrimaryNavigationElemT } from "@swo/storefront";
import { HeaderNavigationDataItem } from "./HeaderNavigationDataItem";
import cn from "classnames";
import { HeaderNavigationDataProps } from "./HeaderNavigationData";
import { css } from "@emotion/react";

type Props = {
  data: HeaderPrimaryNavigationElemT;
  contactData: HeaderNavigationDataProps["contactData"];
  secondaryContentPrimaryHeadlineRef: React.RefObject<HTMLDivElement | null>;
  requestCollapsingNavigation: () => void;
};

export const HeaderNavigationLeftPaneSecondColumn = React.forwardRef<HTMLDivElement, Props>(
  ({ data, contactData, requestCollapsingNavigation, secondaryContentPrimaryHeadlineRef }, ref) => {
    return (
      <div
        className={cn("js-header-navigation-left-pane-second-column", "u-flex u-scroll-y-auto so-scrollbar-y")}
        css={css`
          background-color: var(--bg-color-second-pane);
        `}
        ref={ref}
      >
        <HeaderNavigationDataItem
          item={data}
          contactData={contactData}
          ref={secondaryContentPrimaryHeadlineRef}
          requestCollapsingNavigation={requestCollapsingNavigation}
        />
      </div>
    );
  },
);
