import { SwagSlugBuilder, SwarovskiOptikSegmentShort, getIsoCodeWithIntFallback, showPriceL } from "@swo/shared";
import { SwarovskiOptikPage } from "@swo/sitemap-service";
import { SwarovskiOptikLocaleFrontend, type GeneralTranslation } from "@swo/storefront";
import React, { useContext, useEffect, useMemo } from "react";
import { prepareOauthLink } from "../domain/auth";
import { t2 } from "../domain/translations/translations";
import { useEqualState } from "../hooks";
import { useStore } from "../store/hooks/use-store";
import type { PageContextType } from "./PageContextType";

export type LocaleSegmentContext = { locale: SwarovskiOptikLocaleFrontend; segment: SwarovskiOptikSegmentShort };

export const PageContext = React.createContext<PageContextType>(null as any);

export function usePageContext(): PageContextType {
  return useContext(PageContext);
}

export function usePageLocaleContext(): { locale: SwarovskiOptikLocaleFrontend } {
  const pageCtx = usePageContext();

  return useMemo(() => ({ locale: pageCtx.pageBase.locale }), [pageCtx.pageBase.locale.code]);
}

export function usePageBaseContext(): LocaleSegmentContext {
  const { pageBase } = usePageContext();

  return useMemo(
    () => ({ locale: pageBase.locale, segment: pageBase.segment }),
    [pageBase.locale.code, pageBase.segment.id],
  );
}

export function useCtxFns(): PageContextType["contextFunctions"] {
  const { contextFunctions } = usePageContext();

  return useMemo(() => contextFunctions, [contextFunctions]);
}

type PageContentContextProviderProps = React.PropsWithChildren<{
  locale: SwarovskiOptikLocaleFrontend;
  segment: SwarovskiOptikSegmentShort;
  page: SwarovskiOptikPage;
  generalTranslations: GeneralTranslation;
}>;

export const PageContentContextProvider: React.FC<PageContentContextProviderProps> = props => {
  const { authServiceHost, config } = useStore().state;

  function getState(): PageContextType {
    const homepage = SwagSlugBuilder.path(props.locale, props.segment, "");

    const {
      register: myServiceRegister,
      login: myServiceLogin,
      profile: myServiceProfile,
    } = prepareOauthLink(props.page.path, authServiceHost, config, {
      languageIsoCode: props.locale.language.isoCode,
      countryIsoCode: getIsoCodeWithIntFallback(props.locale.country),
    });

    const contextFunctions: PageContextType["contextFunctions"] = {
      showPrice: showPriceL(props.locale),
    };

    return {
      pageBase: {
        ...props.page,
        locale: props.locale,
        segment: props.segment,
      },
      generalTranslations: t2(props.generalTranslations),
      contextFunctions,
      links: {
        homepage,
        myServiceRegister,
        myServiceLogin,
        myServiceProfile,
      },
    };
  }

  const [ret, setRet] = useEqualState<PageContextType>(getState());
  useEffect(() => {
    setRet(getState());
  }, [props.page, props.generalTranslations]);

  return <PageContext.Provider value={ret}>{props.children}</PageContext.Provider>;
};
