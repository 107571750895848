import { MainHeaderComponent } from "./subcomponents/MainHeaderComponent";
import React, { useRef } from "react";
import { ReactTFC } from "../../utils/fp/missing-ts-parts";

export const EmptyHeaderComponent: ReactTFC = () => {
  return (
    <MainHeaderComponent
      floatingDisabled={true}
      mainNavigationElements={[]}
      t={(key: string) => ({ value: key, key: undefined, type: "fallback" })}
      links={{
        cartPage: "/",
        dealerLocatorPage: "/",
        searchPage: "/",
        homepage: { isInternal: false, rootHomepage: "/" },
        user: { register: "/", login: "/", profile: "/" },
      }}
      contactData={null}
      segments={null}
      locale={null}
      header={null}
      showSegmentSelector={false}
      userPreferredSegmentKey={null}
      hasAnythingInCart={false}
      isOnCartPage={false}
      quickLinks={[]}
    />
  );
};
